import { createFeatureSelector, createSelector } from '@ngrx/store';
import {ProcessI, TaskStateI} from "../../interfaces/process.interface";
import {processResultFeatureKey, StateProcessResult} from "./state";
import {
  MapResultI,
  ProcessResultFilterI,
  ProcessResultI, ProcessResultKanbanStateI,
  ProcessResultPaginatorI,
  ProcessResultTab,
  ViewComponentType
} from "../../interfaces/process-result.interface";
import {DetailTabs, TaskI} from "../../interfaces/task.interface";

export const processResultState = createFeatureSelector<StateProcessResult>(processResultFeatureKey);

export const selectLoadingResultTaskAction = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingResultTaskAction
  }
)

export const selectLoadingResults = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingResults
  }
)

export const selectLoadingDetailProcessResult = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingDetailProcessResult
  }
)

export const selectLoadingBookmarked = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingBookmarked
  }
)

export const selectLoadingCreateResult = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingCreateResult
  }
)

export const selectLoadingProcessResultPermissions = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingProcessResultPermissions
  }
)

export const selectLoadingProcessResultGridData = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingProcessResultGridData
  }
)

export const selectLoadingProcessResultMap = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.loadingProcessResultMap
  }
)

export const selectShowFilterBar = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.showFilterBar
  }
)

export const selectShowRightBar = createSelector(
  processResultState,
  (state: StateProcessResult): boolean => {
    return state.showRightBar
  }
)

export const selectResultDetailsTab = createSelector(
  processResultState,
  (state: StateProcessResult): DetailTabs => {
    return state.detailTab
  }
)

export const selectProcessResultList = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultI[] => {
    return state.processResultList
  }
)

export const selectProcessResultPaginator = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultPaginatorI => {
    return state.paginator
  }
)

export const selectSelectedProcessResult = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultI | null => {
    return state.selectedProcessResult
  }
)

export const selectDetailsProcessResult = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultI | null => {
    return state.detailProcessResult
  }
)

export const selectProcessResultTab = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultTab => {
    return state.processResultTab
  }
)

export const selectViewComponentProcessResult = createSelector(
  processResultState,
  (state: StateProcessResult): ViewComponentType => {
    return state.viewComponent
  }
)

export const selectProcessResultKanbanByStatus = createSelector(
  processResultState,
  (state: StateProcessResult, props: {status: string}): ProcessResultKanbanStateI | null => {
    return state.processResultKanban?.[props.status] || null
  }
)

export const selectProcessResultFilter = createSelector(
  processResultState,
  (state: StateProcessResult): ProcessResultFilterI => {
    return state.processResultFilter
  }
)

export const selectProcessResultGlobalSearch = createSelector(
  processResultState,
  (state: StateProcessResult): string => {
    return state.globalSearch
  }
)

export const selectProcessResultGridData = createSelector(
  processResultState,
  (state: StateProcessResult): TaskI[] => {
    return state.gridData
  }
)

export const selectProcessResultMap = createSelector(
  processResultState,
  (state: StateProcessResult): {
    count: number,
    loaded: number,
    page: number,
    valid: number,
    result: (MapResultI[])[]
  } => {
    return state.processResultMap
  }
)

export const selectGridDefaultState = createSelector(
  processResultState,
  (state: StateProcessResult): TaskStateI | null => {
    return state.gridDefaultState
  }
)



