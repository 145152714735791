<div
  class="position-relative border rounded-2 d-flex justify-content-center align-items-center
              email-provider-card hover:bg-neutral-color-f pointer px-2 mb-2"
  style="height: 50px"
  *ngFor="let provider of emailProviders, let index=index"
  (click)="selectEmailProvider(provider)"
>
  <div
    *ngIf="provider.icon_url"
    class="h-100 me-2 py-2 d-flex justify-content-center align-items-center"
  >
    <img src="{{provider.icon_url}}" alt="{{provider.label}}" height="90%">
  </div>
  <div
    class="d-flex justify-content-start  align-items-center"
  >
    <span class="text-title fw-bold text-complementary-color-a">
      {{provider.label | titlecase}}
    </span>
  </div>
</div>
