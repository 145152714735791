import {TaskI} from "./task.interface";

export enum ProcessFilterType {
  "text"="text",
  "select"= "select",
  "date"="date"
}

export interface TaskStateI {
  "key": string;
  "icon": string;
  "color": string;
  "fontColor": string;
  "label": string;
  "message": string;
  "selected": boolean;
}

export interface TaskStateConfI {
  "initial_state": string;
  "complete_state": string;
  "default_filter": string[];
  "change_probability" : string[];
  "states_list": TaskStateI[]
}

export interface ProcessHeaderValue {
  "locale": string,
  "transformation": any,
  "type": string,
  "value": string
}


export interface ProcessHeaders{
  "component": string;
  "label": string;
  "value": ProcessHeaderValue;
  "key": string;
}

export interface ProcessTableDashboard {
  "headers": ProcessHeaders[];
  "rowsPerPage": number[];
}

export interface ProcessProperties {
  new_button: {
    icon: string;
    label: string;
    css: object;
  };
  process_properties: {
    title: string;
    sub_title: string;
    description: string;
  },
  process_general_activity: {
    api: string;
    label: string;
    websocketChannel: string;
    websocketServer: string;
  }
}

export interface ProcessFilter {
  type: ProcessFilterType;
  label: string;
  field: string;
  placeHolder: string;
  default: any;
  settings: {
    "sourceType": "static" | "remote";
    "source": any;
    "label_field": string;
    "value_field": string;
  }
}


export interface ProcessI{
  "id": string;
  "sk": string;
  "label": string;
  icon?: string;
  icon_url?: string;
  "slugName": string;
  "tasks": TaskI[];
  "properties": ProcessProperties;
  "tableDashboard": ProcessTableDashboard;
  "tableTask": ProcessTableDashboard;
  "process_filters": ProcessFilter[];
  "task_states": TaskStateConfI;
  "process_states": TaskStateConfI;
}

export interface ProcessResponseI{
  "count": number;
  "page": number;
  "next": number | null;
  "previous": number | null;
  "results": ProcessI[];
}

export interface ProcessPaginatorI {
  "count": number;
  "next": number | null;
  "page": number;
  "previous": number | null;
}
