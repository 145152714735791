import { createReducer, on } from '@ngrx/store';
import {initialState, StateAudit} from "./state";
import * as auditActions from "./audit.actions"

export const auditReducer = createReducer(
	initialState,
  on(auditActions.loadAuditStatusSuccess, (state, action) => {
    return {
      ...state,
      statusAudit: action.response.results || []
    }
  }),
  on(auditActions.setLoadingAuditStatus, (state, action) => {
    return {
      ...state,
      loadingStatusAudit: action.status
    }
  }),
);
