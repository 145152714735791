import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { AvatarTextPipe } from './avatar-text.pipe';
import { RoundPipe } from './round.pipe';

@NgModule({
    declarations: [
        SafePipe,
        AvatarTextPipe,
        RoundPipe
    ],
  exports: [
    SafePipe,
    AvatarTextPipe,
    RoundPipe
  ],
    imports: [
        CommonModule
    ]
})
export class PipesModule { }
