import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {QRFormService} from "../../../../infrastructure/services/qr.service";

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  @Input() qrForm: boolean = false;
  @Input() qrLabel: string = "";
  @Input() formUrl: string = "";
  @Input() inputProperties: any;
  @Output() formSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();

  loadingQr: boolean = true;
  qrGeneratedUrl: string = '';
  showMode: string = 'qr'

  constructor(
    private qrFormService: QRFormService
  ) {
  }

  onFormSave(event: any){
    console.log("Webcomponent Event =>", event);
    this.formSave.emit(event);
  }

  onFormSubmit(event: any){
    console.log("Webcomponent Event =>", event);
    this.formSubmit.emit(event);
  }

  ngOnInit() {
    console.log("Input Properties =>", this.inputProperties);
    if (this.qrForm && this.formUrl && this.inputProperties.formId && this.inputProperties.versionId && this.inputProperties.customKey){
      this.qrFormService.generateFormQR({
        formUrl: this.formUrl,
        formId: this.inputProperties.formId,
        versionId: this.inputProperties.versionId,
        customKey: this.inputProperties.customKey,
        page: this.inputProperties?.page || '1'
      }).pipe(
        takeUntil(this.destroy$)
      ).subscribe((value) => {
        console.log(value);
        this.qrGeneratedUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${value}`
      })
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  qrLoaded(){
    this.loadingQr = false
  }
}
