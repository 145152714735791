import { createReducer, on } from '@ngrx/store';
import {initialState} from "./state";
import * as processResultActions from "./process-result.actions"
import * as taskActions from "../task/task.actions";
import {loadProcessResultKanban, setViewComponentProcessResult} from "./process-result.actions";
import {
  ProcessResultI,
  ProcessResultKanbanI,
  ProcessResultKanbanStateI
} from "../../interfaces/process-result.interface";

export const processResultReducer = createReducer(
	initialState,
  on(processResultActions.setLoadingResultTaskAction, (state, action) => {
    return {
      ...state,
      loadingResultTaskAction: action.status
    }
  }),

  on(processResultActions.setLoadingResult, (state, action) => {
    return {
      ...state,
      loadingResults: action.status
    }
  }),

  on(processResultActions.setLoadingDetailProcessResult, (state, action) => {
    return {
      ...state,
      loadingDetailProcessResult: action.status
    }
  }),

  on(processResultActions.setLoadingBookmarked, (state, action) => {
    return {
      ...state,
      loadingBookmarked: action.status
    }
  }),

  on(processResultActions.setLoadingCreateResult, (state, action) => {
    return {
      ...state,
      loadingCreateResult: action.status
    }
  }),

  on(processResultActions.setLoadingProcessResultGridData, (state, action) => {
    return {
      ...state,
      loadingProcessResultGridData: action.status
    }
  }),

  on(processResultActions.setLoadingProcessResultMap, (state, action) => {
    return {
      ...state,
      loadingProcessResultMap: action.status
    }
  }),


  on(processResultActions.setShowFilterBar, (state, action) => {
    return {
      ...state,
      showFilterBar: action.status
    }
  }),

  on(processResultActions.setShowRightBar, (state, action) => {
    return {
      ...state,
      showRightBar: action.status
    }
  }),

  on(processResultActions.setResultDetailsTab, (state, action) => {
    return {
      ...state,
      detailTab: action.tab
    }
  }),

  on(processResultActions.setLoadingProcessResultPermissions, (state, action) => {
    return {
      ...state,
      loadingProcessResultPermissions: action.status
    }
  }),

  on(processResultActions.setViewComponentProcessResult, (state, action) => {
    return {
      ...state,
      viewComponent: action.value
    }
  }),

  on(processResultActions.loadProcessResultListSuccess, (state, action) => {
    return {
      ...state,
      processResultList: action.response.results,
      paginator: {
        count: action.response.count,
        page: action.response.page,
        previous: action.response.previous,
        limit: action.response.limit,
        next: action.response.next
      }
    }
  }),

  on(processResultActions.resetProcessResultMap, (state, action) => {

    return {
      ...state,
      processResultMap: {
        count: 0,
        loaded: 0,
        page: 0,
        valid: 0,
        result: []
      }
    }
  }),

  on(processResultActions.loadProcessResultMapSuccess, (state, action) => {
    const processResultMap = {...state.processResultMap};

    // Only load next expected page
    if (processResultMap.page + 1 === action.response.page){
      processResultMap.count = action.response.count;
      processResultMap.valid = processResultMap.valid + action.response.results.filter((v) => v.lat && v.lng).length
      processResultMap.result = [...processResultMap.result, action.response.results];
      processResultMap.page = action.response.page;
      processResultMap.loaded = processResultMap.result.reduce((acc, curr) => acc + curr.length, 0);
    }

    return {
      ...state,
      processResultMap: processResultMap
    }
  }),

  on(processResultActions.loadSelectedProcessResultSuccess, (state, action) => {
    return {
      ...state,
      selectedProcessResult: action.result
    }
  }),

  on(processResultActions.changeStatusProcessResultSuccess, (state, action) => {
    //Update selected process result
    const selectedProcessResult = state.selectedProcessResult?.id === action.result.id ? action.result : state.selectedProcessResult;

    //Update detail process result
    const detailProcessResult = state.detailProcessResult?.id === action.result.id ? action.result : state.detailProcessResult;

    //Update process result list
    const processResultList = [...state.processResultList || []];
    const result_index = processResultList.findIndex((val) => action.result.id === val.id);
    if (result_index !== -1){
      processResultList[result_index] = {...processResultList[result_index], process_status: action.result.process_status}
    }

    //Update kanban
    const previous_status_key = action.previousStatus.key
    const processResultKanban = {...state.processResultKanban}

    // Remove element from status column
    const processResultKanbanObject = {...processResultKanban[previous_status_key]}
    const process_result_index = (processResultKanbanObject?.result || []).findIndex((val)=>{
      return val.id === action.result.id
    })

    if (process_result_index !== -1) {
      const result = [...processResultKanbanObject.result];
      result.splice(process_result_index,1);
      processResultKanbanObject.result = result;
      processResultKanbanObject.count = processResultKanbanObject.count - 1
      processResultKanban[previous_status_key] = processResultKanbanObject
    }


    // Add element to status column
    const current_status_key = action.result.process_status.key;

    const processResultKanbanObjectNew = {...processResultKanban?.[current_status_key] || {count: 0, result:[]}};

    const process_result_new_index = processResultKanbanObjectNew.result.findIndex((val)=>{
      return val.id === action.result.id
    })

    if (process_result_new_index === -1) {
      const resultNew = [...processResultKanbanObjectNew.result];
      processResultKanbanObjectNew.count = processResultKanbanObjectNew.count + 1;

      if (action.index === null) {
        resultNew.unshift({...action.result});
      }else{
        resultNew.splice(action.index, 0, action.result)
      }
      processResultKanbanObjectNew.result = resultNew;
    }
    processResultKanban[current_status_key] = processResultKanbanObjectNew

    return {
      ...state,
      selectedProcessResult: selectedProcessResult,
      detailProcessResult: detailProcessResult,
      processResultList: processResultList,
      processResultKanban: processResultKanban
    }
  }),

  on(processResultActions.bookmarkProcessResultSuccess, (state, action) => {
    //Update selected process result
    const selectedProcessResult = state.selectedProcessResult?.id === action.result.id ? action.result : state.selectedProcessResult;

    //Update detail process result
    const detailProcessResult = state.detailProcessResult?.id === action.result.id ? action.result : state.detailProcessResult;

    //Update process result list
    const processResultList = [...state.processResultList];
    const result_index = processResultList.findIndex((val) => action.result.id === val.id);
    if (result_index !== -1){
      processResultList[result_index] = {...action.result}
    }

    return {
      ...state,
      selectedProcessResult: selectedProcessResult,
      detailProcessResult: detailProcessResult,
      processResultList: processResultList
    }
  }),


  on(processResultActions.createProcessResultSuccess, (state, action) => {
    return {
      ...state,
      selectedProcessResult: action.result
    }
  }),

  on(processResultActions.patchResultTaskSuccess, (state, action) => {
    return {
      ...state,
      selectedProcessResult: action.result
    }
  }),

  on(processResultActions.executeResultTaskActionSuccess, (state, action) => {
    return {
      ...state,
      selectedProcessResult: action.result
    }
  }),

  on(processResultActions.setDetailProcessResult, (state, action) => {
    return {
      ...state,
      detailProcessResult: action.result
    }
  }),

  on(processResultActions.setProcessResultTab, (state, action) => {
    return {
      ...state,
      processResultTab: action.tab
    }
  }),

  on(processResultActions.setProcessResultFilter, (state, action) => {
    return {
      ...state,
      processResultFilter: action.filter
    }
  }),

  on(processResultActions.setProcessResultGlobalSearch, (state, action) => {
    return {
      ...state,
      globalSearch: action.query
    }
  }),

  on(processResultActions.updateProcessResultPermissionsSuccess, (state, action) => {
    // Update details task if exist
    let detailsProcessResult = state.detailProcessResult ? {...state.detailProcessResult}: null;
    if (detailsProcessResult && detailsProcessResult.id === action.result.id){
      detailsProcessResult = {
        ...detailsProcessResult,
        ...action.result
      }
    }

    // Search task in task list and update
    let processResultList = [...state.processResultList]
    const index = processResultList.findIndex((val) => val.id === action.result.id)
    if (index !== -1){
      processResultList[index] = {
        ...processResultList[index],
        ...action.result
      }
    }

    return {
      ...state,
      processResultList: processResultList,
      detailProcessResult: detailsProcessResult
    }
  }),

  on(processResultActions.resetProcessResultKanban, (state, action) => {
    const processResultKanban = {...state.processResultKanban};

    if (processResultKanban[action.status]){
      processResultKanban[action.status] = {
        count: 0,
        page: 1,
        next: null,
        previous: null,
        result: Array.from({length:4}).map((_,i:number)=> {return {id: `${i}`} as ProcessResultI})
      }
    }

    return {
      ...state,
      processResultKanban: processResultKanban
    }
  }),

  on(processResultActions.loadProcessResultKanbanSuccess, (state, action) => {
    let processResultKanban = null;

    if (state.processResultKanban) {
      processResultKanban = {...state.processResultKanban}

      //Initialize object
      let processResultKanbanObject: ProcessResultKanbanStateI = {
        count: 0,
        next: null,
        page: 1,
        previous: null,
        result: []
      }
      if (processResultKanban[action.status] && !action.reset){
        processResultKanbanObject = {...processResultKanban[action.status]}
      }

      //Update object with received data
      processResultKanbanObject.count = action.response.count;
      processResultKanbanObject.next = action.response.next;
      processResultKanbanObject.previous = action.response.previous;
      processResultKanbanObject.page = action.response.page;
      const map = (processResultKanbanObject?.["result"] || []).map((val)=>val.id)
      const results = [...processResultKanbanObject?.result || []]

      action.response.results.forEach((val) =>{
        if (!map.includes(val.id)){
          results.push(val)
        }
      } )
      processResultKanbanObject["result"] = results;
      processResultKanban[action.status] = processResultKanbanObject
    } else {
      processResultKanban = {
        [action.status]: {
          count: action.response.count,
          next: action.response.next,
          previous: action.response.previous,
          page: action.response.page,
          result: action.response.results.map((element,index) => {
            return {
              ...element,
              position: index
            } as ProcessResultKanbanI
          })
        }
      }
    }

    return {
      ...state,
      processResultKanban: processResultKanban
    }
  }),

  on(processResultActions.loadProcessResultGridDataSuccess, (state, action) => {
    return {
      ...state,
      gridData: action.response.results
    }
  }),

  on(processResultActions.setGridDefaultState, (state, action) => {
    return {
      ...state,
      gridDefaultState: action.state
    }
  }),

);
