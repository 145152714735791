import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {filter, Observable, switchMap, take} from "rxjs";
import {selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";
import {RolesUserService} from "@mangosoft/mango-layout";
import {TaskPermissionsObjectI} from "../interfaces/task.interface";

@Injectable({
  providedIn: "root",
})
export class TaskService {

  constructor(
    private store: Store,
    private http: HttpClient,
    private permissionsUser: RolesUserService
  ) {}

  getTaskList(data:{processId: string, page: number|string, limit: number|string}): Observable<any> {
    const permissions = this.permissionsUser.getPermissionsObject().permissions || [];
    console.log("Permissions", permissions)

    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const pageParam = data.page ? `&page=${data.page}` : '';
        const limitParam = data.limit ? `&limit=${data.limit}` : '';
        return this.http.get(`${backendApiUrl}/tasks-list?processId=${data.processId}${pageParam}${limitParam}`)
      })
    )
  }

  getSelectedTask(data: {resultId: string, taskId: string}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/tasks-list/${data.resultId}/${data.taskId}`)
      })
    )
  }

  patchTaskActivity(data: {
    resultId: string,
    taskId: string,
    activityId: string,
    data: string
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.patch(`${backendApiUrl}/tasks-list/${data.resultId}/${data.taskId}/${data.activityId}`, data.data)
      })
    )
  }


  executeTaskAction(data:{resultId: string, taskId: string, action: string}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/tasks-list/${data.resultId}/${data.taskId}/status`
        const body = {
          status: data.action
        }
        return this.http.patch(
          url,body)
      })
    )
  }

  updateTaskPermissions(data:{resultId: string, taskId: string, body: TaskPermissionsObjectI}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/tasks-list/${data.resultId}/${data.taskId}/permissions`
        return this.http.patch(url,data.body)
      })
    )
  }

  removeTaskPermissions(data:{resultId: string, taskId: string, body: TaskPermissionsObjectI}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/tasks-list/${data.resultId}/${data.taskId}/permissions/${data.body.object.username}`
        return this.http.delete(url)
      })
    )
  }

}
