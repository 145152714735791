<div class="d-flex w-100 gap-2 h-100" *ngIf="!loading">
  <div class="h-100 d-flex align-items-center justify-content-center" style="width: 20px">
    <div
      class="rounded-circle d-flex justify-content-center align-items-center"
      style="width: 12px; height: 12px"
      [ngStyle]="{
          'background-color': child?.color,
          'border': '1px solid ' + child?.color
          }"
    >
    </div>
  </div>
  <div class="flex-grow-1 d-flex align-items-center h-100">
    <span class="text-normal lh-1">{{child?.label}}</span>
  </div>
  <div class="d-flex align-items-center h-100">
          <span
            (click)="show_elements(child)"
            class="text-sub-title lh-1 pointer rounded-pill px-1 text-center align-middle hover:bg-neutral-color-c"
            [ngStyle]="{'color': child?.color}"
          >
            {{total || 0}}
          </span>
  </div>
</div>

<div class="d-flex w-100 gap-2 h-100" *ngIf="loading">
  <div class="h-100 d-flex align-items-center justify-content-center" style="width: 20px">
    <div
      class="rounded-circle d-flex justify-content-center align-items-center skeleton-box"
      style="width: 12px; height: 12px"
    >
    </div>
  </div>
  <div class="flex-grow-1 d-flex align-items-center h-100">
    <div class="skeleton-box w-100 rounded-2" style="height: 12px"></div>
  </div>
</div>



