<div
	*ngIf="showModal"
	class="modal-backdrop fade show"
	style="z-index: 1040"
></div>

<div
	class="modal fade"
	id="exampleModal"
	[style.display]="showModal ? 'block' : 'none'"
	tabindex="-1"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
	[class.show]="showModal"
	(click)="onBackgroundClick($event)"
>
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mg-modal-title" id="exampleModalLabel">{{  content.title  }}</h5>
				<button type="button" class="btn-close"  (click)="closeModal(false)"></button>
			</div>
			<div class="modal-body mg-modal-title">
				{{ content.text1 }}
			</div>

			<div class="modal-footer-alert-mg">

        <div class="p-2 d-flex justify-content-end align-items-center" (click)="closeModal(false)">
          <div class="rounded-1 px-2 py-1 bg-white border d-flex justify-content-center align-items-center pointer">
            <span class="text-normal text-primary d-none d-sm-inline">
              {{ content.buttonOk }}
            </span>
          </div>
        </div>

        <div class="py-2 d-flex justify-content-end align-items-center" (click)="closeModal(true)">
          <div class="rounded-1 px-2 py-1 bg-primary-color d-flex justify-content-center align-items-center pointer">
            <span class="text-normal text-white d-none d-sm-inline">
              {{ content.buttonOk }}
            </span>
          </div>
        </div>
			</div>
		</div>
	</div>
</div>
