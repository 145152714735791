import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskI, TaskType} from "../../../infrastructure/interfaces/task.interface";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {Subject, takeUntil} from "rxjs";
import {ProcessService} from "../../../infrastructure/services/process.service";
import {Store} from "@ngrx/store";
import {
  loadProcessResultList,
  setLoadingResult,
  setProcessResultFilter,
  setViewComponentProcessResult
} from "../../../infrastructure/store/process-result/process-result.actions";
import {ProcessResultPaginatorI, ViewComponentType} from "../../../infrastructure/interfaces/process-result.interface";
import {initialProcessResultPaginator} from "../../../infrastructure/store/process-result/state";
import {selectProcessResultPaginator} from "../../../infrastructure/store/process-result/process-result.selector";

@Component({
  selector: 'app-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss']
})
export class GridCardComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  @Input() task: TaskI | null = null;
  @Input() process: ProcessI | null = null;
  @Input() index: number = 0;

  loading: boolean = false;
  total: number = 0;

  paginator: ProcessResultPaginatorI = initialProcessResultPaginator;

  constructor(
    private processService: ProcessService,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.loading = true;
    if (this.process && this.task){
      this.processService.getProcessStatsByTask({
        processId: this.process.id,
        taskId: this.task?.id
      }).pipe(
        takeUntil(this.destroy$)
      ).subscribe((value) => {
        this.total = value.total;
        this.loading = false;
      })
    }

    this.store.select(selectProcessResultPaginator).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.paginator = value;
    })
  }

  show_elements(task: TaskI | null){
    if (this.process) {
      let state_filter: string[] = [];
      if (task?.type === TaskType.GROUP){
        state_filter = (task?.children || []).map((v) => v.id)
      }else{
        state_filter = task ? [task?.id] : []
      }

      const statuses = this.process.process_states.states_list.map((v) => v.key)

      this.store.dispatch(setProcessResultFilter({
        filter: {
          isInitial: false,
          tasks: state_filter,
          process_status: statuses,
          bookmarked: false
        }
      }));
      this.store.dispatch(setLoadingResult({status: true}));
      this.store.dispatch(loadProcessResultList({
        processId: this.process?.id,
        page: 0,
        limit: this.paginator.limit,
        bookmarked: null,
        process_status: statuses,
        tasks: state_filter,
        filter: null,
        globalSearch: ''
      }))
      this.store.dispatch(setViewComponentProcessResult({value:ViewComponentType.table}));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
