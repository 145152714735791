import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as auditActions from "./audit.actions";
import {catchError, map, switchMap, throwError} from "rxjs";
import {AccessControlService} from "../../services/access-control.service";
import {ConfigI} from "../../interfaces/config.interface";
import {loadAuditStatus} from "./audit.actions";
import {AuditService} from "../../services/audit.service";
import {AuditResponseI} from "../../interfaces/audit.interface";

@Injectable()
export class AuditEffects {

  loadAuditStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auditActions.loadAuditStatus),
      switchMap((action) => {
        // Show splash
        this.store.dispatch(auditActions.setLoadingAuditStatus({status: true}));

        // Load data from audit
        return this.auditService.getStatusAudit({
          object_id: action.object_id
        }).pipe(
          map((response: AuditResponseI) => {
            this.store.dispatch(auditActions.setLoadingAuditStatus({status: false}));
            return auditActions.loadAuditStatusSuccess({ response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(auditActions.setLoadingAuditStatus({status: false}))
            return throwError(error);
          })
        );
      })
    )
  );

	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private auditService: AuditService
	) {}
}
