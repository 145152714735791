import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Store} from "@ngrx/store";
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {
  ProcessResultFilterI,
  ProcessResultI,
  ProcessResultKanbanI
} from "../../../infrastructure/interfaces/process-result.interface";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {
  auditTime,
  BehaviorSubject, combineLatest, concat,
  debounce,
  debounceTime,
  filter, merge, mergeAll, Observable,
  of,
  Subject,
  Subscription,
  takeUntil,
  tap, withLatestFrom
} from "rxjs";
import {
  selectProcessResultFilter, selectProcessResultGlobalSearch,
  selectProcessResultKanbanByStatus
} from "../../../infrastructure/store/process-result/process-result.selector";
import {
  changeStatusProcessResult,
  changeStatusProcessResultSuccess,
  loadProcessResultKanban,
  loadProcessResultKanbanSuccess, resetProcessResultKanban
} from "../../../infrastructure/store/process-result/process-result.actions";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";

@Component({
  selector: 'app-kanban-status-columns',
  templateUrl: './kanban-status-columns.component.html',
  styleUrls: ['./kanban-status-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanStatusColumnsComponent implements OnInit, OnDestroy, AfterViewInit{

  destroy$ = new Subject<void>();
  scrollIndex = new Subject<number>();

  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;

  @Input() state!: TaskStateI;
  @Input() first: boolean = false;
  @Input() last: boolean = false;

  process: ProcessI | null = null;

  itemSize = 120;
  elements: number = 4
  page: number = 2;
  next: number | null = null;
  previous: number | null = null;
  total: number = 0;
  processResultList$ = new BehaviorSubject< ProcessResultI[]>([]);
  processResultList: ProcessResultI[] = [];

  processResultFilter!: ProcessResultFilterI;
  processResultGlobalSearch: string = "";

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef
  ) {
  }


  ngAfterViewInit() {
    //Load process changes
    combineLatest([
      this.store.select(selectProcessResultFilter),
      this.store.select(selectProcessResultGlobalSearch),
      this.store.select(selectSelectedProcess),
    ]).pipe(
      takeUntil(this.destroy$),
      filter(([processResultFilter, processResultGlobalSearch, process]) => process != null)
    ).subscribe(([processResultFilter, processResultGlobalSearch, process]) => {

      this.process = process;
      this.processResultGlobalSearch = processResultGlobalSearch;
      this.processResultFilter = processResultFilter;
      this.store.dispatch(resetProcessResultKanban({status: this.state.key}));
      setTimeout(() =>{
        this.total = 0;
        this.page = 1;
        this.next = null;
        this.previous = null;
        this.processResultList$.next([]);
        this.processResultList$.next(
          Array.from({length:4}).map((_,i:number)=> {return {id: `${i}`} as ProcessResultI})
        );
        this.cd.markForCheck();
      },11);


      if (this.process) {
        const {tasks:_, bookmarked:__, process_status:___, ...filter} = processResultFilter;

        this.store.dispatch(loadProcessResultKanban({
          processId: this.process.id,
          page: 1,
          limit: this.elements*this.page,
          bookmarked: processResultFilter.bookmarked,
          process_status: this.state.key,
          tasks: processResultFilter.tasks,
          filter: filter,
          globalSearch: processResultGlobalSearch,
          reset: true
        }))
      }
    })

    //Load process result kanban by status
    this.store.select(selectProcessResultKanbanByStatus, {status: this.state.key}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      if (value) {
        this.total = value?.count || 0;
        this.page = value.page;
        this.next = value.next;
        this.previous = value.previous;
        this.cd.markForCheck();
        setTimeout(()=>{
          this.processResultList$.next(value?.result || []);
        },11)

      }
    })

    //On scroll index
    this.scrollIndex.pipe(
      takeUntil(this.destroy$),
      debounceTime(300)
    ).subscribe((index: number) => {
      const end = this.viewPort.getRenderedRange().end;
      const total = this.viewPort.getDataLength();
      const buffer = Math.floor(this.viewPort.getViewportSize()/this.itemSize);

      if (this.process && total === end && end != 0 && this.next && total < this.total) {
        const nextPage = Math.floor(total/this.elements) + (total%this.elements > 0 ? 1 : 0);
        this.processResultList$.next([...this.processResultList, ...Array.from({length:4}).map((_,i) => {
          return {id: `${i}`} as ProcessResultI
        })])

        //Load results of process
        const {tasks:_, bookmarked:__, process_status:___, ...filter} = this.processResultFilter;

        this.store.dispatch(loadProcessResultKanban({
          processId: this.process.id,
          page: nextPage + 1,
          limit: this.elements,
          bookmarked: this.processResultFilter.bookmarked,
          process_status: this.state.key,
          tasks: this.processResultFilter.tasks,
          filter: filter,
          globalSearch: this.processResultGlobalSearch,
          reset: false
        }))
      }
      this.cd.markForCheck();
    })

    this.processResultList$.subscribe((val) => {
      this.processResultList = val;
    })



    // Listen for filter changes
    // this.store.select(selectProcessResultFilter).pipe(
    //   takeUntil(this.destroy$),
    //   withLatestFrom(this.store.select(selectProcessResultGlobalSearch))
    // ).subscribe(([processResultFilter, search]) => {
    //   this.processResultFilter = processResultFilter;
    //
    //   if (this.process) {
    //     // Restart data to initial value
    //     this.total = 0;
    //     this.page = 1;
    //     this.next = null;
    //     this.previous = null;
    //     this.processResultList$.next([]);
    //     this.processResultList$.next([...this.processResultList, ...Array.from({length:4}).map((_,i) => {
    //       return {id: `${i}`} as ProcessResultI
    //     })])
    //     this.cd.markForCheck();
    //
    //     //Load results of process
    //     const {tasks:_, bookmarked:__, process_status:___, ...filter} = this.processResultFilter;
    //
    //     this.store.dispatch(loadProcessResultKanban({
    //       processId: this.process.id,
    //       page: 1,
    //       limit: this.elements,
    //       bookmarked: processResultFilter.bookmarked,
    //       process_status: this.state.key,
    //       tasks: processResultFilter.tasks,
    //       filter: filter,
    //       globalSearch: search,
    //       reset: true
    //     }))
    //   }
    // })
    //
    // // Listen for seach changes
    // this.store.select(selectProcessResultGlobalSearch).pipe(
    //   takeUntil(this.destroy$),
    //   withLatestFrom(this.store.select(selectProcessResultFilter))
    // ).subscribe(([processResultGlobalSearch, processResultFilter]) => {
    //
    //   if (this.process && this.processResultGlobalSearch !== processResultGlobalSearch) {
    //     // Restart data to initial value
    //     this.total = 0;
    //     this.page = 1;
    //     this.next = null;
    //     this.previous = null;
    //     this.processResultList$.next([]);
    //     this.processResultList$.next([...this.processResultList, ...Array.from({length:4}).map((_,i) => {
    //       return {id: `${i}`} as ProcessResultI
    //     })])
    //     this.cd.markForCheck();
    //
    //     //Load results of process
    //     const {tasks:_, bookmarked:__, process_status:___, ...filter} = this.processResultFilter;
    //
    //     this.store.dispatch(loadProcessResultKanban({
    //       processId: this.process.id,
    //       page: 1,
    //       limit: this.elements,
    //       bookmarked: processResultFilter.bookmarked,
    //       process_status: this.state.key,
    //       tasks: processResultFilter.tasks,
    //       filter: filter,
    //       globalSearch: processResultGlobalSearch,
    //       reset: true
    //     }))
    //     this.processResultGlobalSearch = processResultGlobalSearch;
    //   }
    // })
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onScrolledIndexChange(index:number){
    this.scrollIndex.next(index);
  }

  trackByIdx(i: number) {
    return i;
  }


  onDraggedEnd(element: ProcessResultI) {
    console.log("Drag Element => ", element);
  }

  onDrop(event: any) {
    if (this.process) {
      const insertResult = {...event.data}

      this.store.dispatch(changeStatusProcessResultSuccess({
        previousStatus: insertResult.process_status,
        index: null,
        result: {...insertResult, process_status: this.state},
      }))
      this.store.dispatch(changeStatusProcessResult({
        previousStatus: insertResult.process_status,
        processId: this.process.id,
        resultId: insertResult.id,
        data: {...insertResult, process_status: this.state},
      }))
    }


  }

}

