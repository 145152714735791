<div class="folder-container">
  <div class="folder">
    <img width="80px" src="assets/img/folder.png">
    <div class="d-flex">
      <div class="d-flex justify-content-center align-items-center">
        <span class="text-neutral-color-e text-sub-title">Creating folder in DAM</span>
      </div>
      <div *ngFor="let x of [].constructor(3)"  class="d-flex justify-content-center align-items-center ms-1">
        <div class="spinner-grow spinner-grow-sm text-neutral-color-e" style="width: 5px; height: 5px" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

    </div>

  </div>
</div>
