<div
  [popover]="'Write at least three characters to search'"
  placement="top left"
  triggers=""
  container="body"
  [adaptivePosition]="true"
  boundariesElement="viewport"
  class="w-100 position-relative typeahead-container"
  [formGroup]="searchForm"
>
    <input formControlName="search"
           #search_input
           [typeahead]="dataSource"
           [typeaheadAsync]="true"
           typeaheadOptionField="label"
           typeaheadGroupField="group"
           [typeaheadScrollable]="true"
           [typeaheadMinLength]="3"
           [typeaheadOptionsInScrollableView]="30"
           [typeaheadSelectFirstItem]="false"
           (typeaheadLoading)="typeaheadLoading($event)"
           (typeaheadNoResults)="typeaheadNoResults($event)"
           (typeaheadOnSelect)="keyDown($event, 'select')"
           (keydown)="keyDown($event, 'key')"
           [optionsListTemplate]="typeaheadTemplate"
           (typeaheadOnBlur)="keyDown($event, 'select')"
           (focusin)="searchFocus = true"
           (focusout)="searchFocus = false"
           class="global-search-input"
           [class.focusout]="!searchFocus"
    >

  <!-- Search icon -->
  <div class="position-absolute d-flex justify-content-center align-items-center top-0 bottom-0 h-100 px-1">
    <span class="material-symbols-outlined text-neutral-color-e" style="font-size: 20px"> search </span>
  </div>

  <!-- Searching -->
  <div
    *ngIf="loading"
    class="end-0 position-absolute d-flex justify-content-end align-items-center top-0 h-100 pe-3"
  >
      <span class="text-sub-title text-neutral-color-e">searching...</span>
  </div>

  <!-- No results -->
  <div
    *ngIf="noResults"
    class="end-0 position-absolute d-flex justify-content-end align-items-center top-0 h-100 pe-3"
  >
    <span class="text-sub-title text-neutral-color-e">No results founds</span>
  </div>

  <!-- Pill of search -->
  <div
    *ngIf="!searchFocus && search?.value"
    class="search-pill position-absolute d-flex justify-content-start align-items-center top-0 start-0 h-100"
    style="margin-left: 30px;"
  >
    <div
      class="d-flex justify-content-center align-items-center border px-2 rounded-1 bg-neutral-color-f shadow-sm"
      style="padding-top: 1px; padding-bottom: 1px"
      (click)="inputSearch.nativeElement.focus()"
    >
      <span class="material-symbols-sharp text-sub-title text-neutral-color-g">
        sell
      </span>
      <span class="text-normal ms-1" >
        {{search?.value}}
      </span>
      <span
        class="material-symbols-sharp text-sub-title text-neutral-color-g ms-2 pointer"
        (click)="$event.stopPropagation(); search?.patchValue('')"
      >
        cancel
      </span>
    </div>

  </div>

</div>


<ng-template #typeaheadTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <div class="d-flex w-100 flex-column">
    <div
      class="w-100 d-flex align-items-center px-3"
      *ngFor="let match of matches"
    >
      <!-- Grouping header -->
      <ng-container *ngIf="match.header">
        <div
          class="w-100 border-bottom"
          (click)="$event.stopPropagation()"
        >
          <span class="text-sub-title text-complementary-color-a fw-bolder">
            {{match.value | titlecase}}
          </span>
        </div>
      </ng-container>

      <!-- Match data -->
      <ng-container *ngIf="!match.header">
        <div
          class="w-100 d-flex align-items-center pointer py-1"
          [class.option-active]="typeaheadTemplateMethods.isActive(match)"
          (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
          (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
        >
          <div class="flex-grow-1 d-flex align-items-center justify-content-start">
            <span class="text-normal fw-semibold text-link-color-a">{{match.value}}</span>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <span
              class="rounded-pill bg-complementary-color-a lh-0 text-white text-mini"
              style="padding: 1px 4px"
            >
              {{match.item.total}}
            </span>

          </div>

        </div>
      </ng-container>

    </div>
  </div>
</ng-template>
