import { createFeatureSelector, createSelector } from '@ngrx/store';
import {StateTask, taskFeatureKey} from "./state";
import {DetailTabs, TaskI, TaskPaginatorI, TaskResultTab} from "../../interfaces/task.interface";
import {StateProcessResult} from "../process-result/state";
import {processResultState} from "../process-result/process-result.selector";

export const processState = createFeatureSelector<StateTask>(taskFeatureKey);

export const selectLoadingTask = createSelector(
  processState,
  (state: StateTask): boolean => {
    return state.loadingTask
  }
)

export const selectLoadingTaskPermissions = createSelector(
  processState,
  (state: StateTask): boolean => {
    return state.loadingTaskPermissions
  }
)

export const selectTaskShowFilterBar = createSelector(
  processState,
  (state: StateTask): boolean => {
    return state.showFilterBar
  }
)

export const selectTaskShowRightBar = createSelector(
  processState,
  (state: StateTask): boolean => {
    return state.showRightBar
  }
)

export const selectTaskDetailsTab = createSelector(
  processState,
  (state: StateTask): DetailTabs => {
    return state.detailTab
  }
)

export const selectSelectedTask = createSelector(
  processState,
  (state: StateTask): TaskI | null => {
    return state.selectedTask
  }
)

export const selectTaskList = createSelector(
  processState,
  (state: StateTask): TaskI[] => {
    return state.taskList
  }
)

export const selectTaskPaginator = createSelector(
  processState,
  (state: StateTask): TaskPaginatorI => {
    return state.paginator
  }
)

export const selectDetailsTask = createSelector(
  processState,
  (state: StateTask): TaskI | null => {
    return state.detailsTask
  }
)

export const selectTaskResultTab = createSelector(
  processState,
  (state: StateTask): TaskResultTab => {
    return state.taskResultTab
  }
)
