<div class="process-model-gallery flex-shrink-0 p-3">
  <!-- Skeleton -->
  <ng-container *ngIf="loadingProcess">
    <div
      class="process-model-gallery process-model-gallery-element skeleton-box"
      *ngFor="let i of [].constructor(2)"
    >

    </div>
  </ng-container>

  <ng-container *ngIf="!loadingProcess">
    <div
      class="process-model-gallery-element d-flex flex-column align-items-center justify-content-center
             hover:bg-neutral-color-f pointer p-2"
      *ngFor="let process of processList"
      (click)="selectProcess(process)"
    >
      <div class="d-flex flex-grow-1 justify-content-center align-items-center">
        <span
          *ngIf="process.icon && !process.icon_url"
          class="material-symbols-outlined text-complementary-color-a" style="font-size: 40px"
        >
          {{process.icon}}
        </span>
        <img *ngIf="process?.icon_url" src="{{process?.icon_url}}" height="40px">
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="text-title text-center text-complementary-color-a">{{process.label}}</span>
      </div>

    </div>
  </ng-container>


</div>
