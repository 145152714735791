import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {filter, map, Observable, switchMap, take} from "rxjs";
import {selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";
import {MapResultI, MapResultResponseI, ProcessResultI} from "../interfaces/process-result.interface";
import {ActivityI, TaskI, TaskPermissionsObjectI} from "../interfaces/task.interface";
import {TaskStateI} from "../interfaces/process.interface";

@Injectable({
  providedIn: "root",
})
export class ProcessResultService {

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  getProcessResultMap(
    processId: string,
    page: number | string,
    limit: number | string,
    bookmarkedFilter: string | null | boolean,
    processStatusFilter: string[] | string | null,
    processTaskFilter: string[] | string | null,
    dynamicFilter: any,
    globalSearch: string
  ): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const params = [];
        if (page) params.push(`page=${page}`)
        if (limit) params.push(`limit=${limit}`)
        if (bookmarkedFilter) params.push(`bookmarked=${bookmarkedFilter}`)
        if (processStatusFilter) params.push(`process_status=${processStatusFilter}`)
        if (processTaskFilter) params.push(`process_tasks=${processTaskFilter}`)
        if (globalSearch) params.push(`search=${globalSearch}`)
        if (dynamicFilter) {
          const dynamicFilterStr = Object.keys(dynamicFilter).reduce((acc: string, curr: string) => {
            if (dynamicFilter[curr]) return acc + `$${curr}=${dynamicFilter[curr]}`;
            return acc;
          }, "").slice(1)
          if (dynamicFilterStr) params.push(`filter=${dynamicFilterStr}`)
        }
        const queryParams = params.length > 0 ? '?' + params.join('&') : ''

        return this.http.get(`${backendApiUrl}/process/${processId}/map-list${queryParams}`).pipe(
          map((data: any) => {
            return {
              ...data,
              results: data.results.map((result: any[]) => ({
                id: result[0],
                percentComplete: result[1],
                lat: result[2],
                lng: result[3],
                color: result[4]
              }))
            }
          })
        )
      })
    )
  }

  getProcessResultKanban(
    processId: string,
    page: number | string,
    limit: number | string,
    bookmarkedFilter: string | null | boolean,
    processStatusFilter: string[] | string | null,
    processTaskFilter: string[] | string | null,
    dynamicFilter: any,
    globalSearch: string
  ): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const params = [];
        if (page) params.push(`page=${page}`)
        if (limit) params.push(`limit=${limit}`)
        if (bookmarkedFilter) params.push(`bookmarked=${bookmarkedFilter}`)
        if (processStatusFilter) params.push(`process_status=${processStatusFilter}`)
        if (processTaskFilter) params.push(`process_tasks=${processTaskFilter}`)
        if (globalSearch) params.push(`search=${globalSearch}`)
        if (dynamicFilter) {
          const dynamicFilterStr = Object.keys(dynamicFilter).reduce((acc: string, curr: string) => {
            if (dynamicFilter[curr]) return acc + `$${curr}=${dynamicFilter[curr]}`;
            return acc;
          }, "").slice(1)
          if (dynamicFilterStr) params.push(`filter=${dynamicFilterStr}`)
        }
        const queryParams = params.length > 0 ? '?' + params.join('&') : ''

        return this.http.get(`${backendApiUrl}/process/${processId}/kanban-list${queryParams}`)
      })
    )
  }

  getProcessResultList(
    processId: string,
    page: number | string,
    limit: number | string,
    bookmarkedFilter: string | null | boolean,
    processStatusFilter: string[] | string | null,
    processTaskFilter: string[] | string | null,
    dynamicFilter: any,
    globalSearch: string
  ): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const params = [];
        if (page) params.push(`page=${page}`)
        if (limit) params.push(`limit=${limit}`)
        if (bookmarkedFilter) params.push(`bookmarked=${bookmarkedFilter}`)
        if (processStatusFilter) params.push(`process_status=${processStatusFilter}`)
        if (processTaskFilter) params.push(`process_tasks=${processTaskFilter}`)
        if (globalSearch) params.push(`search=${globalSearch}`)
        if (dynamicFilter) {
          const dynamicFilterStr = Object.keys(dynamicFilter).reduce((acc: string, curr: string) => {
            if (dynamicFilter[curr]) return acc + `$${curr}=${dynamicFilter[curr]}`;
            return acc;
          }, "").slice(1)
          if (dynamicFilterStr) params.push(`filter=${dynamicFilterStr}`)
        }
        const queryParams = params.length > 0 ? '?' + params.join('&') : ''

        return this.http.get(`${backendApiUrl}/process/${processId}/result-list${queryParams}`)
      })
    )
  }

  createProcessResult(processId: string): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.post(`${backendApiUrl}/process/${processId}/result-list`, {})
      })
    )
  }

  updateProcessResult(data: {processId: string, resultId: string, data: ProcessResultI}): Observable<any>{
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.patch(`${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}`, data.data)
      })
    )
  }

  updateProcessResultTask(resultId: string, taskId:string, data: any): Observable<any>{
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.post(`${backendApiUrl}/result-list/${resultId}/${taskId}`, data)
      })
    )
  }

  patchProcessResultTask(data:{processId: string, resultId: string, taskId:string, activityId: string, body: any}): Observable<any>{
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.patch(`${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}/${data.taskId}/${data.activityId}`, data.body)
      })
    )
  }

  getProcessResult(data:{processId: string, resultId: string}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}`)
      })
    )
  }

  getProcessResultGridData(data:{
    processId: string,
    search: string,
    status: string,
    bookmarkedFilter: string | null | boolean,
    processStatusFilter: string[] | string | null,
    processTaskFilter: string[] | string | null,
    dynamicFilter: any,
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const params = [];
        if (data.search) params.push(`search=${data.search}`)
        if (data.status) params.push(`task_status=${data.status}`)
        if (data.bookmarkedFilter) params.push(`bookmarked=${data.bookmarkedFilter}`)
        if (data.processStatusFilter) params.push(`process_status=${data.processStatusFilter}`)
        if (data.processTaskFilter) params.push(`process_tasks=${data.processTaskFilter}`)
        if (data.dynamicFilter) {
          const dynamicFilterStr = Object.keys(data.dynamicFilter).reduce((acc: string, curr: string) => {
            if (data.dynamicFilter[curr]) return acc + `$${curr}=${data.dynamicFilter[curr]}`;
            return acc;
          }, "").slice(1)
          if (dynamicFilterStr) params.push(`filter=${dynamicFilterStr}`)
        }
        const queryParams = params.length > 0 ? '?' + params.join('&') : ''

        return this.http.get(`${backendApiUrl}/process/${data.processId}/stats${queryParams}`)
      })
    )
  }

  removeProcessResult(data: {processId: string, resultId: string, data: {page: string, limit: string}}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.delete(
          `${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}`,{body: data.data})
      })
    )
  }

  executeProcessResultAction(data:{processId: string, resultId: string, taskId: string, status: TaskStateI}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}/${data.taskId}/status?`
        const body = {
          status: data.status
        }
        return this.http.patch(
          url,body)
      })
    )
  }

  updateProcessResultPermissions(data:{processId: string, resultId: string, body: TaskPermissionsObjectI}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}/permissions`
        return this.http.patch(url,data.body)
      })
    )
  }

  removeProcessPermissions(data:{resultId: string, processId: string, body: TaskPermissionsObjectI}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/process/${data.processId}/result-list/${data.resultId}/permissions/${data.body.object.username}`
        return this.http.delete(url)
      })
    )
  }

  getTypeaheadOptions(data:{processId: string, query: string}): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        const url = `${backendApiUrl}/process/${data.processId}/result-list/typeahead?search=${data.query}`
        return this.http.get(url)
      })
    )
  }


  createTaskTree(processTasks: TaskI[]) {

    //Convert tasks in a tree using id and parentId
    const tasks = JSON.parse(JSON.stringify(processTasks));

    //Maping of task id and position in array of tasks
    const idMapping = tasks.reduce((accum:any, curr:TaskI, i:number) => {
      accum[curr.id] = i;
      return accum;
    },{})

    //Build task tree using references
    let taskTree: TaskI[] = [];
    tasks.forEach((task: TaskI) => {
      if (!task.parentId) {
        taskTree = [...taskTree,task];
        return;
      }
      let parentTask = tasks[idMapping[task.parentId]]
      parentTask.children = [...(parentTask.children || []), task]
    });

    return taskTree
  }
}
