import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalStorageService } from '../../../infrastructure/services/local-storage.service';
import {Store} from "@ngrx/store";
import { loadProcessScrollList } from '../../../infrastructure/store/process-scroll/process-scroll.action';
import { selectedProcessScroll } from '../../../infrastructure/store/process-scroll/process-scroll.selector';
import { Subject, takeUntil } from 'rxjs';
import { ProcessScrollI } from '../../../infrastructure/interfaces/process-scroll.interface';
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {TaskI} from "../../../infrastructure/interfaces/task.interface";
import {ProcessResultService} from "../../../infrastructure/services/process-result.service";

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  private destroy$ = new Subject<void>();

  selectedProcess: ProcessI | null = null
  processTasks: TaskI[] = []

  constructor(
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private store: Store,
    private processResultService: ProcessResultService

  ) {
  }

  ngOnInit(): void {
    this.store.select(selectSelectedProcess).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.selectedProcess = value;
      this.processTasks = this.processResultService.createTaskTree(value?.tasks || [])
    })
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
