import {Component, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {SettingsI} from "../../../infrastructure/interfaces/settings.interface";
import {selectSelectedSetting, selectSettingList} from "../../../infrastructure/store/settings/settings.selector";
import {setSelectedSetting} from "../../../infrastructure/store/settings/settings.actions";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-settings-leftbar',
  templateUrl: './settings-leftbar.component.html',
  styleUrls: ['./settings-leftbar.component.scss']
})
export class SettingsLeftbarComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  settings: SettingsI[] = [];
  filteredSettings: SettingsI[] = [];

  selectedSetting: SettingsI | null = null;

  form!: FormGroup

  constructor(
    private store: Store,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      'search': ''
    })
  }

  ngOnInit() {
    // Listen for settings list changes
    this.store.select(selectSettingList).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.settings = value;
      this.filteredSettings = value;
    })

    // Listen for selected settings changes
    this.store.select(selectSelectedSetting).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.selectedSetting = value;
    })

    // Listen for search changes
    this.search?.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a,b) => JSON.stringify(a) === JSON.stringify(b)),
      debounceTime(400)
    ).subscribe((value) => {
      const re = new RegExp(`${value}`,'gi');
      this.filteredSettings = this.settings.filter((v) => re.test(v.label))
      console.log("Search => ", value);
      console.log(this.filteredSettings);
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectSetting(value: SettingsI) {
    this.store.dispatch(setSelectedSetting({setting: value}));
    this.router.navigate(['/settings', value.id])
  }

  get search(){
    return this.form.get("search") as FormControl
  }
}
