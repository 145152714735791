
//IDENTIFICADOR DEL REDUCER
import {AuditI} from "../../interfaces/audit.interface";

export const auditFeatureKey = 'audit';

export interface StateAudit {
  statusAudit: AuditI[];
  loadingStatusAudit: boolean;
}

//ESTADO INICIAL
export const initialState: StateAudit = {
  statusAudit: [],
  loadingStatusAudit: true
}
