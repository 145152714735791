//IDENTIFICADOR DEL REDUCER

import {EmailProviderI, SettingsI, UserSettingsI} from "../../interfaces/settings.interface";

export const settingsFeatureKey = 'userSettings';

const settings = [
  {
    id: 'email',
    label: 'Email Accounts',
    image: 'assets/img/email-settings.png'
  }
]


export interface StateSettings {
  loadingSettings: boolean;
  loadingEmailProviders: boolean;
  loadingCrudUserEmailSettings: boolean;
  emailProviders: EmailProviderI[];
  settings: SettingsI[];
  selectedSetting: SettingsI;
  userSettings: UserSettingsI | null;
}

//ESTADO INICIAL
export const initialState: StateSettings = {
  loadingSettings: false,
  loadingEmailProviders: false,
  loadingCrudUserEmailSettings: false,
  settings: settings,
  selectedSetting: settings[0],
  userSettings: null,
  emailProviders: []
}
