import { createFeatureSelector, createSelector } from '@ngrx/store';
import {settingsFeatureKey, StateSettings} from "./state";
import {EmailProviderI, SettingsI, UserEmailSettingsI} from "../../interfaces/settings.interface";

export const settingsState = createFeatureSelector<StateSettings>(settingsFeatureKey);

export const selectLoadingSettings = createSelector(
  settingsState,
  (state: StateSettings): boolean => {
    return state.loadingSettings
  }
)

export const selectLoadingEmailProviders = createSelector(
  settingsState,
  (state: StateSettings): boolean => {
    return state.loadingEmailProviders
  }
)

export const selectLoadingCrudUserEmailSettings = createSelector(
  settingsState,
  (state: StateSettings): boolean => {
    return state.loadingCrudUserEmailSettings
  }
)

export const selectEmailProviders = createSelector(
  settingsState,
  (state: StateSettings): EmailProviderI[] => {
    return state.emailProviders || []
  }
)


export const selectSettingList = createSelector(
  settingsState,
  (state: StateSettings): SettingsI[] => {
    return state.settings
  }
)


export const selectSelectedSetting = createSelector(
  settingsState,
    (state: StateSettings): SettingsI => {
      return state.selectedSetting
    }
)

export const selectEmailUserSettings = createSelector(
  settingsState,
  (state: StateSettings): UserEmailSettingsI[] => {
    return state.userSettings?.email || []
  }
)

