
//IDENTIFICADOR DEL REDUCER
import { MapProcessI } from "../../interfaces/map-process.interface";
import { ProcessScrollI } from "../../interfaces/process-scroll.interface";

// Identificador del Reducer
export const mapProcessFeatureKey = 'mapProcess';

export interface StateMapProcess {
  selectedMapProcess: MapProcessI;
}

// Estado inicial
export const initialState: StateMapProcess = {
  selectedMapProcess: {
    address: "",
    zipCode: ""
  },
};
