import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as processScrollActions from "./process-scroll.action"

import {catchError, map, switchMap, tap, throwError} from "rxjs";
import { ProcessScrollI } from '../../interfaces/process-scroll.interface';
import { ProcessScrollService } from "../../services/process-scroll.service";

@Injectable()
export class ProcessScrollEffects {

  loadProcessScrollList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processScrollActions.loadProcessScrollList),
      switchMap(() => {
        return this.processScrollService.getProcessList().pipe(
          // tap(response => console.log('Response from service:', response)),
          map((response: ProcessScrollI) => {
            return processScrollActions.loadProcessListScrollSuccess({ response: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            return throwError(error);
          })
        );
      })
    )
  );


	constructor(
		private actions$: Actions,
    private processScrollService: ProcessScrollService
	) {}
}
