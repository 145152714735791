
//IDENTIFICADOR DEL REDUCER
import {PermissionsObjectI} from "../../interfaces/permissions.interface";

export const permissionsFeatureKey = 'permissions';

export interface StatePermissions {
  objects: PermissionsObjectI[];
}

//ESTADO INICIAL
export const initialState: StatePermissions = {
  objects: []
};
