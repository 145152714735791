import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-phi-viewer',
  templateUrl: './phi-viewer.component.html',
  styleUrls: ['./phi-viewer.component.scss']
})
export class PhiViewerComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  @Input() phiUrl: string | null = null;
  @Input() patientId: string | null = null;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
