<app-splash *ngIf="loadingConfig"></app-splash>
<div class="h-screen w-screen">
  <mg-layout
    [version]="version"
    [listSidebar]="listSidebar"
    [url]="url"
    [ids]="ids"
    [loading]="loadingConfig">
    <router-outlet mg-router></router-outlet>
  </mg-layout>
</div>

