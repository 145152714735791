<div class="d-flex w-100 h-100 gap-2 align-items-start">
  <div
    class="permission d-flex justify-content-center align-items-center border rounded-circle pointer"
    tooltip="List" containerClass="text-mini fw-bold mb-2"
    [ngClass]="{
      'border-success-color bg-success-color': value.list,
      'border-neutral-color-h': !value.list
    }"
    style="width: 15px; height: 15px"
    (click)="togglePermission('list')"
  >
    <span
      class="text-mini fw-bolder"
      [ngClass]="{
        'text-white': value.list,
        'text-neutral-color-h': !value.list
      }"
    >L</span>
  </div>
  <div
    class="permission d-flex justify-content-center align-items-center border rounded-circle pointer"
    tooltip="Read" containerClass="text-mini fw-bold mb-2"
    [ngClass]="{
      'border-success-color bg-success-color': value.read,
      'border-neutral-color-h': !value.read
    }"
    style="width: 15px; height: 15px"
    (click)="togglePermission('read')"
  >
    <span
      [ngClass]="{
        'text-white': value.read,
        'text-neutral-color-h': !value.read
      }"
      class="text-mini fw-bolder"
    >R</span>
  </div>
  <div
    class="permission d-flex justify-content-center align-items-center border rounded-circle pointer"
    tooltip="Write" containerClass="text-mini fw-bold mb-2"
    [ngClass]="{
      'border-success-color bg-success-color': value.write,
      'border-neutral-color-h': !value.write
    }"
    style="width: 15px; height: 15px"
    (click)="togglePermission('write')"
  >
    <span
      [ngClass]="{
        'text-white': value.write,
        'text-neutral-color-h': !value.write
      }"
      class="text-mini fw-bolder"
    >W</span>
  </div>
  <div
    class="permission d-flex justify-content-center align-items-center border rounded-circle pointer"
    tooltip="Delete" containerClass="text-mini fw-bold mb-2"
    [ngClass]="{
      'border-success-color bg-success-color': value.delete,
      'border-neutral-color-h': !value.delete
    }"
    style="width: 15px; height: 15px;"
    (click)="togglePermission('delete')"
  >
    <span
      [ngClass]="{
        'text-white': value.delete,
        'text-neutral-color-h': !value.delete
      }"
      class="text-mini fw-bolder"
    >D</span>
  </div>
</div>
