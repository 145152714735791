<div class="w-100 px-3 d-flex flex-column">
    <div
      *ngFor="let log of statusAuditLogs; let first=first"
      class="w-100"
    >
      <div *ngIf="!first" class="border-top border-top-1 my-3"></div>
      <div class="d-flex pb-2">
        <span class="material-symbols-outlined text-title text-complementary-color-a me-1">
          calendar_month
        </span>
        <span class="mb-0 text-normal lh-0">
          {{ log.date | date: 'dd/MM/yyyy, h:mm a' }}
        </span>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end overflow-hidden"  style="flex: 1">
            <span
              class="text-mini rounded-pill w-100 text-center lh-0 truncate truncate-1"
              [ngStyle]="{
                'padding': '0 2px',
                'border-style': 'solid',
                'border-width': '1px',
                'background-color': log.metadata?.old_object?.process_status?.color,
                'color': log.metadata?.old_object?.process_status?.fontColor,
                'border-color': log.metadata?.old_object?.process_status?.color
              }"
            >
              {{ log.metadata?.old_object?.process_status?.label }}
            </span>
        </div>
        <div class="d-flex align-items-center"  style="width: 20px;">
          <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
            arrow_right_alt
          </span>
        </div>
        <div class="d-flex justify-content-start overflow-hidden"  style="flex: 1">
           <span
             class="text-mini rounded-pill w-100 text-center lh-0"
             [ngStyle]="{
                'padding': '0 2px',
                'border-style': 'solid',
                'border-width': '1px',
                'background-color': log.metadata?.new_object?.process_status?.color,
                'color': log.metadata?.new_object?.process_status?.fontColor,
                'border-color': log.metadata?.new_object?.process_status?.color
              }"
           >
              {{ log.metadata?.new_object?.process_status?.label }}
            </span>
        </div>

      </div>
      <div class="d-flex align-items-center pt-2">
        <div>
          <img [src]="log.user?.picture || '../../../../assets/img/avatar.svg'"
               class="rounded-circle mr-3"
               width="40"
               height="40"
               alt="{{ log.user.full_name }}"
               onerror="this.onerror=null; this.src='../../../../assets/img/avatar.svg'">
        </div>
        <div class="ps-2">
          <div style="line-height: 15px;">
            <strong class="text-normal">{{ log.user.full_name }}</strong>
          </div>
          <div>
            <span class="mb-0 text-normal">{{ log.user.email }}</span>
          </div>
        </div>
      </div>
    </div>
</div>
