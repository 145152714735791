<div
  class="h-100 border p-3 rounded-2 grid-card"
>
  <!-- Card Image -->
  <div class="d-flex justify-content-center align-items-center pb-1">
    <img *ngIf="!loading" [src]="task?.picture" class="grid-card-image">
    <div *ngIf="loading" class="skeleton-box rounded-circle" style="width: 60px; height: 60px"></div>
  </div>

  <!-- Card main element -->
  <div *ngIf="!loading" class="d-flex w-100 gap-2 grid-card-element">
    <div class="h-100 d-flex align-items-center">
      <div
        class="rounded-circle d-flex justify-content-center align-items-center"
        style="width: 20px; height: 20px"
        [ngStyle]="{
          'background-color': task?.color,
          'border': '1px solid ' + task?.color
          }"
      >
        <span class="text-white text-sub-title lh-1">{{index}}</span>
      </div>
    </div>
    <div class="flex-grow-1 d-flex align-items-center h-100">
      <span class="text-sub-title fw-bold lh-1">{{task?.label}}</span>
    </div>
    <div  class="d-flex align-items-center h-100">
        <span
          (click)="show_elements(task)"
          class="text-sub-title lh-1 pointer px-1 rounded-pill text-center align-middle hover:bg-neutral-color-c"
          [ngStyle]="{'color': task?.color}"
        >
          {{total}}
        </span>
    </div>
  </div>

  <!-- Card main element skeleton-->
  <div *ngIf="loading" class="d-flex w-100 gap-2 grid-card-element">
    <div class="h-100 d-flex align-items-center">
      <div
        class="rounded-circle d-flex justify-content-center align-items-center skeleton-box"
        style="width: 20px; height: 20px"
      >
      </div>
    </div>
    <div class="flex-grow-1 d-flex align-items-center h-100">
      <div class="skeleton-box w-100 rounded-2" style="height: 14px"></div>
    </div>
  </div>

  <div *ngIf="(task?.children || []).length > 0" class="w-100 border-top"></div>


  <!-- Card secondary element -->
  <ng-container
    *ngFor="let child of task?.children || []; let taskLast=last; let taskFirst=first; let taskIndex=index"
  >
    <div class="grid-card-element">
      <app-grid-card-element (showElements)="show_elements($event)"  [process]="process"  [child]="child"></app-grid-card-element>
    </div>

    <div *ngIf="!taskLast" class="w-100 border-top"></div>
  </ng-container>

</div>
