<!------- Data ------->
<div
  *ngIf="!loadingDetailProcessResult && processResult"
  class="overflow-y-scroll h-100"
>
    <!-- Action Bookmarked -->
    <div
      *ngIf="actions && processResult"
      class="d-flex justify-content-start align-items-center w-100 px-3"
    >

      <!-- Classification Title -->
      <div class="py-2">
      <span class="text-complementary-color-a fw-bold">
        Bookmarked:
      </span>
      </div>

      <!-- Classification Data -->
      <div
        class="d-flex justify-content-start align-items-center ps-2 mt-1"
      >
        <span
          *ngIf="processResult?.bookmarked === bookMarketType.inactive && !loadingBookmarked"
          class="material-symbols-outlined hover:material-symbols-outlined text-title text-primary-color pointer"
          (click)="setBookmarkProcessResult(bookMarketType.active)"
        >
          bookmark
        </span>
        <span
          *ngIf="processResult?.bookmarked === bookMarketType.active && !loadingBookmarked"
          class="material-symbols-sharp hover:material-symbols-sharp text-primary-color pointer"
          style="font-size: 1rem"
          (click)="setBookmarkProcessResult(bookMarketType.inactive)"
        >
        bookmark
      </span>
        <span
          *ngIf="loadingBookmarked"
          class="spinner-grow spinner-grow-sm text-primary-color"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>

    <!-- Action Status -->
    <div
      *ngIf="actions && processResult"
      class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
    >

      <!-- Classification Title -->
      <div class="py-2">
    <span class="text-complementary-color-a fw-bold">
      Status:
    </span>
      </div>

      <!-- Classification Data -->
      <div
        class="d-flex justify-content-start align-items-center pb-3"
      >
        <app-process-state
          [stateList]="processModel?.process_states?.states_list || []"
          [selectedState]="processResult?.process_status || null"
          [editable]="processResult?.user_permissions?.write || false"
          (onSelectState)="processStatusChange($event)"
        ></app-process-state>
      </div>

      <div
        class="w-100"
        style="border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
      ></div>

    </div>

    <!-- Detail Data Classification -->
    <div
      class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
      *ngFor="let detail of processResult?.modalHeaderData?.detailsData; let first=first; let last=last"
    >
      <!-- divider -->
      <div
        *ngIf="!first"
        class="w-100"
        style="border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
      ></div>

      <!-- Classification Title -->
      <div *ngIf="detail.label" class="py-2">
    <span class="text-complementary-color-a fw-bold">
      {{detail.label}}
    </span>
      </div>

      <!-- Classification Data -->
      <div
        class="d-flex flex-column justify-content-start pb-2"
        [class.pb-3]="dataLast"
        *ngFor="let data of detail.data; let dataFirst=first; let dataLast=last"
      >
        <div class="d-flex justify-content-start align-items-start">
      <span class="text-normal fw-bold">
        {{data.label}}:
      </span>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-start"
          *ngFor="let value of data.value; let valueFirst=first; let valueLast=last"
        >
      <span class="text-normal">
        {{value || 'To be defined'}}
      </span>
        </div>
      </div>

    </div>

    <div
      *ngIf="processResult?.updated_by"
      class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
    >
      <!-- divider -->
      <div
        class="w-100"
        style="border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
      ></div>

      <!-- Classification Title -->
      <div class="py-2">
    <span class="text-complementary-color-a fw-bold">
      Updated By
    </span>
      </div>

      <!-- Classification Data -->
      <div
        class="d-flex justify-content-start pb-1"
      >
      <span class="text-normal">
        {{processResult?.updated_by}}
      </span>
      </div>

    </div>

    <div
      *ngIf="processResult?.updated_at"
      class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
    >

      <!-- Classification Title -->
      <div class="py-2">
    <span class="text-complementary-color-a fw-bold">
      Updated Date
    </span>
      </div>

      <!-- Classification Data -->
      <div
        class="d-flex justify-content-start pb-1"
      >
      <span class="text-normal">
        {{processResult?.updated_at | date:'full'}}
      </span>
      </div>

    </div>
</div>


<!-- No selected Row Message-->
<div *ngIf="!loadingDetailProcessResult && !processResult"
     class="h-100 w-100 d-flex flex-column justify-content-center align-content-center"
>
  <div class="w-100 d-flex justify-content-center">
          <span class="material-symbols-sharp text-neutral-color-i" style="font-size: 48px">
            gesture_select
          </span>
  </div>
  <div class="w-100 d-flex justify-content-center">
          <span class="text-normal text-neutral-color-e text-center">
            There is no data selected<br>
            Please select row to show details
          </span>
  </div>

</div>

<!------- Skeletons ------->
<div
  *ngIf="loadingDetailProcessResult"
  class="h-100 overflow-y-scroll"
>

  <!-- Action Bookmarked -->
  <div
    *ngIf="actions"
    class="d-flex justify-content-start align-items-center w-100 px-3"
  >

    <!-- Classification Title -->
    <div class="skeleton-box rounded-1 mt-3" style="height: 14px; width: 96px"></div>
    <div class="skeleton-box rounded-circle mt-3 ms-1" style="height: 14px; width: 14px" ></div>
  </div>

  <!-- Action Status -->

  <div
    *ngIf="actions"
    class="d-flex mt-3 flex-column justify-content-center align-items-start w-100 px-3"
  >

    <!-- Classification Title -->
    <div class="mt-3 skeleton-box rounded-1" style="height: 12px; width: 50px"></div>

    <!-- Classification Data -->
    <div class="mt-2 skeleton-box rounded-pill" style="height: 20px; width: 102px"></div>

    <div
      class="w-100 mt-3"
      style="border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
    ></div>

  </div>

  <div
    class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
  >

    <!-- Classification Title -->
    <div class="mt-2">
      <span class="skeleton-box rounded-1" style="width: 90px; height: 14px">
      </span>
    </div>

    <!-- Classification Data -->
    <div
      class="d-flex flex-column justify-content-start mt-4"
      [class.pb-3]="dataLast"
      *ngFor="let sd of [].constructor(6); let dataFirst=first; let dataLast=last"
    >
      <div class="d-flex justify-content-start align-items-start">
        <span class="skeleton-box rounded-1" style="width: 90px; height: 12px"></span>
      </div>
      <div class="d-flex justify-content-start align-items-start mt-1">
        <span class="skeleton-box rounded-1" style="width: 150px; height: 10px"></span>
      </div>
    </div>

    <div
      class="w-100 mt-2"
      style="border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
    ></div>
  </div>


  <div
    class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
  >
    <!-- Classification Title -->
    <div class="mt-2">
      <span class="skeleton-box rounded-1" style="width: 90px; height: 12px">
      </span>
    </div>

    <!-- Classification Data -->
    <div
      class="d-flex justify-content-start pb-1"
    >
      <span class="skeleton-box rounded-1" style="width: 150px; height: 10px">
      </span>
    </div>

  </div>

  <div
    class="d-flex flex-column justify-content-center align-items-start w-100 px-3"
  >

    <!-- Classification Title -->
    <div class="mt-3">
      <span class="skeleton-box rounded-1" style="width: 90px; height: 12px">
      </span>
    </div>

    <!-- Classification Data -->
    <div
      class="d-flex justify-content-start mb-2"
    >
      <span class="skeleton-box rounded-1" style="width: 150px; height: 10px">
      </span>
    </div>

  </div>
</div>

