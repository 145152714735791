import { createAction, props } from "@ngrx/store";
import {DetailTabs, TaskI, TaskPermissionsObjectI, TaskResponseI, TaskResultTab} from "../../interfaces/task.interface";


export const setLoadingTask = createAction(
  "[ Task ] Set Loading Task",
  props<{
    status: boolean
  }>()
);

export const setLoadingTaskPermissions = createAction(
  "[ Task ] Set Loading Task Permissions",
  props<{
    status: boolean
  }>()
);


export const loadTaskList = createAction(
  "[ Task ] Load Task List",
  props<{
    processId: string,
    page: number | string,
    limit: number | string
  }>()
);

export const loadTaskListSuccess = createAction(
  "[ Task ] Load Task List Success",
  props<{
    response: TaskResponseI
  }>()
);

export const loadSelectedTask = createAction(
  "[ Task ] Load Selected Task",
  props<{
    resultId: string,
    taskId: string
  }>()
);

export const loadSelectedTaskSuccess = createAction(
  "[ Task ] Load Selected Task Success",
  props<{
    task: TaskI
  }>()
);

export const patchTaskActivity = createAction(
  "[ Task ] Patch task activity",
  props<{
    resultId: string,
    taskId: string,
    activityId: string,
    data: string
  }>()
);

export const patchTaskActivitySuccess = createAction(
  "[ Task ] Patch task activity success",
  props<{
    task: TaskI,
  }>()
);

export const executeTaskAction = createAction(
  "[ Task ] Execute Task Action",
  props<{
    resultId: string,
    taskId: string,
    action: string
  }>()
);

export const executeTaskActionSuccess = createAction(
  "[ Task ] Execute Task Action Success",
  props<{
    task: TaskI
  }>()
);

export const setDetailTask = createAction(
  "[ Task ] Select Detail Task",
  props<{
    task: TaskI | null
  }>()
);

export const updateTaskPermissions = createAction(
  "[ Task ] Update Task Permissions",
  props<{
    resultId: string,
    taskId: string,
    data: TaskPermissionsObjectI
  }>()
);

export const updateTaskPermissionsSuccess = createAction(
  "[ Task ] Update Task Permissions Success",
  props<{
    task: TaskI
  }>()
);

export const removeTaskPermissions = createAction(
  "[ Task ] Remove Task Permissions",
  props<{
    resultId: string,
    taskId: string,
    data: TaskPermissionsObjectI
  }>()
);

export const setTaskShowFilterBar = createAction(
  "[ Task ] Set Show Filter Bar",
  props<{
    status: boolean
  }>()
);

export const setTaskShowRightBar = createAction(
  "[ Task ] Set Show Right Bar",
  props<{
    status: boolean
  }>()
);

export const setTaskDetailsTab = createAction(
  "[ Task ] Set Result Details Tab",
  props<{
    tab: DetailTabs
  }>()
);

export const setTaskResultTab = createAction(
  "[ Task ] Set Task Result Tab",
  props<{
    tab: TaskResultTab
  }>()
);







