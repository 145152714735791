import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {filter, Observable, switchMap, take} from "rxjs";
import {selectAuditBackendApi, selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuditService {

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  getStatusAudit(data: {object_id: string}): Observable<any> {
    return this.store.select(selectAuditBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendAuditApi: string) => {
        return this.http.get(`${backendAuditApi}/audit/objects/${data.object_id}?action=change_process_status`)
      })
    )
  }
}
