import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent implements OnInit{
  item: any = null;
  message?: string;
  action = new EventEmitter<any>();

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
  }

  accept(){
    this.action.emit(this.item);
    this.bsModalRef.hide();
  }
}
