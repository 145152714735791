<!-- content process horizontal scroll -->
<div class="d-flex overflow-auto h-100 px-3 py-3 gap-3">
  <app-grid-card
    *ngFor="let task of processTasks; let index=index"
    [task] = task
    [process] = selectedProcess
    [index]="index+1"
  ></app-grid-card>
</div>




