import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  catchError,
  debounceTime, distinctUntilChanged,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscriber,
  switchMap,
  takeUntil,
  tap
} from "rxjs";
import {PermissionsObjectI} from "../../../../infrastructure/interfaces/permissions.interface";
import {TypeaheadItemsI, TypeaheadResponse} from "../../../../infrastructure/interfaces/process-result.interface";
import {HttpClient} from "@angular/common/http";
import {ProcessResultService} from "../../../../infrastructure/services/process-result.service";
import {restoreTypeScriptVersionForTesting} from "@angular/compiler-cli/src/typescript_support";
import {Store} from "@ngrx/store";
import {setProcessResultGlobalSearch} from "../../../../infrastructure/store/process-result/process-result.actions";

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GlobalSearchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('search_input', { static: false }) inputSearch! :ElementRef;

  destroy$ = new Subject<void>();

  @Input() processId: string | undefined = "";

  onChange: any = () => {};
  onTouch: any = () => {};

  value: string = "";

  searchForm!: FormGroup

  dataSource!: Observable<TypeaheadItemsI[]>;

  loading: boolean = false;
  noResults: boolean = false;
  searchFocus: boolean = false;

  constructor(
    private cdRef:ChangeDetectorRef,
    private store: Store,
    private fb:FormBuilder,
    private processResultService: ProcessResultService
  ) {
    this.searchForm = this.fb.group({
      search:[""]
    });
  }

  ngOnInit() {
    this.search?.valueChanges.pipe(
      distinctUntilChanged((a,b) => JSON.stringify(a) === JSON.stringify(b)),
      debounceTime(400),
      takeUntil(this.destroy$)
    ).subscribe((search: string) => {
      if (search.length === 0 && search !== this.value){
        this.onChange(search);
        this.onTouch(search);
        this.value = this.search?.value;
      }
    })

    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.search?.value || '');
    })
      .pipe(
        debounceTime(400),
        switchMap((query: string) => {
          if (this.processId && query) {
            return this.processResultService.getTypeaheadOptions({
              processId: this.processId,
              query: query
            }).pipe(
              map((response: TypeaheadResponse) => {
                return response.results || []
              })
            )
          }
          return of([]);
        }),
        catchError((e) => of([]))
      );
  }

  ngOnDestroy() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: string): void {
    this.value = obj;
    this.search?.patchValue(this.value, {onlySelf: true, emitEvent: false});
  }

  typeaheadLoading(event: any) {
    this.loading = event;
    this.noResults = false;
  }

  typeaheadNoResults(event: any){
    this.noResults = event
  }

  keyDown(event: any, eventType: 'key' | 'select'){
    //For enter key press
    if (eventType == 'key' && event?.key === 'Enter'){
      this.cdRef.detectChanges();
      this.inputSearch.nativeElement.blur();

      if (this.search?.value && this.search.value.length >= 3 && this.search?.value !== this.value){
        this.onChange(this.search.value);
        this.onTouch(this.search.value);
        this.value = this.search?.value;
      }

    }

    //For select typeahead option
    if (eventType === 'select'){
      if (this.search?.value){
        if (this.search?.value !== this.value && this.search.value.length >= 3){
          this.onChange(this.search?.value);
          this.onTouch(this.search?.value);
          this.value = this.search?.value;
        }
      }
    }
  }

  get search(){
    return this.searchForm.get("search");
  }
}
