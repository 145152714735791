import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap, tap, throwError} from "rxjs";
import * as mapProcessActions from "./map.action"
import { MapProcessService } from "../../services/map.service";
import { MapProcessI } from "../../interfaces/map-process.interface";

@Injectable()
export class MapProcessEffects {

  loadProcessScrollList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapProcessActions.loadMapProcesslList),
      switchMap(() => {
        return this.mapProcessService.getMapProcessList().pipe(
          // tap(response => console.log('Response from service:', response)),
          map((response: MapProcessI) => {
            return mapProcessActions.loadMapProcessListSuccess({ response: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            return throwError(error);
          })
        );
      })
    )
  );


	constructor(
		private actions$: Actions,
    private mapProcessService: MapProcessService
	) {}
}
