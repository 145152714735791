import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as forge from 'node-forge';
import {filter, map, Observable, of, switchMap, take} from "rxjs";
import {ConfigI} from "../interfaces/config.interface";
import {Store} from "@ngrx/store";
import {selectBackendApi, selectConfig, selectGroupStore} from "../store/config/config.selector";
import {PermissionsObjectI, UserI} from "../interfaces/permissions.interface";
import {AuthService} from "@mangosoft/mango-login";
import { jwtDecode } from "jwt-decode";



interface Config {
  a: string;
  b: string;
  c: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private authService: AuthService
  ) {}

  getAuthUserData(): UserI {
    const token = this.authService.getToken();
    return jwtDecode(token);
  }

  getConfigData(configData: {url: string, random_pass: string}): Observable<ConfigI> {
    let body = {
      key: this.randomNumber(16).toString()
    };
    return this.http.post<Config>(configData.url, body).pipe(
      map((value) => {
        const decripted = this.decrypt(value, configData.random_pass, body.key);
        return JSON.parse(decripted?.data ? decripted?.data : '{}') || {};
      })
    )
  }

  private randomNumber(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
    );
  }

  private decrypt(
    config: { a: string; b: string; c: string },
    random_pass: string,
    valueKey: string
  ) {
    let data_a = config.a;
    var datos = forge.util.decode64(data_a);
    var encrypted_end = this.cypherData(random_pass, valueKey, datos);
    datos = encrypted_end.data
    var myPrivKey = forge.pki.privateKeyFromPem(datos);
    let data_c = config.c;
    var decrypted = myPrivKey.decrypt(forge.util.decode64(data_c));
    let original = decrypted + config.b;
    return this.cypherDataDecode(random_pass, valueKey, original);
  }

  private cypherData(random_pass: string, valueKey: string, update: any) {
    var cipher = forge.cipher.createDecipher("AES-CBC", valueKey);
    cipher.start({ iv: random_pass });
    cipher.update(forge.util.createBuffer(update));
    cipher.finish();
    return cipher.output;
  }

  private cypherDataDecode(random_pass: string, valueKey: string, update: any) {
    var cipher = forge.cipher.createDecipher("AES-CBC", valueKey);
    cipher.start({ iv: random_pass });
    cipher.update(forge.util.createBuffer(forge.util.decode64(update)));
    cipher.finish();
    return cipher.output;
  }

  getGroupUsers(data: {url: string}) {
    return this.store.select(selectGroupStore).pipe(
      filter((val) => val !== null && !!val.pk && !!val.sk),
      take(1),
      switchMap((groupStore:{pk: string, sk: string} | null) => {
        if (groupStore) {
          return this.http.get<{data: any[]}>(`${data.url}/groups/${groupStore.pk}/${groupStore.sk}/details`).pipe(
            map((response) => {
              if (response.data.length) {
                return response.data.reduce((acc: PermissionsObjectI[], value: {group: any, users: any[]}) => {
                  // Extract group
                  acc.push({
                    username: value?.group?.name || "",
                    full_name: value?.group?.name || "",
                    email: "",
                    picture: value?.group?.picture || "",
                    users: (value?.users || []).length,
                    type: "group"
                  });

                  // Extract users
                  (value?.users || []).forEach((user: any) => {
                    if (acc.findIndex((val) => val.username === user?.username) === -1){
                      acc.push({
                        username: user?.username,
                        full_name: user?.fullName,
                        email: user?.email,
                        picture: user?.picture,
                        users: 0,
                        type: "user"
                      })
                    }
                  });

                  return acc
                },[]);
              }
              return [];
            })
          )
        }
        return of([])
      })
    )
  }
}
