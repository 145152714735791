import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-contacts-board',
  templateUrl: './contacts-board.component.html',
  styleUrls: ['./contacts-board.component.scss']
})
export class ContactsBoardComponent implements OnInit {

  @Input() triggerDownloadCSV!: BehaviorSubject<string>;
  @Output() eventTriggerDownloadCSV: EventEmitter<string> = new EventEmitter();
  @Output() emitValidateDownload: EventEmitter<boolean> = new EventEmitter();

  filterForm!: FormGroup;
  viewComponent: string = 'whatsapp';
	showFilterBar: boolean = false;
  showRightBar: boolean = false;
	resultPaginator = {
		count: 0,
		pages: 0,
		next: 0,
		previous: 0,
		current: 0,
	};
	pageInterval: any;
	paginationValue!: string;
  viewComponentType: any;
	selectedProcess: any | null = null;
	detailContactResult: any | null = null;
	viewRightBar = 'info';
	resultList: any[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
			page: '',
		});
  }

  toggleFilterMenu() {
		this.showFilterBar = !this.showFilterBar;
	}

  toggleRightBar() {
		this.showRightBar = !this.showRightBar;
	}

  selectRightBar(tab: string) {
		this.viewRightBar = tab;
	}

  canNavigate(action: 'next' | 'last' | 'first' | 'previous'): boolean {
		switch (action) {
			case 'first':
			case 'previous':
				return this.resultPaginator.current > 1;
			case 'next':
			case 'last':
				return this.resultPaginator.current < this.resultPaginator.pages;
			default:
				return false;
		}
	}

  paginate(action: 'next' | 'last' | 'first' | 'previous') {
		let newPaginator = { ...this.resultPaginator };
		switch (action) {
			case 'first':
				this.paginationValue = 'first';
				newPaginator.pages = 1;
				break;
			case 'previous':
				this.paginationValue = 'previous';
				newPaginator.pages = newPaginator.current - 1;
				break;
			case 'next':
				this.paginationValue = 'next';
				newPaginator.pages = newPaginator.current + 1;
				break;
			case 'last':
				this.paginationValue = 'last';
				newPaginator.pages = newPaginator.pages;
				break;
		}
		this.pageInterval = newPaginator;

		this.resultPaginator = newPaginator;
	}

  eventActionRow(event: any) {
		if (this.detailContactResult && this.detailContactResult.id === event.id) {
			this.toggleRightBar();
		} else {
			this.showRightBar = true;
			this.detailContactResult = event;
		}
	}

  get itemPerPage() {
		return this.filterForm.get('itemPerPage');
	}
  getPageInterval(page: number): { start: number; end: number } {
		const start = (page - 1) * (this.itemPerPage?.value || 10) + 1;
		const end = Math.min(page * (this.itemPerPage?.value || 10), this.resultPaginator.count || 0);
		return { start, end };
	}

  changeViewComponent(type: string) {
		switch (type) {
			case 'table':
				this.viewComponent = 'table';
				break;
			default:
				this.viewComponent = 'table';
				break;
		}
  }

  actionTriggerDownloadCSV(event: any) {
    this.eventTriggerDownloadCSV.emit('off');
  }

  actionValidateDownload(event: any) {
    this.emitValidateDownload.emit(event);
  }
}
