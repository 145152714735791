import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as taskActions from "./task.actions"
import {catchError, concatMap, map, switchMap, tap, throwError} from "rxjs";
import {ProcessService} from "../../services/process.service";
import {ProcessI} from "../../interfaces/process.interface";
import {TaskService} from "../../services/task.service";
import {TaskI, TaskResponseI} from "../../interfaces/task.interface";
import {executeTaskAction, setLoadingTask, setLoadingTaskPermissions} from "./task.actions";

@Injectable()
export class TaskEffects {

  loadTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.loadTaskList),
      switchMap((action) => {
        return this.taskService.getTaskList({
          processId: action.processId,
          page: action.page,
          limit: action.limit
        }).pipe(
          map((response: TaskResponseI) => {
            this.store.dispatch(setLoadingTask({status: false}));
            return taskActions.loadTaskListSuccess({ response: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadSelectedTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.loadSelectedTask),
      switchMap((action) => {
        return this.taskService.getSelectedTask({
          resultId: action.resultId,
          taskId: action.taskId
        }).pipe(
          map((task: TaskI) => {
            this.store.dispatch(setLoadingTask({status: false}));
            return taskActions.loadSelectedTaskSuccess({ task: task });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 404) {
              this.notification.error(error.error?.error || "Not Found", "Process Result not Found")
              this.router.navigate(['/tasks'])
            }
            if (error.status == 403) {
              this.router.navigate(['/login'])
            }
            return throwError(error);
          })
        );
      })
    )
  );


  patchTaskActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.patchTaskActivity),
      switchMap((action) => {
        return this.taskService.patchTaskActivity({
          resultId: action.resultId,
          taskId: action.taskId,
          activityId: action.activityId,
          data: action.data
        }).pipe(
          map((task: TaskI) => {
            return taskActions.patchTaskActivitySuccess({ task: task });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process Result not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  executeTaskAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.executeTaskAction),
      switchMap((action) => {
        return this.taskService.executeTaskAction({
          resultId: action.resultId,
          taskId: action.taskId,
          action: action.action
        }).pipe(
          map((task: TaskI) => {
            return taskActions.executeTaskActionSuccess({ task: task });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingTask({status: false}));
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process Result not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  executeTaskActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.executeTaskActionSuccess),
      tap((action) => {
        this.router.navigate(['/tasks'])
      })
    ), {dispatch: false}
  );

  updateTaskPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.updateTaskPermissions),
      concatMap((action) => {
        return this.taskService.updateTaskPermissions({
          resultId: action.resultId,
          taskId: action.taskId,
          body: action.data
        }).pipe(
          map((task: TaskI) => {
            return taskActions.setLoadingTaskPermissions({status: false});
            // return taskActions.updateTaskPermissionsSuccess({ task: task });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingTaskPermissions({status: false}));
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process Result not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );


  removeTaskPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskActions.removeTaskPermissions),
      concatMap((action) => {
        return this.taskService.removeTaskPermissions({
          resultId: action.resultId,
          taskId: action.taskId,
          body: action.data
        }).pipe(
          map((task: TaskI) => {
            return taskActions.setLoadingTaskPermissions({status: false});
            // return taskActions.updateTaskPermissionsSuccess({ task: task });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingTaskPermissions({status: false}));
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process Result not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private taskService: TaskService
	) {}
}
