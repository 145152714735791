<div
  *ngIf="qrForm && formUrl"
  class="w-100 h-100 d-flex flex-column justify-content-start align-items-center px-3 py-3 overflow-scroll"
>
  <div class="d-flex gap-2 py-2">
    <div
      class="border rounded-2 d-flex justify-content-center align-items-center py-1 pointer
            hover:bg-primary-color hover:border-primary-color hover:text-white"
      style="width: 164px"
      [ngClass]="{
        'bg-primary-color text-white border-primary-color': showMode === 'form',
        'border': showMode === 'qr'
      }"
      (click)="showMode='form'; loadingQr=true"
    >
      <span class="text-normal">Show form</span>
    </div>
    <div
      class="rounded-2 d-flex justify-content-center align-items-center py-1 pointer
            hover:bg-primary-color hover:border-primary-color hover:text-white"
      [ngClass]="{
        'bg-primary-color text-white border-primary-color': showMode === 'qr',
        'border': showMode === 'form'
      }"
      style="width: 164px"
      (click)="showMode='qr'"
    >
      <span class="text-normal">Show QR</span>
    </div>
  </div>


  <div *ngIf="showMode==='qr'"  class="border border-status-color-inactive p-3">
    <div *ngIf="loadingQr" class="skeleton-box" style="height: 300px; width: 300px"></div>
    <img
      [class]="{
      'display': loadingQr ? 'None' : 'block'
      }"
      (load)="qrLoaded()"
      [src]="qrGeneratedUrl"
    >
  </div>
  <div *ngIf="showMode==='qr'" class="d-flex justify-content-center align-items-center col-12 col-sm-4 mt-3">
    <span class="text-center text-normal">{{qrLabel}}</span>
  </div>
  <mng-fill-form
    class="w-100"
    *ngIf="showMode === 'form'"
    (formSave)="onFormSave($event)"
    (formSubmit)="onFormSubmit($event)"
    [inputProperties]="inputProperties"
  ></mng-fill-form>
</div>


<mng-fill-form
  *ngIf="!qrForm"
  (formSave)="onFormSave($event)"
  (formSubmit)="onFormSubmit($event)"
  [inputProperties]="inputProperties"
></mng-fill-form>
