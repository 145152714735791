import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatarText'
})
export class AvatarTextPipe implements PipeTransform {

  transform(value: string): string {

    if (value){
      const text_list = value.split(" ");
      return `${text_list[0][0].toUpperCase()}${ text_list.length > 1 ? text_list[1][0].toUpperCase(): text_list[0][0].toUpperCase() }`
    }
    return "";
  }

}
