import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@mangosoft/mango-layout';
import { Subject, Subscription, catchError, of, takeUntil } from 'rxjs';

@Component({
	selector: 'app-modal-alert',
	templateUrl: './modal-alert.component.html',
	styleUrls: ['./modal-alert.component.scss'],
})
export class ModalAlertComponent implements OnInit, OnDestroy {
  @Output() closeModalAlert: EventEmitter<any> = new EventEmitter();
	@Input() nameModal!: string;
	@Input() value: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();
	subscriptionTranslate!: Subscription;
	content: any;
	showModal: boolean = true;
	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.getTranslate();
	}

  ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getTranslate() {
    this.translateService
    .init('core')
    .pipe(
      takeUntil(this.unsubscribe$),
      catchError((err) => {
        return of({ error: err });
      })
    )
    .subscribe((data) => {
      console.log(data?.toolbar)
			this.content = data?.toolbar[this.nameModal || 'modalAlertDeleteNoSelected'];
    });

	}

	closeModal(value: boolean) {
		this.showModal = !this.showModal;
		this.closeModalAlert.emit({ resp: value, value: this.value });
	}

	onBackgroundClick(event: MouseEvent) {
		if ((event.target as HTMLElement).classList.contains('modal')) {
			this.closeModal(true);
		}
	}
}
