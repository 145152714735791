import {Component, Input} from '@angular/core';
import {TaskI, TaskStatus} from "../../../infrastructure/interfaces/task.interface";
import {ProcessResultI} from "../../../infrastructure/interfaces/process-result.interface";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";

@Component({
  selector: 'app-tasks-status',
  templateUrl: './tasks-status.component.html',
  styleUrls: ['./tasks-status.component.scss']
})
export class TasksStatusComponent {

  status_map: {[key:string]: string} = {'empty': '#DADADA'}

  private _processModel: ProcessI | null = null;
  @Input() set processModel(val) {
    this._processModel = val;

    if (val) {
      this.status_map = val.task_states.states_list.reduce((acc: any, curr) => {
        acc[curr.key] = curr.color
        return acc
      },{'empty': '#DADADA'})
    }
  }
  get processModel() {
    return this._processModel
  }

  @Input() processResult: ProcessResultI | null = null;

  taskStatus = TaskStatus;
  math = Math;

}
