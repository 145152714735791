<!-- Movil View -->
<div
  class="d-sm-none overflow-hidden w-100 d-flex gap-1  px-3"
  style="height: 20px;"
>
  <div
    class="d-flex flex-nowrap gap-3 overflow-auto text-nowrap horizontal-menu"
  >
    <div
      class="position-relative d-flex align-items-end"
      *ngFor="let setting of filteredSettings"
      (click)="selectSetting(setting)"
    >
      <span class="text-sub-title text-complementary-color-a" style="line-height: 24px">
        {{setting.label}}
      </span>
      <div
        *ngIf="setting.id === selectedSetting?.id"
        class="position-absolute bottom-0 start-0 w-100 border-bottom border-complementary-color-a"
      ></div>
    </div>
  </div>

</div>


<!-- Desktop View -->
<div
  class="h-100 flex-shrink-0 border-end d-none d-sm-flex flex-column overflow-y-hidden"
  style="width: 250px;"
>
  <!--Search-->
  <div
    class="w-100 flex-shrink-0 px-3 d-flex justify-content-center align-items-center"
    style="height: 50px"
  >
    <div class="position-relative w-100" [formGroup]="form">
      <input
        class="w-100 border rounded-1 text-normal"
        type="input"
        style="padding-left: 30px; height: 30px; outline: none"
        formControlName="search"
      >
      <div
        class="d-flex justify-content-center align-items-center position-absolute h-100 top-0 start-0"
        style="width: 30px"
      >
        <span class="material-symbols-outlined text-title text-neutral-color-e">
          search
        </span>
      </div>
    </div>

  </div>

  <!-- Cards -->
  <div
    class="flex-grow-1 overflow-y-auto d-flex flex-column gap-2 px-3 pb-2"
  >
    <div
      class="position-relative flex-shrink-0 w-100 border rounded-1 d-flex flex-column hover:bg-neutral-color-f pointer"
      style="height: 100px"
      *ngFor="let setting of filteredSettings"
      (click)="selectSetting(setting)"
    >
      <div
        class="w-100 flex-shrink-0 d-flex justify-content-center align-items-center pt-2"
        style="height: 70%"
      >
        <img *ngIf="setting.image && !setting.icon" src="{{setting.image}}" height="30px">
        <span
          *ngIf="setting.icon && !setting.image"
          class="material-symbols-outlined"
          style="font-size: 3.5rem"
        >
          {{setting.icon}}
        </span>
      </div>
      <div
        class="flex-shrink-0 d-flex justify-content-center align-items-start"
        style="height: 30%"
      >
        <span
          class="text-sub-title text-complementary-color-a lh-1"
          [ngClass]="{
          'text-neutral-color-g': setting.id !== selectedSetting?.id,
          'text-complementary-color-a': setting.id === selectedSetting?.id
          }"
        >
          {{setting.label}}
        </span>
      </div>
      <div
        class="position-absolute top-0 end-0 h-100 bg-complementary-color-a"
        *ngIf="setting.id === selectedSetting?.id"
        style="width: 2px; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem"
      ></div>
    </div>

  </div>


</div>
