import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {
  loadProcessList,
  loadSelectedProcessSuccess,
  setLoadingProcess
} from "../../../infrastructure/store/process/process.actions";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {selectLoadingProcess, selectProcessList} from "../../../infrastructure/store/process/process.selector";
import {Router} from "@angular/router";

@Component({
  selector: 'app-process-model-grid',
  templateUrl: './process-model-grid.component.html',
  styleUrls: ['./process-model-grid.component.scss']
})
export class ProcessModelGridComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>()

  loadingProcess: boolean = false;

  processList: ProcessI[] = [];

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store.dispatch(setLoadingProcess({status: true}));
    this.store.dispatch(loadProcessList())
  }

  ngOnInit() {
    this.store.select(selectProcessList).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.processList = value;
    })

    this.store.select(selectLoadingProcess).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingProcess = value;
    })
  }

  selectProcess(process: ProcessI){
    this.store.dispatch(loadSelectedProcessSuccess({process: process}));
    this.router.navigate(['/process', process.id, 'process-results'])
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
