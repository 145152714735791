<div
  class="d-flex h-100 flex-column rounded-2 overflow-y-hidden"
  style="min-width: 280px; max-width: 280px; padding: 5px;"
  [ngClass]="{'h-100': total > this.elements}"
  [ngStyle]="{
  'background-color': state.color + '1A'
  }"
>

  <!-- Header of column -->
  <div
    dndDropzone
    (dndDrop)="onDrop($event)"
    [dndDisableDropIf]="total > 0"
  >
    <div
      class="w-100 d-flex justify-content-start pb-1"

    >
      <div
        class="rounded-2 px-2 d-flex align-items-center"
        [ngStyle]="{
      'padding-top': '1px',
      'padding-bottom': '2px',
      'background-color': state.color
      }"
      >
        <div
          class="d-flex justify-content-center align-items-center border-2 rounded-circle me-2"
          style="width: 14px; height: 14px;"
          [ngStyle]="{
          'border-color': state.fontColor
        }"
        >
          <div
            class="rounded-circle"
            style="width: 6px; height: 6px"
            [ngStyle]="{
            'background-color': state.fontColor,
          }"
          >
          </div>
        </div>
        <span
          class="text-normal fw-bold"
          [ngStyle]="{
      'color': state.fontColor
      }"
        >
      {{state.label}}
    </span>
      </div>

      <div class="flex-grow-1 d-flex justify-content-end align-items-center">
        <div
          class="px-2 rounded-2 d-flex align-items-center justify-content-center hover:bg-neutral-color-e"

        >
          <span class="text-mini user-select-none">{{total}}</span>
        </div>

      </div>

    </div>

    <div
      dndPlaceholderRef
      class="d-flex border bg-white rounded-2 kanban-card p-2 mb-1"
      [ngStyle]="{
      'width': '260px',
      'height': itemSize + 'px',
    }"
    >

    </div>
  </div>


  <!-- Body of column -->
  <div
    class="flex-grow-1 d-flex flex-column"
    *ngIf="total > elements"
  >
      <cdk-virtual-scroll-viewport
        class="h-100 flex-grow-1"
        [itemSize]="itemSize"
        [minBufferPx]="itemSize"
        [maxBufferPx]="itemSize"
        (scrolledIndexChange)="onScrolledIndexChange($event)"
      >
        <div *cdkVirtualFor="let item of processResultList$ | async; trackBy: trackByIdx; let index=index; let last=last">
          <app-kanban-status-card
            [dndDraggable]="item"
            dndEffectAllowed="move"
            (dndEnd)="onDraggedEnd(item)"
            [index]="index"
            [total]="total"
            [selectedProcess]="process"
            [processResult]="item"
            [height]="itemSize"
            [last]="last"
          ></app-kanban-status-card>
        </div>
      </cdk-virtual-scroll-viewport>
  </div>

  <!-- Body of column -->
  <div
    class="flex-grow-1 d-flex flex-column"
    *ngIf="total <= this.elements"
  >
        <app-kanban-status-card
          *ngFor="let item of processResultList$ | async; let last=last; let index=index"
          [dndDraggable]="item"
          dndEffectAllowed="move"
          [index]="index"
          [total]="total"
          [selectedProcess]="process"
          [processResult]="item"
          [height]="itemSize"
          [last]="last"
        ></app-kanban-status-card>
  </div>
</div>
