
//IDENTIFICADOR DEL REDUCER
import { ProcessScrollI } from "../../interfaces/process-scroll.interface";

// Identificador del Reducer
export const processScrollFeatureKey = 'processScroll';

export interface StateProcessScroll {
  selectedProcessScroll: ProcessScrollI;
}

// Estado inicial
export const initialState: StateProcessScroll = {
  selectedProcessScroll: {
    name: "",
    icon: "",
    value: 0,
    id: ""
  },
};
