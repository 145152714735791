import { createReducer, on } from '@ngrx/store';
import { initialState } from "./state";
import * as mapProcessActions from "./map.action";

export const mapProcessReducer = createReducer(
  initialState,
  on(mapProcessActions.loadMapProcessListSuccess, (state, action) => {
    // console.log('Estado anterior:', state);
    // console.log('Datos recibidos:', action.response);

    const newState = {
      ...state,
      selectedMapProcess: action.response
    };

    // console.log('Nuevo estado:', newState);

    return newState;
  })
);
