import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TaskStateI} from "../../../../infrastructure/interfaces/process.interface";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusFilterComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusFilterComponent implements ControlValueAccessor, OnInit, OnDestroy{

  destroy$ = new Subject<void>();

  @Input() state_list: TaskStateI[] = []
  @Input() selectAll!: Subject<boolean>;
  @Input() clearAll!: Subject<boolean>;

  onChange: any = () => {};
  onTouch: any = () => {};

  value: string[] = [];

  formStatusFilter!: FormGroup

  constructor(
    private fb:FormBuilder
  ) {
    this.formStatusFilter = this.fb.group({
      process_status: new FormArray([])
    })


  }

  ngOnInit() {
    this.state_list.forEach((val) => {
      this.process_status.push(
        new FormControl()
      )
    })

    this.process_status.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: boolean[]) => {
      this.value = value
        .map((checked: boolean, i: number) => checked ? this.state_list[i].key: null)
        .filter((v: string|null) => v!==null) as string[];
      this.onChange(this.value);
      this.onTouch(this.value);
    })

    this.selectAll.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.process_status.controls.forEach((value) => value.patchValue(true))
    })

    this.clearAll.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.process_status.controls.forEach((value) => value.patchValue(false))
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(obj: string[]): void {
    this.state_list.forEach((state, i) => {
      this.process_status.controls[i].patchValue(obj.includes(state.key));
    })
  }

  get process_status(){
    return this.formStatusFilter.get("process_status") as FormArray
  }
}
