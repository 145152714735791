import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as permissionsActions from "./permissions.actions";
import {catchError, map, switchMap, throwError} from "rxjs";
import {AccessControlService} from "../../services/access-control.service";
import {ConfigI} from "../../interfaces/config.interface";

@Injectable()
export class PermissionsEffects {

  loadPermissionsObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionsActions.loadPermissionsObjects),
      switchMap((action) => {
        // Load data from access control
        // todo: Modify for mango core service when ready
        return this.accessControlService.getGroupUsers({
          url: action.url,
        }).pipe(
          map((response) => {
            return permissionsActions.loadPermissionsObjectsSuccess({ objects: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private accessControlService: AccessControlService
	) {}
}
