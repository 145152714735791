import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {combineLatest, filter, Subject, takeUntil, withLatestFrom} from "rxjs";
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {selectProcessResultFilter} from "../../../infrastructure/store/process-result/process-result.selector";
import {ProcessResultFilterI} from "../../../infrastructure/interfaces/process-result.interface";

@Component({
  selector: 'app-kanban-status',
  templateUrl: './kanban-status.component.html',
  styleUrls: ['./kanban-status.component.scss']
})
export class KanbanStatusComponent implements OnInit, OnDestroy{

  destroy$ = new Subject<void>()

  process: ProcessI | null = null;

  processResultFilter!: ProcessResultFilterI

  process_states: TaskStateI[] = [];

  constructor(
    private store: Store
  ) {}


  ngOnInit() {
    //Listen for process changes
    combineLatest([
      this.store.select(selectSelectedProcess),
      this.store.select(selectProcessResultFilter)
    ])
    .pipe(
      takeUntil(this.destroy$),
      filter(([process, processResultFilter]) => process !== null)
    ).subscribe(([process, processResultFilter]) => {
      this.process = process;
      this.processResultFilter = processResultFilter;

      this.process_states = (this.process?.process_states?.states_list || [])
        .filter((val) => {
          return processResultFilter.process_status.includes(val.key)
        })

      if (this.process_states.length === 0){
        this.process_states = (this.process?.process_states?.states_list || []).filter((v)=>v.selected)
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
