import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {delay, filter, Observable, of, switchMap, take} from "rxjs";
import {selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";
import {UserEmailSettingsI} from "../interfaces/settings.interface";

@Injectable({
  providedIn: "root",
})
export class SettingsService {

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  getEmailProvider(): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/email-providers`)
      })
    )
  }

  getUserSettings(data:{
    username: string
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/user-settings`)
        // return of({
        //   email:[{
        //       smtp_server: "smtp.google.com",
        //       smtp_port: "25",
        //       imap_server: "imap.google.com",
        //       imap_port: "110",
        //       email: "ernesto.licea@mango-soft.com",
        //       from: "Ernesto Licea Martin",
        //       provider: {
        //         model: "email-provider",
        //         id: 'google',
        //         label: 'Google',
        //         icon_url: 'assets/img/Gmail.png',
        //         smtp_server: "smtp.google.com",
        //         smtp_port: "25",
        //         imap_server: "imap.google.com",
        //         imap_port: "110",
        //       }
        //   },{
        //     smtp_server: "smtp.outlook.com",
        //     smtp_port: "25",
        //     imap_server: "imap.outlook.com",
        //     imap_port: "110",
        //     email: "ernesto.licea@hotmail.com",
        //     from: "Ernesto Licea Martin",
        //     provider: {
        //       model: "email-provider",
        //       id: 'outlook',
        //       label: 'Outlook',
        //       icon_url: 'assets/img/Outlook.png',
        //       smtp_server: "smtp.outlook.com",
        //       smtp_port: "25",
        //       imap_server: "imap.outlook.com",
        //       imap_port: "110",
        //     }
        //   }]
        // }).pipe(delay(500))
      })
    )
  }

  addEmailProviderSetting(data:{
    emailProvider: UserEmailSettingsI
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.post(`${backendApiUrl}/user-settings`, {
          setting_type: 'email',
          data: data.emailProvider
        })
        // return of({
        //   position: data.position,
        //   data: data.emailProvider
        // }).pipe(
        //   delay(500)
        // )
      })
    )
  }

  updateEmailProviderSetting(data:{
    emailProvider: UserEmailSettingsI
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.put(`${backendApiUrl}/user-settings`, {
          setting_type: 'email',
          data: data.emailProvider
        })
        // return of({
        //   position: data.position,
        //   data: data.emailProvider
        // })
      })
    )
  }

  removeEmailProviderSetting(data:{
    emailProvider: UserEmailSettingsI
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.request('delete',`${backendApiUrl}/user-settings`, {
          body: {
            setting_type: 'email',
            data: data.emailProvider
          }
        })
        // return of({
        //   position: data.position,
        //   data: data.emailProvider
        // }).pipe(delay(500))
      })
    )
  }

  validateEmailProviderSetting(data:{
    code: string,
    state: string
  }): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.post(`${backendApiUrl}/validate-email-settings`, {
          state: data.state,
          code: data.code
        })
        // return of({
        //   position: data.position,
        //   data: data.emailProvider
        // })
      })
    )
  }
}
