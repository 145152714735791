<div
  *ngIf="phiUrl && patientId"
  style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 30px;"
>
  <div>
    <img src="https://cdn.mango-soft.com/assets/ips-primero/image+170.svg">
  </div>
  <div
    style="width: 40%; display: flex; justify-content: center; align-items: center; margin-bottom: 16px;"
  >
    <span
      style="font-size: 12px; text-align: center;"
    >
      El paciente ya esta preparado para su atenci&oacute;n medica, presiona el siguiente bot&oacute;n para iniciar reporte en el
      <a
        style="text-decoration: none; color: black;" href="https://ehr.ipsprimero-apps.com"
        target="_blank"
        rel="noopener">
        <strong>Mango PHI</strong></a>
    </span>
  </div>
  <div
    style="display: flex; justify-content: center; align-items: center;">
    <a
      style="text-decoration: none; height: 28px; width: 110px; display: flex; align-items: center; justify-content: center; font-size: 12px; color: white; background-color: #87aacb; border-radius: 5px;"
      href="{{phiUrl}}/patients/{{patientId}}" target="_blank" rel="noopener">
      <img
        src="https://cdn.mango-soft.com/assets/ips-primero/diagnosis.svg"
      >
      Iniciar Reporte
    </a>
  </div>
</div>
