import {createReducer, on} from '@ngrx/store';
import {initialState} from "./state";
import * as configActions from "./config.actions"
import {ViewComponentType} from "../../interfaces/process-result.interface";

export const configReducer = createReducer(
	initialState,
  on(configActions.setLoadingConfig, (state, action) => {
    return {
      ...state,
      loadingConfig: action.status
    }
  }),
  on(configActions.loadConfigSuccess, (state, action) => {
    return {
      ...state,
      formApi: action.config?.PM_FORM_API,
      api: action.config.PM_API,
      audit_api: action.config.PM_AUDIT_API,
      initialProcess: action.config.PM_INITIAL_PROCESS,
      allowedFiles: action.config?.PM_ALLOWED_FILES || [],
      groupStore: action.config?.PM_GROUP_STORE || null,
      fileImporterApi: action.config?.FILE_IMPORTER_BAKEND_URL || "",
      fileImporterWss: action.config?.FILE_IMPORTER_WSS_SERVER || "",
      fileImporterChannel: action.config?.FILE_IMPORTER_FILE_CHANNEL || "",
      rootUsers: action.config?.PM_ROOT_USERS || [],
      websocketServer: action.config?.PM_WEBSOCKET_SERVER || "",
      websocketChannel: action.config?.PM_WEBSOCKET_CHANNEL || "",
      map: action.config?.PM_MAP || initialState.map,
      calendarBackendUrl: action.config?.PM_CALENDAR_BACKEND_URL || '',
      mapElementsLimit: action.config?.PM_MAP_ELEMENTS_LIMIT || state.mapElementsLimit,
      default_view: action.config?.PM_UI?.default_view || ViewComponentType.table,
      active_views: action.config?.PM_UI?.active_views || [ViewComponentType.table]
    }
  }),

);
