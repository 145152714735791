import { createReducer, on } from '@ngrx/store';
import {initialState} from "./state";
import * as permissionsActions from "./permissions.actions"

export const permissionsReducer = createReducer(
	initialState,
  on(permissionsActions.loadPermissionsObjectsSuccess, (state, action) => {
    return {
      ...state,
      objects: action.objects
    }
  })

);
