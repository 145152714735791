<div class="position-relative h-100 w-100">
  <div class="w-100 h-100" #map id="map"></div>

  <div class="custom-zoom-controls">
    <button (click)="mapZoom('in')" style="border-radius: 8px 0px 0px 8px;">
      <img src="../../../../assets/img/map/zoom_in.svg">
    </button>
    <button (click)="mapZoom('out')" style="border-radius: 0px 8px 8px 0px;">
      <img src="../../../../assets/img/map/zoom_out.svg">
    </button>
  </div>
</div>


<!--<div *ngIf="placeDetails" class="place-details">-->
<!--  <h2>{{ placeDetails.name }}</h2>-->
<!--  <p>{{ placeDetails.formatted_address }}</p>-->
<!--  <p>{{ placeDetails.formatted_phone_number }}</p>-->
<!--  <p *ngIf="placeDetails.website"><a [href]="placeDetails.website" target="_blank">{{ placeDetails.website }}</a></p>-->
<!--</div>-->

