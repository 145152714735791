<div class="splash">
  <div class="d-flex justify-content-center">
    <div class="loader"></div>
  </div>
  <div class="d-flex justify-content-center">
    <span class="text-sub-title">Loading...</span>
  </div>

</div>

