import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {filter, Observable, switchMap, take} from "rxjs";
import {selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";
import {itemsPerPageList} from "../store/process-result/state";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {

  constructor(
  ) {}

  getItemPerPage(){
    return localStorage.getItem("itemPerPage") || itemsPerPageList[0].value
  }

  setItemPerPage(value: number | string) {
    localStorage.setItem("itemPerPage", `${value}`)
  }

  setProcessStatusFilter(value: string){
    localStorage.setItem('processStatusFilter', `${value}`)
  }
  getProcessStatusFilter(){
    return localStorage.getItem('processStatusFilter') || ''
  }
}
