<div
  class="w-100 pt-1"
  *ngIf="state_list.length > 0"
  [formGroup]="formStatusFilter"
>
  <div
    class="d-flex justify-content-start align-items-center pb-1 w-100"
    formArrayName="process_status"
    *ngFor="let status of process_status.controls, let index=index"
  >
    <div
      class="d-flex justify-content-start align-items-center px-2 rounded-2  w-100"
      [ngStyle]="{
              'border': '1px solid',
              'padding-top': '2px',
              'padding-bottom': '2px',
              'border-color': state_list[index].color,
              'background-color': state_list[index].color + 'BB',
              'color': state_list[index].fontColor
            }"
    >
      <input class="pointer" type="checkbox" id="state-{{index}}" [formControlName]="index">
      <span class="text-normal ms-2 fw-bold" >
             {{state_list[index].label}}
            </span>
    </div>

  </div>

</div>
