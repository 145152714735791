import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TaskI} from "../../../infrastructure/interfaces/task.interface";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {Subject, takeUntil} from "rxjs";
import {ProcessService} from "../../../infrastructure/services/process.service";
import {taskReducer} from "../../../infrastructure/store/task/task.reducer";

@Component({
  selector: 'app-grid-card-element',
  templateUrl: './grid-card-element.component.html',
  styleUrls: ['./grid-card-element.component.scss']
})
export class GridCardElementComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>()

  @Input() process: ProcessI | null = null;
  @Input() child: TaskI | null = null;
  @Output() showElements: EventEmitter<TaskI> = new EventEmitter<TaskI>()

  total: number = 0;
  loading: boolean = false;

  constructor(
    private processService: ProcessService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    if (this.child && this.process) {
      this.processService.getProcessStatsByTask({
        processId: this.process.id,
        taskId: this.child.id
      }).pipe(
        takeUntil(this.destroy$)
      ).subscribe((value ) => {
        this.loading = false;
        if(this.child){
          this.total = value.total
        }

      })
    }

  }

  show_elements(task: TaskI | null){
    if(task){
      this.showElements.emit(task)
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
