<app-spinner *ngIf="isLoading"></app-spinner>

<table class="table table-responsive" *ngIf="content?.table">
  <!-- Header -->
  <thead>
    <tr class="table-row-header">
      <th class="align-middle px-3 d-none" scope="col" style="width: 20px">
        <span>
          <input class="form-check-input pointer p-1" type="checkbox" value="" (change)="toggleSelectAll($event)"/>
        </span>
      </th>
      <th *ngFor="let header of content.table" scope="col" class="align-middle">
        <span class="text-sub-title fw-bold text-complementary-color-a">{{ header.label }}</span>
      </th>
    </tr>
  </thead>

  <!-- Body -->
  <tbody *ngIf="!isLoading">
    <!-- Result Row -->
    <ng-container *ngFor="let result of contacts; let index = index; let first = first; let last = last">
      <!-- Row Data -->
      <tr
        class="align-middle table-row row-option"
        [ngClass]="{ 'select-row': contactsId == result.id }"
      >
        <td class="align-middle px-3 d-none" [ngStyle]="{ background: index % 2 !== 0 ? '' : '#F8F8F8' }">
          <span>
            <input class="form-check-input pointer" type="checkbox" [value]="result.id === '1'" [checked]="selectedContactIds.has(result.id)" (change)="toggleSelection(result.id, $event)" />
          </span>
        </td>

        <!-- Dynamic Columns -->
        <td (click)="actionRow(result)" *ngFor="let header of content.table" class="align-middle" [ngStyle]="{ background: index % 2 !== 0 ? '' : '#F8F8F8' }">
          <label
            class="status-bg"
            [ngStyle]="{
              'text-align': header.value === 'status' ? 'center' : 'start',
              display: header.value === 'language' ? 'flex' : 'block'
            }"
          >
          <ng-container *ngIf="header.value !== 'createdOn'; else date">
            <span class="text-normal">
              {{ getResultValue(header.value, result) | titlecase }}
            </span>
          </ng-container>
          <ng-template #date>
            <span class="text-normal">
              {{ getResultValue(header.value, result) | date: 'MMM d, y, h:mm:ss a' }}
            </span>
          </ng-template>
          </label>
        </td>
        <!-- Icons -->
        <td class="icons-container"	[ngStyle]="{ background: index % 2 !== 0 ? 'null' : '#F8F8F8' }">
          <span class="material-symbols-outlined table-icon" tooltip="Send" (click)="sendRow(result)">
            send
          </span>
          <span class="material-symbols-outlined table-icon" tooltip="Edit" (click)="editRow(result)">
            draw
          </span>
          <span class="material-symbols-outlined table-icon" tooltip="Delete" (click)="deleteRow(result)">
            delete
          </span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!-- Modal delete -->
<app-modal-alert
	*ngIf="viewModal"
	[value]="'delete'"
	[nameModal]="'modalAlertDeleteElement'"
	(closeModalAlert)="closeModalAlert($event)"
></app-modal-alert>
