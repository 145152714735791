import { createReducer, on } from '@ngrx/store';
import {initialState} from "./state";
import * as taskActions from "./task.actions"
import * as processResultActions from "../process-result/process-result.actions";

export const taskReducer = createReducer(
	initialState,

  on(taskActions.setLoadingTask, (state, action) => {
    return {
      ...state,
      loadingTask: action.status
    }
  }),

  on(taskActions.setLoadingTaskPermissions, (state, action) => {
    return {
      ...state,
      loadingTaskPermissions: action.status
    }
  }),

  on(taskActions.loadTaskListSuccess, (state, action) => {
    return {
      ...state,
      taskList: action.response.results,
      paginator: {
        count: action.response.count,
        page: action.response.page,
        next: action.response.next,
        previous: action.response.previous
      }
    }
  }),

  on(taskActions.loadSelectedTaskSuccess, (state, action) => {
    return {
      ...state,
      selectedTask: action.task
    }
  }),

  on(taskActions.setTaskShowFilterBar, (state, action) => {
    return {
      ...state,
      showFilterBar: action.status
    }
  }),

  on(taskActions.setTaskShowRightBar, (state, action) => {
    return {
      ...state,
      showRightBar: action.status
    }
  }),

  on(taskActions.setTaskDetailsTab, (state, action) => {
    return {
      ...state,
      detailTab: action.tab
    }
  }),

  on(taskActions.patchTaskActivitySuccess, (state, action) => {
    return {
      ...state,
      selectedTask: action.task
    }
  }),

  on(taskActions.executeTaskActionSuccess, (state, action) => {
    return {
      ...state,
      selectedTask: action.task
    }
  }),

  on(taskActions.setDetailTask, (state, action) => {
    return {
      ...state,
      detailsTask: action.task
    }
  }),

  on(taskActions.setTaskResultTab, (state, action) => {
    return {
      ...state,
      taskResultTab: action.tab
    }
  }),

  on(taskActions.updateTaskPermissionsSuccess, (state, action) => {
    // Update details task if exist
    let detailsTask = state.detailsTask ? {...state.detailsTask}: null;
    if (detailsTask && detailsTask.resultId === action.task.resultId && detailsTask.id === action.task.id){
      detailsTask = {
        ...detailsTask,
        ...action.task
      }
    }

    // Search task in task list and update
    let taskList = [...state.taskList]
    const index = taskList.findIndex((val) => val.resultId === action.task.resultId && val.id === action.task.id)
    if (index !== -1){
      taskList[index] = {
        ...taskList[index],
        ...action.task
      }
    }

    return {
      ...state,
      taskList: taskList,
      detailsTask: detailsTask
    }
  }),


);
