import { createAction, props } from "@ngrx/store";
import { MapProcessI } from "../../interfaces/map-process.interface";

export const loadMapProcesslList = createAction(
  "[Map Process List] Load Map Process List"
);

export const loadMapProcessListSuccess = createAction(
  "[Map Process List] Load Map Process List Success",
  props<{
    response: MapProcessI
  }>()
);

