import { createFeatureSelector, createSelector } from '@ngrx/store';
import {configFeatureKey, StateConfig} from "./state";
import {ConfigI, FileImporterDataI} from "../../interfaces/config.interface";
import {MapPropertiesI} from "../../interfaces/map-process.interface";
import {ViewComponentType} from "../../interfaces/process-result.interface";

export const configState = createFeatureSelector<StateConfig>(configFeatureKey);

export const selectConfig = createSelector(
  configState,
    (state: StateConfig): ConfigI => {
    return {
      PM_FORM_API: state.formApi,
      PM_INITIAL_PROCESS: state.initialProcess,
      PM_API: state.api,
      PM_AUDIT_API: state.audit_api,
      PM_ALLOWED_FILES: state.allowedFiles,
      PM_GROUP_STORE: state.groupStore,
      FILE_IMPORTER_BAKEND_URL: state.fileImporterApi,
      FILE_IMPORTER_WSS_SERVER: state.fileImporterWss,
      FILE_IMPORTER_FILE_CHANNEL: state.fileImporterChannel,
      PM_ROOT_USERS: state.rootUsers,
      PM_MAP: {
        markerClusterer: {
          baseSize: 30,
          baseTextSize: 10,
          color: '',
          fontColor: ''
        }
      },
      PM_WEBSOCKET_SERVER: state.websocketServer,
      PM_WEBSOCKET_CHANNEL: state.websocketChannel,
      PM_CALENDAR_BACKEND_URL: state.calendarBackendUrl,
      PM_MAP_ELEMENTS_LIMIT: state.mapElementsLimit,
      PM_UI: {
        default_view: state.default_view,
        active_views: state.active_views
      }
    }
  }
)


export const selectMapProperties = createSelector(
  configState,
  (state: StateConfig): MapPropertiesI => {
    return state.map
  }
)

export const selectLoadingConfig = createSelector(
  configState,
  (state: StateConfig): boolean => {
    return state.loadingConfig
  }
)

export const selectFormApi = createSelector(
  configState,
  (state: StateConfig): string => {
    return state.formApi
  }
)

export const selectBackendApi = createSelector(
  configState,
  (state: StateConfig): string => {
    return state.api
  }
)

export const selectAuditBackendApi = createSelector(
  configState,
  (state: StateConfig): string => {
    return state.audit_api
  }
)

export const selectAllowedFiles = createSelector(
  configState,
  (state: StateConfig): string[] => {
    return state.allowedFiles
  }
)

export const selectInitialProcess = createSelector(
  configState,
  (state: StateConfig): string => {
    return state.initialProcess
  }
)

export const selectRootUsers = createSelector(
  configState,
  (state: StateConfig): string[] => {
    return state.rootUsers
  }
)

export const selectGroupStore = createSelector(
  configState,
  (state: StateConfig): { pk: string; sk: string; } | null => {
    return state.groupStore
  }
)

export const selectCalendarUrlBackend = createSelector(
  configState,
  (state: StateConfig): string => {
    return state.calendarBackendUrl
  }
)

export const selectFileImporterData = createSelector(
  configState,
  (state: StateConfig): FileImporterDataI => {
    return {
      fileImporterApi: state.fileImporterApi,
      fileImporterWss: state.fileImporterWss,
      fileImporterChannel: state.fileImporterChannel
    }
  }
)

export const selectWebsocketUrl = createSelector(
  configState,
  (state: StateConfig): {server: string, channel:string } => {
    return {
      server: state.websocketServer,
      channel: state.websocketChannel
    }
  }
)

export const selectMapElementsLimit = createSelector(
  configState,
  (state: StateConfig): number => {
    return state.mapElementsLimit
  }
)

export const selectActiveViews = createSelector(
  configState,
  (state: StateConfig): ViewComponentType[] => {
    return state.active_views
  }
)

