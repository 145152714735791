import { createAction, props } from "@ngrx/store";
import {ProcessI, ProcessResponseI} from "../../interfaces/process.interface";

export const loadProcessList = createAction(
  "[Process List] Load Process List"
);

export const loadProcessListSuccess = createAction(
  "[Process List] Load Process List Success",
  props<{
    response: ProcessResponseI
  }>()
);

export const loadSelectedProcess = createAction(
	"[Process List] Load Selected Process",
	props<{
    processId: string
  }>()
);

export const loadSelectedProcessSuccess = createAction(
  "[Process List] Load Selected Process Success",
  props<{
    process: ProcessI
  }>()
);

export const setLoadingProcess = createAction(
  "[Process List] Set Loading Process",
  props<{
    status: boolean
  }>()
);
