<div class="accordion" id="accordionPanels{{process.label}}" *ngFor="let process of processChildren">
  <div class="accordion-item" dndDropzone (dndDrop)="onDrop($event, process)">
    <span class="accordion-header" [ngClass]="{'noCard': !hasCards(process.id, process.parentId)}">
      <button class="accordion-button text-sub-title text-dark" type="button" data-bs-toggle="collapse"
        [attr.data-bs-target]="'#panelsStayOpen-collapseOne' + process.id" aria-expanded="true"
        aria-controls="panelsStayOpen-collapseOne' + process.id"
        [disabled]="!hasCards(process.id, process.parentId)">
        {{process.label}}
      </button>
    </span>
    <div [attr.id]="'panelsStayOpen-collapseOne' + process.id" class="accordion-collapse collapse show"
         *ngIf="hasCards(process.id, process.parentId)">
      <div class="accordion-body">
        <!-- card -->
        <ng-container *ngFor="let item of processResult; let i = index">
          <div
            dndDraggable
            [dndDraggable]="{ item: item, processId: process.id }"
            class="p-2 bg-white rounded-1 border-bottom border-end border-start overflow-hidden pr-grab"
            *ngIf="process.id === item.viewCardColumn && process.parentId === item.viewColumn"
            [ngStyle]="{ 'border-top': applyStatus(item.tasks, process.id) }"
            [ngClass]="{'mb-2': !i, 'mb-0': i}">
            <div class="row">
              <div class="col-md-8">
                <div class="text-center">
                  <div class="d-flex" *ngFor="let text of cardText">
                    <span class="text-sub-title">
                      <img [src]="text.icon">
                      {{ item.data[text.key] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex justify-content-end">
                  <div class="position-relative" style="height: 100%;">
                    <svg viewBox="0 0 36 36" class="w-100 h-100">
                      <path class="circle-bg" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#eee" stroke-width="2.0" fill="none" />
                      <path class="circle" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#6AA84F" stroke-width="2.0" fill="none"
                        stroke-linecap="round"
                        [ngStyle]="{ 'stroke-dasharray': '100', 'stroke-dashoffset': 100 - item.percentComplete }"
                        class="circle-animation" />
                      <text x="18" y="20.35" class="percentage text-center" fill="#2D2D2D" font-size="0.5em"
                        text-anchor="middle">{{item.percentComplete | round}}%</text>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
