import { createReducer, on } from '@ngrx/store';
import { initialState } from "./state";
import * as processScrollActions from "./process-scroll.action";

export const processScrollReducer = createReducer(
  initialState,
  on(processScrollActions.loadProcessListScrollSuccess, (state, action) => {
    // console.log('Estado anterior:', state);
    // console.log('Datos recibidos:', action.response);

    const newState = {
      ...state,
      selectedProcessScroll: action.response
    };

    // console.log('Nuevo estado:', newState);

    return newState;
  })
);
