import { Component, Input, OnInit } from '@angular/core';
import { TaskI } from '../../../infrastructure/interfaces/task.interface';
import { ProcessResultI } from '../../../infrastructure/interfaces/process-result.interface';
import { ProcessResultService } from '../../../infrastructure/services/process-result.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit{

  @Input() processChildren: TaskI[] = [];
  @Input() processResult: ProcessResultI []= [];

  // esto es para test, aqui estan las propiedades para buscar en los process y mostrar en los items de las cards
  cardText = [
    {
      "key": "last_name",
      "icon": "../../../../assets/img/test/ecg_heart.svg"
    },
    {
      "key": "first_name",
      "icon": "../../../../assets/img/test/clinical_notes.svg"
    },
    {
      "key": "specialty",
      "icon": "../../../../assets/img/test/vaccines.svg"
    }
  ]
  processTasks: TaskI[] = [];

  constructor(
    private processResultService: ProcessResultService
  ) {}

  ngOnInit(): void {
  }

  applyStatus(tasks: TaskI[], id_process: string): string {
    const processTasks = this.processResultService.createTaskTree(tasks || []);
    const findTaskById = (tasks: TaskI[], id: string): TaskI[] => {
      let result: TaskI[] = [];
      for (let task of tasks) {
        if (task.id === id) {
          result.push(task);
        }
        if (task.children) {
          result = result.concat(findTaskById(task.children, id));
        }
      }
      return result;
    };

    const validateProcessTasks = findTaskById(processTasks, id_process);
    let borderColor = '4px solid #DADADA';
    for (let task of validateProcessTasks) {
      switch (task.status.key) {
        case 'complete':
          borderColor = '4px solid #75E67A';
          break;
        case 'draft':
          borderColor = '4px solid #EDDD54';
          break;
        default:
          borderColor = '4px solid #DADADA';
          break;
      }
      if (borderColor !== '4px solid #DADADA') {
        break;
      }
    }
    return borderColor;
  }

  hasCards(processId: string, parentId: string): boolean {
    return this.processResult.some(item => item.viewCardColumn === processId && item.viewColumn === parentId);
  }

  onDrop(event: any, process: any) {
    const index = this.processResult.findIndex(val => val.id === event.data.item.id );

    if (index !== -1) {
      this.processResult[index].viewCardColumn = process.id;
      this.processResult[index].viewColumn = process.parentId;
    }
  }
}
