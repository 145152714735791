<!--<div class="pie" style="&#45;&#45;p:20"> 20%</div>-->
<!--<div class="pie" style="&#45;&#45;p:40;&#45;&#45;c:darkblue;&#45;&#45;b:10px"> 40%</div>-->
<!--<div class="pie no-round" style="&#45;&#45;p:60;&#45;&#45;c:purple;&#45;&#45;b:15px"> 60%</div>-->
<!--<div class="pie animate no-round" style="&#45;&#45;p:80;&#45;&#45;c:orange;"> 80%</div>-->
<!--<div class="pie animate" style="&#45;&#45;p:90;&#45;&#45;c:lightgreen"> 90%</div>-->

<div style="position: relative; aspect-ratio: 1"
     [ngStyle]="{
          'width': width,
        }"
>
  <div
    class="pie"
    [ngStyle]="{
          '--w': width,
        }"
  >

    <div class="pie-inside animate"
         [ngStyle]="{
          '--p': value,
          '--w': width,
          '--h': background,
          '--b': border,
        }"
    >

    </div>
  </div>


  <div
    class="pie"
    [ngStyle]="{
          '--w': width,
        }"
  >

    <div class="pie-outside"
         [ngClass]="{'animate': animate}"
         [ngStyle]="{
          '--p': value,
          '--w': width,
          '--c': color,
          '--b': border,
          '--fz': fontSize
        }"
    >
      {{value}}%
    </div>
  </div>
</div>


