import { createAction, props } from "@ngrx/store";
import {PermissionsObjectI} from "../../interfaces/permissions.interface";

export const loadPermissionsObjects = createAction(
  "[Permissions] Load Permissions Objects",
  props<{
    url: string
  }>()
);

export const loadPermissionsObjectsSuccess = createAction(
  "[Permissions] Load Permissions Objects Success",
  props<{
    objects: PermissionsObjectI[]
  }>()
);
