import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmailProviderI, UserEmailSettingsI} from "../../../../infrastructure/interfaces/settings.interface";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Store} from "@ngrx/store";
import {pairwise, Subject, takeUntil} from "rxjs";
import {
  selectEmailProviders, selectLoadingCrudUserEmailSettings,
  selectLoadingEmailProviders
} from "../../../../infrastructure/store/settings/settings.selector";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  addEmailProviderSetting, removeEmailProviderSetting,
  setLoadingCrudUserEmailSettings, updateEmailProviderSetting
} from "../../../../infrastructure/store/settings/settings.actions";

@Component({
  selector: 'app-add-email-provider',
  templateUrl: './add-email-provider.component.html',
  styleUrls: ['./add-email-provider.component.scss']
})
export class AddEmailProviderComponent implements OnInit, OnDestroy{

  destroy$ = new Subject<void>();

  action: 'add' | 'edit' | 'remove' = 'add';
  emailSetting: UserEmailSettingsI | null = null;

  loadingEmailProviders: boolean = false;

  loadingCrudUserEmailSettings: boolean = false;

  emailProviders: EmailProviderI[] = [];

  form: FormGroup;

  passwordVisibility: boolean = true;

  constructor(
    private store: Store,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      id: null,
      smtp_server: null,
      smtp_port: null,
      imap_server: null,
      imap_port: null,
      email: [null, [Validators.required, Validators.email]],
      email_password: null,
      account_name: [null, Validators.required],
      provider: [null, Validators.required],
      status: "pending"
    })
  }

  ngOnInit() {
    if (this.emailSetting){
      this.form.patchValue(this.emailSetting);
    }

    // Listen for loading crud user email settings changes
    this.store.select(selectLoadingCrudUserEmailSettings).pipe(
      takeUntil(this.destroy$),
      pairwise()
    ).subscribe(([lastValue, newValue]) => {
      this.loadingCrudUserEmailSettings = newValue;
      if (lastValue && !newValue){
        this.bsModalRef.hide();
      }
    })

    // Listen for loading email providers changes
    this.store.select(selectLoadingEmailProviders).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingEmailProviders = value;
    })

    // Listen for email providers changes
    this.store.select(selectEmailProviders).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.emailProviders = [
        ...value,
        // {
        //   model: 'email-provider',
        //   id: 'custom',
        //   default: true,
        //   label: 'Custom',
        //   icon_url: 'assets/img/Custom.svg',
        //   smtp_server: '',
        //   smtp_port: '',
        //   imap_server: '',
        //   imap_port: ''
        // }
      ];
    })

    // Listen for form provider field changes
    this.provider.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.form.patchValue({
        smtp_server: value.smtp_server,
        smtp_port: value.smtp_port,
        imap_server: value.imap_server,
        imap_port: value.imap_port,
      }, {onlySelf: true, emitEvent: false})
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(){
    this.form.markAllAsTouched();
    console.log("FORM VALUE =>", this.form.value);
    switch (this.action){
      case "add":
        if (this.form.valid) {
          this.store.dispatch(setLoadingCrudUserEmailSettings({status: true}));
          this.store.dispatch(addEmailProviderSetting({
            emailProvider: this.form.value
          }))
        }
        break;
      case "edit":
        if (this.form.valid) {
          this.store.dispatch(setLoadingCrudUserEmailSettings({status: true}));
          this.store.dispatch(updateEmailProviderSetting({
            emailProvider: this.form.value
          }))
        }
        break;
      case "remove":
        this.store.dispatch(setLoadingCrudUserEmailSettings({status: true}));
        this.store.dispatch(removeEmailProviderSetting({
          emailProvider: this.form.value
        }))
    }

    if (!this.form.valid){
      const errors = Object.keys(this.form.controls).reduce((acc: any, key:string) =>{
        acc[key] = this.form.get(key)?.errors
        return acc
      }, {})
      console.log("Form Errors => ", errors);
    }
  }

  close(){
    if (!this.loadingCrudUserEmailSettings){
      this.bsModalRef.hide();
    }
  }

  selectProvider(){
    if (!this.loadingCrudUserEmailSettings){
      this.form.patchValue({
        smtp_server: null,
        smtp_port: null,
        imap_server: null,
        imap_port: null,
        email: null,
        password: null,
        from: null,
        provider: null
      }, {onlySelf: true, emitEvent: false})
    }
  }

  togglePasswordVisibility(){
    this.passwordVisibility = !this.passwordVisibility;
  }

  get provider(){
    return this.form.get("provider") as FormControl
  }

  get smtp_server(){
    return this.form.get("smtp_server") as FormControl
  }

  get smtp_port(){
    return this.form.get("smtp_port") as FormControl
  }

  get imap_server(){
    return this.form.get("imap_server") as FormControl
  }

  get imap_port(){
    return this.form.get("imap_port") as FormControl
  }

  get account_name(){
    return this.form.get("account_name") as FormControl
  }

  get email(){
    return this.form.get("email") as FormControl
  }

  get email_password(){
    return this.form.get("email_password") as FormControl
  }
}
