import { createAction, props } from "@ngrx/store";
import { ProcessScrollI } from "../../interfaces/process-scroll.interface";

export const loadProcessScrollList = createAction(
  "[Process Scroll List] Load Process Scroll List"
);

export const loadProcessListScrollSuccess = createAction(
  "[Process Scroll List] Load Process Scroll List Success",
  props<{
    response: ProcessScrollI
  }>()
);

