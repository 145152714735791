import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookmarkFieldComponent } from './fields/bookmark-field/bookmark-field.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { StatusBoardComponent } from './status-board/status-board.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { TasksStatusComponent } from './tasks-staus/tasks-status.component';
import { SplashComponent } from './splash/splash.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { RemoveModalComponent } from './modals/remove-modal/remove-modal.component';
import {ModalModule} from "ngx-bootstrap/modal";
import { TaskBoardComponent } from './task-board/task-board.component';
import {ConfirmationModalComponent} from "./modals/confirmation-modal/confirmation-modal.component";
import { SecurityUserComponent } from './security/security-user/security-user.component';
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {PipesModule} from "../pipes/pipes.module";
import { SecurityComponent } from './security/security.component';
import { PermissionsFieldComponent } from './security/permissions-field/permissions-field.component';
import { CustomBreadcrumbsComponent } from './custom-breadcrumbs/custom-breadcrumbs.component';
import {NgCircleProgressModule} from "ng-circle-progress";
import { ProcessDetailsComponent } from './process-details/process-details.component';
import { GridComponent } from './grid/grid.component';
import { MapComponent } from './map/map.component';
import { DamSyncComponent } from './dam-sync/dam-sync.component';
import { GridCardElementComponent } from './grid-card-element/grid-card-element.component';
import { GridCardComponent } from './grid-card/grid-card.component';
import { AccordionComponent } from './accordion/accordion.component';
import { DndModule } from 'ngx-drag-drop';
import { ProcessStateComponent } from './process-state/process-state.component';
import { StatusLogsComponent } from './status-logs/status-logs.component';
import { ContactsBoardComponent } from './contacts-board/contacts-board.component';
import { ContactsTableComponent } from './contacts-table/contacts-table.component';
import { RightSidebarComponent } from './contacts-board/right-sidebar/right-sidebar.component';
import { ModalAlertComponent } from './modals/modal-alert/modal-alert.component';
import { TruncatePipe } from '../../infrastructure/pipe/truncate.pipe';
import { KanbanStatusComponent } from './kanban-status/kanban-status.component';
import { KanbanStatusColumnsComponent } from './kanban-status-columns/kanban-status-columns.component';
import { KanbanStatusCardComponent } from './kanban-status-card/kanban-status-card.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { StatusFilterComponent } from './fields/status-filter/status-filter.component';
import { TaskFilterComponent } from './fields/task-filter/task-filter.component';
import {CollapseModule} from "ngx-bootstrap/collapse";
import { GlobalSearchComponent } from './fields/global-search/global-search.component';
import {PopoverModule} from "ngx-bootstrap/popover";
import { GridGlobalComponent } from './grid-global/grid-global.component';
import { ProcessResultTableComponent } from './process-result-table/process-result-table.component';
import { SettingsLeftbarComponent } from './settings-leftbar/settings-leftbar.component';
import { SettingsEmailComponent } from './settings-email/settings-email.component';
import { AddEmailProviderComponent } from './modals/add-email-provider/add-email-provider.component';
import { EmailProviderComponent } from './fields/email-provider/email-provider.component';
import { ProcessModelGridComponent } from './process-model-grid/process-model-grid.component';
import { FormsComponent } from './widgets/forms/forms.component';
import { PhiViewerComponent } from './widgets/phi-viewer/phi-viewer.component';

@NgModule({
    declarations: [
        BookmarkFieldComponent,
        StatusBoardComponent,
        TasksStatusComponent,
        SplashComponent,
        SpinnerComponent,
        RemoveModalComponent,
        ConfirmationModalComponent,
        TaskBoardComponent,
        SecurityUserComponent,
        SecurityComponent,
        PermissionsFieldComponent,
        CustomBreadcrumbsComponent,
        ProcessDetailsComponent,
        GridComponent,
        MapComponent,
        DamSyncComponent,
        GridCardElementComponent,
        GridCardComponent,
        AccordionComponent,
        ProcessStateComponent,
        StatusLogsComponent,
        ContactsBoardComponent,
        ContactsTableComponent,
        RightSidebarComponent,
        ModalAlertComponent,
        TruncatePipe,
        KanbanStatusComponent,
        KanbanStatusColumnsComponent,
        KanbanStatusCardComponent,
        CircularProgressComponent,
        StatusFilterComponent,
        TaskFilterComponent,
        GlobalSearchComponent,
        GridGlobalComponent,
        ProcessResultTableComponent,
        SettingsLeftbarComponent,
        SettingsEmailComponent,
        AddEmailProviderComponent,
        EmailProviderComponent,
        ProcessModelGridComponent,
        FormsComponent,
        PhiViewerComponent,
    ],
    exports: [
        BookmarkFieldComponent,
        StatusBoardComponent,
        SplashComponent,
        SpinnerComponent,
        TaskBoardComponent,
        CustomBreadcrumbsComponent,
        GridComponent,
        MapComponent,
        DamSyncComponent,
        AccordionComponent,
        ProcessStateComponent,
        StatusLogsComponent,
        ContactsBoardComponent,
        GlobalSearchComponent,
        SettingsLeftbarComponent,
        SettingsEmailComponent,
        ProcessModelGridComponent,
        FormsComponent,
        PhiViewerComponent,
    ],
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    PipesModule,
    NgCircleProgressModule,
    DndModule,
    ScrollingModule,
    CollapseModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule { }
