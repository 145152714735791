//IDENTIFICADOR DEL REDUCER
import {DetailTabs, TaskI, TaskPaginatorI, TaskResultTab} from "../../interfaces/task.interface";

export const taskFeatureKey = 'tasks';

export const initialTaskPaginator = {
  "count": 0,
  "page": 1,
  "limit": 10,
  "previous": null,
  "next": null
}

export interface StateTask {
  selectedTask: TaskI | null;
  detailsTask: TaskI | null;
  loadingTask: boolean;
  loadingTaskPermissions: boolean;
  taskList: TaskI[];
  paginator: TaskPaginatorI;
  showFilterBar: boolean;
  showRightBar: boolean;
  detailTab: DetailTabs;
  taskResultTab: TaskResultTab;
}

//ESTADO INICIAL
export const initialState: StateTask = {
  selectedTask: null,
  detailsTask: null,
  loadingTask: false,
  loadingTaskPermissions: false,
  taskList: [],
  paginator: initialTaskPaginator,
  showFilterBar: false,
  showRightBar: false,
  detailTab: DetailTabs.info,
  taskResultTab: TaskResultTab.detail
};
