import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, filter, map, Observable, of, switchMap, take} from "rxjs";
import {selectAuditBackendApi, selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class QRFormService {

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  generateFormQR(data: {
    "formUrl": string,
    "formId": string,
    "versionId": string,
    "customKey": string,
    "page": string
  }): Observable<any> {
    return this.http.post(
      'https://mng.codes',
      {
        'url': `${data.formUrl}/fill?formId=${data.formId}&versionId=${data.versionId}&page=${data.page}&customKey=${data.customKey}`
      }
    ).pipe(
      map((value: any) => {
        return value?.data?.short_url || ''
      }),
      catchError((error) => {
        console.log(error)
        return ''
      })
    )
  }
}
