
//IDENTIFICADOR DEL REDUCER
import {ProcessI, ProcessPaginatorI} from "../../interfaces/process.interface";

export const processFeatureKey = 'process';

export const initialProcessPaginator = {
  "count": 0,
  "page": 1,
  "limit": 10,
  "previous": null,
  "next": null
}

export interface StateProcess {
  paginator: ProcessPaginatorI;
  processList: ProcessI[];
  selectedProcess: ProcessI | null;
  loadingProcess: boolean;
}

//ESTADO INICIAL
export const initialState: StateProcess = {
  paginator: initialProcessPaginator,
  processList: [],
  selectedProcess: null,
  loadingProcess: false
};
