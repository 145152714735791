import { createAction, props } from "@ngrx/store";
import {AuditResponseI} from "../../interfaces/audit.interface";

export const loadAuditStatus = createAction(
  "[Audit] Load Audit Status",
  props<{
    object_id: string
  }>()
);


export const loadAuditStatusSuccess = createAction(
  "[Audit] Load Audit Status Success",
  props<{
    response: AuditResponseI
  }>()
);

export const setLoadingAuditStatus = createAction(
  "[Audit] Set Loading Audit Status",
  props<{
    status: boolean
  }>()
);
