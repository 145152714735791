import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {UserEmailSettingsI} from "../../../infrastructure/interfaces/settings.interface";
import {selectEmailUserSettings, selectLoadingSettings} from "../../../infrastructure/store/settings/settings.selector";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {AddEmailProviderComponent} from "../modals/add-email-provider/add-email-provider.component";
import {loadEmailProviders, setLoadingEmailProviders} from "../../../infrastructure/store/settings/settings.actions";

@Component({
  selector: 'app-settings-email',
  templateUrl: './settings-email.component.html',
  styleUrls: ['./settings-email.component.scss']
})
export class SettingsEmailComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>()

  userEmailSettings: UserEmailSettingsI[] = [];

  loadingSettings: boolean = false;

  bsModalRef?: BsModalRef;

  constructor(
    private store: Store,
    private modalService: BsModalService,
  ) {
    this.store.dispatch(setLoadingEmailProviders({status: true}));
    this.store.dispatch(loadEmailProviders());
  }

  ngOnInit() {
    // Listen for user email settings changes
    this.store.select(selectEmailUserSettings).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.userEmailSettings = value;
    })

    // Listen for loading user settings
    this.store.select(selectLoadingSettings).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingSettings = value;
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addEmailAccount(){
    const initialState: ModalOptions = {
      initialState: {
        action: "add",
        position: 0
      },
      animated: false,
      backdrop: "static",
      keyboard: false,
      class: "modal-md modal-mobile"
    };

    this.bsModalRef = this.modalService.show(AddEmailProviderComponent, initialState)
  }

  editEmailAccount(userEmailSetting: UserEmailSettingsI, position: number){
    const initialState: ModalOptions = {
      initialState: {
        action: "edit",
        emailSetting: userEmailSetting,
        position: position
      },
      animated: false,
      backdrop: "static",
      keyboard: false,
      class: "modal-md modal-mobile"
    };

    this.bsModalRef = this.modalService.show(AddEmailProviderComponent, initialState)
  }

  removeEmailAccount(userEmailSetting: UserEmailSettingsI, position: number){
    const initialState: ModalOptions = {
      initialState: {
        action: "remove",
        emailSetting: userEmailSetting,
        position: position
      },
      animated: false,
      backdrop: "static",
      keyboard: false,
      class: "modal-sm modal-mobile"
    };

    this.bsModalRef = this.modalService.show(AddEmailProviderComponent, initialState)
  }


}
