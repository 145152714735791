<!-- Tools bar -->
<form [formGroup]="filterForm">
	<!-- Tools bar container -->
	<div class="w-100 d-flex flex-wrap align-items-center px-3 border-bottom">
		<!-- Left Side -->
		<div class="col-3 d-flex justify-content-start align-content-center py-1">
			<!-- Filter Button -->
			<div
				(click)="toggleFilterMenu()"
				class="process-btn btn-sm"
				tooltip="Filter"
				container="body"
				containerClass="d-none d-md-block d-lg-block text-mini m-1 fw-bold"
				[ngClass]="{ active: showFilterBar }"
			>
				<span class="material-symbols-outlined text-title text-primary-color">
					<span *ngIf="!showFilterBar" class="material-symbols-outlined text-title">
						filter_alt
					</span>
					<span *ngIf="showFilterBar" class="material-symbols-outlined text-title">
						filter_alt_off
					</span>
				</span>
				<span class="text-mini">Filter</span>
			</div>

		</div>

		<!-- Right Side -->
		<div class="col-12 col-sm-9 py-1 h-100 d-flex justify-content-end align-items-center gap-1">
			<!-- Limits -->
			<div class="d-flex align-items-center justify-content-start pe-3 py-1">
				<span class="d-none d-md-inline text-normal pe-2">Items per page:</span>
				<div style="width: 60px">
					<select class="form-select process-btn text-normal" aria-label="Default select example">
						<option selected value="10">10</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
			</div>

			<!--Paginator -->
			<div
				class="d-none d-md-flex justify-content-end justify-content-sm-start align-items-center py-1 me-4"
			>
				<!-- First -->
				<span
					class="material-symbols-outlined text-title py-1"
					[class.disabledPaginator]="!canNavigate('first')"
					style="width: 30px; margin-right: -15px; cursor: pointer"
					(click)="!canNavigate('first') || paginate('first')"
				>
					keyboard_double_arrow_left
				</span>

				<!-- Previous -->
				<span
					class="material-symbols-outlined text-title py-1"
					[class.disabledPaginator]="!canNavigate('previous')"
					style="width: 30px; margin-right: -15px; cursor: pointer"
					(click)="!canNavigate('previous') || paginate('previous')"
				>
					chevron_left
				</span>

				<!-- Page -->
				<span class="text-normal px-2 user-select-none">
					{{ resultPaginator.count ? getPageInterval(resultPaginator.current || 0).start : 0 }}
					to
					{{ resultPaginator.count ? getPageInterval(resultPaginator.current || 0).end : 0 }}
					of
					{{ resultPaginator.count || 0 }}
				</span>

				<!-- Next -->
				<span
					class="material-symbols-outlined text-title py-1"
					[class.disabledPaginator]="!canNavigate('next')"
					style="width: 30px; cursor: pointer"
					(click)="!canNavigate('next') || paginate('next')"
				>
					chevron_right
				</span>

				<!-- Last -->
				<span
					class="material-symbols-outlined text-title py-1"
					[class.disabledPaginator]="!canNavigate('last')"
					style="width: 30px; margin-left: -15px; cursor: pointer"
					(click)="!canNavigate('last') || paginate('last')"
				>
					keyboard_double_arrow_right
				</span>
			</div>

			<!-- table view -->
			<div
				class="process-btn"
				(click)="changeViewComponent(viewComponentType.table)"
				tooltip="Table"
				container="body"
				[delay]="300"
				containerClass="text-mini fw-bold m-1"
			>
				<span
					class="material-symbols-outlined text-title text-complementary-color-a user-select-none"
					style="font-size: 1rem; padding: 0.1rem"
				>
        toc
				</span>
			</div>


			<!-- left bar -->
			<span
				class="bar-icon bar-icon-left border"
				tooltip="Left Bar"
				container="body"
				[delay]="300"
				containerClass="text-mini fw-bold m-1"
				[class.active]="showFilterBar"
				(click)="toggleFilterMenu()"
			>
			</span>

			<!-- right bar -->
			<span
				class="bar-icon bar-icon-right border"
				tooltip="Right Bar"
				container="body"
				[delay]="300"
				containerClass="text-mini fw-bold m-1"
				[class.active]="showRightBar"
				(click)="toggleRightBar()"
			></span>
		</div>
	</div>
</form>

<!-- Main content -->
<div class="d-flex flex-grow-1 overflow-y-auto">
	<!-- Filter Bar -->
	<div class="filter-bar overflow-hidden" [class.visible]="showFilterBar">
		<!-- Header -->
		<div class="d-flex align-items-center px-3 py-2">
			<div class="flex-grow-1">
				<span class="text-neutral-color-h text-sub-title fw-bold">Filters</span>
			</div>
			<div class="d-flex justify-content-end align-items-center">
				<span
					class="material-symbols-outlined text-sub-title pointer fw-bold hover:btn"
					tooltip="Close"
					container="body"
					containerClass="d-none d-md-block d-lg-block text-mini fw-sm-bold m-1"
					[adaptivePosition]="false"
					(click)="toggleFilterMenu()"
				>
					close
				</span>
			</div>
		</div>

		<!-- Body -->
		<div class="flex-grow-1 overflow-y-auto px-3 pt-2">
			<!-- Filter by bookmarked -->



		</div>
	</div>

	<!-- Table Content *ngIf="resultList.length > 0"  -->
	<div class="flex-grow-1 px-0 table-responsive">

    <!-- Table -->
		<app-contacts-table
      [triggerDownloadCSV]="triggerDownloadCSV"
			[paginationValue]="pageInterval"
			[viewComponent]="viewComponent"
      (eventActionRow)="eventActionRow($event)"
      (emitValidateDownload)="actionValidateDownload($event)"
      (eventTriggerDownloadCSV)="actionTriggerDownloadCSV($event)"
		></app-contacts-table>
		<table
			class="table table-responsive"
			*ngIf="selectedProcess?.tableDashboard?.headers && viewComponent === 'table'"
		>
			<!-- Header -->
			<thead>
				<tr class="process-row-header">
					<div class="upper"></div>
					<div class="lower"></div>
					<div class="overlay"></div>
					<th class="align-middle px-3" scope="col">
						<span class="">
							<input class="form-check-input pointer p-1" type="checkbox" value="" />
						</span>
					</th>
					<th
						*ngFor="let header of selectedProcess?.tableDashboard?.headers; let headerLast = last"
						scope="col"
						class="align-middle"
						[style]="{
							width: header.component === 'TaskStatus' ? (headerLast ? '246px' : '200px') : 'auto'
						}"
					>
						<span class="text-sub-title fw-bold text-complementary-color-a">{{
							header.label
						}}</span>
					</th>
				</tr>
			</thead>

			<!-- Body -->
			<tbody>
				<!-- Process Result Row -->
				<ng-container
					*ngFor="let result of resultList; let index = index; let first = first; let last = last"
				>
					<!-- Row Data -->
					<tr
						class="align-middle process-row"
						[class.selected]="detailContactResult?.id === result.id"
					>
						<!-- border up -->
						<div class="upper"></div>
						<!-- border down -->
						<div class="lower"></div>
						<!-- Table row overlay -->
						<div class="overlay">
							<!-- <ng-container
                  *ngTemplateOutlet="actions, context:{result: result, index: index, first: first, last: last}"
                ></ng-container> -->
						</div>
						<!-- Item Selector Column-->
						<td class="align-middle px-3">
							<span class="">
								<input class="form-check-input pointer" type="checkbox" value="" />
							</span>
						</td>

						<!-- Dynamic Columns -->
						<td
							class="align-middle"
							*ngFor="
								let head of selectedProcess?.tableDashboard?.headers;
								let headIndex = index;
								let headFirst = first;
								let headLast = last
							"
						>
							<!-- <ng-container
                  *ngTemplateOutlet="columnValue, context:{header: head, result: result, index: index, headIndex: headIndex, first: headFirst, last: headLast}"
                ></ng-container> -->
						</td>
					</tr>

					<!-- Row Details -->
				</ng-container>
			</tbody>
		</table>
	</div>

	<!-- No Data to show -->


	<!-- Lateral detail view -->
	<div
		class="right-bar visible bg-white border-start border-neutral-color-c h-100"
		[class.visible]="showRightBar"
	>
		<div *ngIf="detailContactResult && showRightBar" class="h-100 d-flex flex-column">
			<!-- Lateral Tabs -->
			<div class="d-flex p-2 align-items-center border-bottom border-neutral-color-c">
				<span
					class="material-symbols-outlined m-auto cursor-pointer"
					style="font-size: 20px"
					[ngStyle]="{ color: viewRightBar === 'info' ? 'var(--primary-color)' : '#BBBBBB' }"
					(click)="selectRightBar('info')"
				>
					info
				</span>
			</div>
			<app-right-sidebar
				*ngIf="detailContactResult && viewRightBar === 'info'"
				[detailContactResult]="detailContactResult"
			></app-right-sidebar>


			<ng-container *ngIf="viewRightBar === 'preview' && detailContactResult.type === 'email'">
				<img width="248" class="m-auto" [src]="detailContactResult.content.thumbnail" />
			</ng-container>
			<!-- Details -->
			<div class="flex-grow-1 d-flex flex-row overflow-y-hidden">
				<!-- Process Details -->

			</div>
		</div>

		<!-- No selected Row Message-->
		<div
			*ngIf="!detailContactResult && showRightBar"
			class="h-100 w-100 d-flex flex-column justify-content-center align-content-center"
		>
			<div class="w-100 d-flex justify-content-center">
				<span class="text-normal text-neutral-color-e text-center">
					There is no data selected<br />
					Please select row to show details
				</span>
			</div>
		</div>
	</div>
</div>


