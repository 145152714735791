import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {filter, Observable, switchMap, take} from "rxjs";
import {selectBackendApi} from "../store/config/config.selector";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProcessService {

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  getProcessList(): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/process-list`)
      })
    )
  }

  getSelectedProcess(processId: string): Observable<any> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get(`${backendApiUrl}/process-list/${processId}`)
      })
    )
  }

  getProcessStatsByTask(data: {processId: string, taskId: string}): Observable<{ total: number }> {
    return this.store.select(selectBackendApi).pipe(
      filter((val) => val !== ""),
      take(1),
      switchMap((backendApiUrl: string) => {
        return this.http.get<{ total: number}>(`${backendApiUrl}/process/${data.processId}/stats/${data.taskId}`)
      })
    )
  }
}
