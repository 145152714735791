import { createAction, props } from "@ngrx/store";
import {ConfigI} from "../../interfaces/config.interface";

export const setLoadingConfig = createAction(
  "[Config] Set Loading Config",
  props<{
    status: boolean
  }>()
);


export const loadConfig = createAction(
  "[Config] Load Config",
  props<{
    url: string
    secret: string
  }>()
)

export const loadConfigSuccess = createAction(
	"[Config] Load Config Success",
	props<{
    config: ConfigI
  }>()
);
