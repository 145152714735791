//IDENTIFICADOR DEL REDUCER
import {MapPropertiesI} from "../../interfaces/map-process.interface";
import {ViewComponentType} from "../../interfaces/process-result.interface";

export const configFeatureKey = 'config';

export interface StateConfig {
  "loadingConfig": boolean;
  "api": string;
  "audit_api": string;
  "initialProcess": string;
  "formApi": string;
  "allowedFiles": string[];
  "groupStore": { pk: string; sk: string; } | null;
  "fileImporterApi": string;
  "fileImporterWss": string;
  "fileImporterChannel": string;
  "rootUsers": string[];
  "map": MapPropertiesI;
  "websocketServer": string;
  "websocketChannel": string;
  calendarBackendUrl: string;
  mapElementsLimit: number;
  default_view: ViewComponentType;
  active_views: ViewComponentType[];
}

//ESTADO INICIAL
export const initialState: StateConfig = {
  loadingConfig: true,
  api: "",
  audit_api: "",
  initialProcess: "",
  formApi: "",
  allowedFiles: [],
  groupStore: null,
  fileImporterApi: "",
  fileImporterWss: "",
  fileImporterChannel: "",
  rootUsers: [],
  websocketChannel: "",
  map: {
    markerClusterer: {
      baseSize: 0,
      baseTextSize: 0,
      color: "",
      fontColor: ""
    }
  },
  websocketServer: "",
  calendarBackendUrl: "",
  mapElementsLimit: 1000,
  default_view: ViewComponentType.grid,
  active_views: [ViewComponentType.table]
}
