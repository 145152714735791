import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {ProcessResultI} from "../../../infrastructure/interfaces/process-result.interface";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-process-state',
  templateUrl: './process-state.component.html',
  styleUrls: ['./process-state.component.scss']
})
export class ProcessStateComponent {

  @Input() selectorType: "full" | "light" = "full"
  @Input() width: string = 'auto';
  @Input() editable: boolean = true;
  @Input() selectedState: TaskStateI | null = null;
  @Input() stateList: TaskStateI[] = [];

  @Output() onSelectState: EventEmitter<TaskStateI> = new EventEmitter<TaskStateI>()

  get process_state_list() {
    if (this.selectedState && this.stateList.length > 0){
      return this.stateList.filter((val) => val.key !== this.selectedState?.key)
    }
    return []
  }

  selectState(state: TaskStateI) {
    this.onSelectState.emit(state);
  }
}
