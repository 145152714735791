export enum WebsocketAction {
  "CREATE"="CREATE",
  "UPDATE"="UPDATE",
  "REMOVE"="REMOVE"
}

export interface WebsocketMessageI {
  "action": WebsocketAction;
  "object": {
    "model": string;
    "id": string;
  },
  "properties": any;
}
