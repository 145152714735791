<div class="position-relative d-flex flex-row px-0 h-100 overflow-hidden">
  <div class="flex-grow-1 d-flex flex-column h-100 overflow-hidden">

    <!-- Tools bar -->
    <form [formGroup]="filterForm">
      <!-- Tools bar container -->
      <div class="w-100 d-flex flex-wrap align-items-center px-3 border-bottom border-neutral-color-c">
        <!-- Left Side -->
        <div class="col-3 d-flex justify-content-start align-content-center py-1">
          <!-- Filter Button -->
<!--          <div-->
<!--            (click)="toggleFilterMenu()"-->
<!--            class="process-btn btn-sm"-->
<!--            tooltip="Filter" container="body" containerClass="d-none d-md-block d-lg-block text-mini m-1 fw-bold"-->
<!--            [ngClass]="{'active': showFilterBar}"-->
<!--          >-->
<!--          <span class="material-symbols-outlined text-title text-primary-color">-->
<!--            <span *ngIf="!showFilterBar" class="material-symbols-outlined text-title">-->
<!--              filter_alt-->
<!--            </span>-->
<!--            <span *ngIf="showFilterBar" class="material-symbols-outlined text-title">-->
<!--              filter_alt_off-->
<!--            </span>-->
<!--          </span>-->
<!--            <span class="text-mini">Filter</span>-->
<!--          </div>-->
        </div>

        <!-- Right Side -->
        <div class="col-12 col-sm-9 py-1 h-100 d-flex justify-content-end align-items-center gap-1">

          <!--Paginator Tablet -->
          <div class="d-md-none d-flex justify-content-end align-items-center">
            <!-- Previous -->
            <div
              class="d-flex pointer justify-content-center border rounded-2 me-1 action-btn"
              [ngClass]="{
                    'bg-primary-color border-primary-color': taskPaginator.previous,
                    'bg-status-color-inactive border-status-color-inactive': !taskPaginator.previous
                    }"
              (click)="paginate('previous')"
            >
                    <span
                      class="material-symbols-outlined text-title user-select-none"
                      [ngClass]="{
                      'text-white': taskPaginator.previous,
                      'text-neutral-color-e': !taskPaginator.previous
                      }"
                    >
                      chevron_left
                    </span>
              <span
                class="text-normal user-select-none text-white"
                [ngClass]="{
                      'text-white': taskPaginator.previous,
                      'text-neutral-color-e': !taskPaginator.previous
                      }"
              >
                      Previous
                    </span>
            </div>

            <!-- Next -->
            <div
              class="d-flex pointer justify-content-center border rounded-2 action-btn"
              [ngClass]="{
                    'bg-primary-color border-primary-color': taskPaginator.next,
                    'bg-status-color-inactive border-status-color-inactive': !taskPaginator.next
                    }"
              (click)="paginate('next')"
            >
                    <span
                      class="text-normal user-select-none text-white"
                      [ngClass]="{
                      'text-white': taskPaginator.next,
                      'text-neutral-color-e': !taskPaginator.next
                      }"
                    >
                      Next
                    </span>
              <span
                class="material-symbols-outlined text-title user-select-none"
                [ngClass]="{
                      'text-white': taskPaginator.next,
                      'text-neutral-color-e': !taskPaginator.next
                      }"
              >
                      chevron_right
                    </span>
            </div>
          </div>

          <!-- Limits -->
          <div class="d-flex align-items-center justify-content-start pe-3 py-1">
            <span class="d-none d-md-inline text-mini pe-2">Items per page:</span>
            <div class="">
              <ng-select
                class="text-mini fw-bold ng-select-mini"
                style="width: 55px; outline: none "
                [items]="itemPerPageList"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="itemPerPage"
              >
              </ng-select>
            </div>
          </div>

          <!--Paginator -->
          <div class="d-none d-md-flex justify-content-end justify-content-sm-start align-items-center py-1 me-4">
            <!-- First-->
            <span
              class="material-symbols-outlined text-title  py-1"  style="width: 30px; margin-right: -15px"
              tooltip="{{taskPaginator.previous?'First':null}}"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold"
              [ngClass]="{
                    'text-complementary-color-a pointer hover:material-symbols-outlined': taskPaginator.previous,
                    'text-neutral-color-g': !taskPaginator.previous
                    }"
              (click)="paginate('first')"
            >keyboard_double_arrow_left</span>

            <!-- Previous -->
            <span
              class="material-symbols-outlined  text-title py-1"  style="width: 30px; margin-right: -15px"
              tooltip="{{taskPaginator.previous?'Previous':null}}"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold px-2"
              [ngClass]="{
                    'text-complementary-color-a pointer hover:material-symbols-outlined': taskPaginator.previous,
                    'text-neutral-color-g': !taskPaginator.previous
                    }"
              (click)="paginate('previous')"
            >chevron_left</span>

            <!-- Page -->
            <span class="text-normal px-2 user-select-none">
                    {{taskPaginator.count ? (taskPaginator.page-1)*(itemPerPage?.value || 10) + 1 : 0}}
              to
              {{taskPaginator.count ? math.min(taskPaginator.page*(itemPerPage?.value || 10), taskPaginator.count): 0}}
              of
              {{taskPaginator.count || 0}}
                  </span>

            <!-- Next -->
            <span
              class="material-symbols-outlined  text-title py-1" style="width: 30px"
              tooltip="{{taskPaginator.next?'Next':null}}"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold"
              [ngClass]="{
                    'text-complementary-color-a pointer hover:material-symbols-outlined': taskPaginator.next,
                    'text-neutral-color-g': !taskPaginator.next
                    }"
              (click)="paginate('next')"
            >chevron_right</span>

            <!-- Last -->
            <span
              class="material-symbols-outlined text-title py-1" style="width: 30px; margin-left: -15px"
              tooltip="{{taskPaginator.next?'Last':null}}"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold"
              [ngClass]="{
                    'text-complementary-color-a pointer  hover:material-symbols-outlined': taskPaginator.next,
                    'text-neutral-color-g': !taskPaginator.next
                    }"
              (click)="paginate('last')"
            >keyboard_double_arrow_right</span>
          </div>

          <!-- left bar -->
          <span
            class="bar-icon bar-icon-left"
            tooltip="Left Bar"
            container="body"
            [delay]="300"
            containerClass="text-mini fw-bold m-1"
            [class.active]="showFilterBar"
            (click)="toggleFilterMenu()"
          ></span>

          <!-- right bar -->
          <span
            class="bar-icon bar-icon-right"
            tooltip="Right Bar"
            container="body"
            [delay]="300"
            containerClass="text-mini fw-bold m-1"
            [class.active]="showRightBar"
            (click) = toggleRightBar()
          ></span>
        </div>


      </div>
    </form>

    <!-- Main content -->
    <div class="d-flex flex-grow-1 overflow-y-auto">
      <!-- Filter Bar -->
      <div
        class="filter-bar overflow-hidden"
        [class.visible]="showFilterBar"
      >
        <!-- Header -->
        <div class="d-flex align-items-center px-3 py-2">
          <div class="flex-grow-1">
            <span class="text-neutral-color-h text-sub-title fw-bold">Filters</span>
          </div>
          <div class="d-flex justify-content-end align-items-center">
          <span
            class="material-symbols-outlined text-sub-title pointer fw-bold hover:btn"
            tooltip="Close" container="body"
            containerClass="d-none d-md-block d-lg-block text-mini fw-sm-bold m-1"
            [adaptivePosition]="false"
            (click)="toggleFilterMenu()"
          >
            close
          </span>
          </div>
        </div>

        <!-- Body -->
        <div class="flex-grow-1 overflow-y-auto px-3 pt-2">
          <!-- Filter by bookmarked -->
          <div
            class="d-flex align-items-center justify-content-start w-100 mb-2"
          >
            <div class="d-flex align-items-center">
              <span class="text-normal fw-bold text-neutral-color-h">Filter by:</span>
            </div>
            <div
              class="d-flex px-1"
            >
              <bookmark-field></bookmark-field>
            </div>
          </div>

          <!-- Filter by bookmarked -->
          <div
            class="d-flex flex-column align-items-start justify-content-center w-100 mb-3"
            *ngFor="let val of [].constructor(5); let i=index"
          >
            <div class="d-flex align-items-center">
              <span class="text-normal fw-bold text-neutral-color-h">Filter {{i}}:</span>
            </div>
            <div class="w-100">
              <ng-select
                class="text-normal"
                [items]="filterList"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
              >
              </ng-select>
            </div>
          </div>

          <div class="w-100 d-flex justify-content-end align-items-center py-2">
            <!-- Apply buttom -->
            <div class="process-btn btn-sm">
          <span class="material-symbols-outlined text-sub-title text-primary-color">
            done
          </span>
              <span class="text-normal text-neutral-color-h user-select-none">Apply</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Table Content -->
      <div
        *ngIf="taskList.length > 0"
        class="flex-grow-1 px-0 table-responsive"
      >
        <table class="table" *ngIf="selectedProcess?.tableTask?.headers">
          <!-- Header -->
          <thead>
          <tr  class="process-row-header">
            <div class="upper"></div>
            <div class="lower"></div>
            <div class="overlay"></div>
            <th class="align-middle px-3 d-none" scope="col">
                <span
                  class=""
                >
                  <input
                    class="form-check-input pointer p-1"
                    type="checkbox" value=""
                    (change)="toggleSelectAll($event)"
                  >
                </span>
            </th>
            <th class="align-middle">
              <span class="text-sub-title fw-bold text-complementary-color-a">Task</span>
            </th>
            <th *ngFor="let header of selectedProcess?.tableTask?.headers" scope="col" class="align-middle py-1">
              <span class="text-sub-title fw-bold text-complementary-color-a">{{header.label}}</span>
            </th>
            <th class="align-middle">
              <span class="text-sub-title fw-bold text-complementary-color-a">Status</span>
            </th>
            <th class="align-middle" style="width: 200px">
              <span class="text-sub-title fw-bold text-complementary-color-a">Updated Date</span>
            </th>
          </tr>
          </thead>

          <!-- Body -->
          <tbody>
          <tr
            class="align-middle process-row"
            [class.selected]="detailsTask?.id === task.id && detailsTask?.resultId === task.resultId"
            *ngFor="let task of taskList; let taskIndex=index; let taskFirst=first; let taskLast=last"
          >
            <!-- border up -->
            <div class="upper"></div>
            <!-- border down -->
            <div class="lower"></div>
            <!-- Table row overlay -->
            <div
              class="overlay"

            >
              <ng-container
                *ngTemplateOutlet="actions, context:{task: task, index: taskIndex, first: taskFirst, last: taskLast}"
              ></ng-container>
            </div>

            <td class="align-middle px-3 col-option d-none" scope="col"  >
                <span
                  class=""
                >
                  <input
                    class="form-check-input pointer p-1"
                    type="checkbox" value=""
                    [checked]="selectedTaskIds.has(task.resultId)"
                    (change)="toggleSelection(task.resultId, $event)"

                  >
                </span>
            </td>
            <td (click)="setDetailsTask($event, task)" class="align-middle col-option" scope="col" >
              <div class="d-flex justify-content-start align-items-center">
                <span
                  *ngIf="task?.bookmarked"
                  class="material-symbols-sharp text-sub-title text-primary-color pe-2"
                >
                bookmark
              </span>
                <span
                  class="text-normal"
                >{{ task?.label || 'Not Defined' }}</span>
              </div>

            </td>

            <!-- Dynamic Columns -->
            <td (click)="setDetailsTask($event, task)" class="align-middle col-option" *ngFor="let head of selectedProcess?.tableTask?.headers; let headIndex=index" >
              <ng-container
                *ngTemplateOutlet="columnValue, context:{header: head, task: task, index: headIndex}"
              ></ng-container>
            </td>

            <td (click)="setDetailsTask($event, task)" class="align-middle col-option" scope="col" >
                <label
                class="status-bg"
                [ngStyle]="{ 'background': task?.status?.color }">
                  <span class="text-normal d-block">{{ task?.status?.label || 'Not Defined' }}</span>
              </label>
            </td>

            <td (click)="setDetailsTask($event, task)" class="align-middle col-option col-last" style="width: 200px" >
                <span
                  class="text-normal"
                >{{ (task?.updated_at | date: 'medium') || 'Not Defined' }}</span>
            </td>

            <td (click)="setDetailsTask($event, task)" class="icons-container">
              <!-- Edit Process Result Action -->
              <span
                *ngIf="task.user_permissions.write"
                class="material-symbols-outlined text-title pointer p-1 user-select-none"
                tooltip="Edit"
                containerClass="text-mini fw-bold"
                (click)="goToTask($event, task)"
              >
                edit_square
              </span>

              <!-- Process Result Security Action -->
              <span
                *ngIf="task.user_permissions.write"
                class="material-symbols-outlined text-title p-1 pointer user-select-none"
                tooltip="Security"
                containerClass="text-mini fw-bold"
                (click)="showTaskSecurity($event, task)"
              >
                admin_panel_settings
              </span>

              <!-- Show Info Action -->
              <span
                class="material-symbols-outlined text-title p-1 pointer user-select-none"
                tooltip="Show Details"
                containerClass="text-mini fw-bold"
                (click)="showTaskDetails($event, task)"
              >
                info
              </span>
            </td>

          </tr>

          </tbody>
        </table>
      </div>

      <!-- No Data to show -->
      <div
        *ngIf="taskList.length === 0"
        class="flex-grow-1 px-0 d-flex flex-column justify-content-center"
      >
        <div class="w-100 d-flex justify-content-center">
          <img
            class="no-process-icon"
            src="assets/img/icon-process-white.svg"
          />
        </div>
        <div class="w-100 d-flex justify-content-center">
          <span class="text-sub-title text-neutral-color-e text-center">
            This view currently has no pending tasks to display.
          </span>
        </div>
      </div>

      <!-- Lateral detail view -->
      <div
        class="right-bar bg-white border-start border-neutral-color-c h-100"
        [class.visible]="showRightBar"
      >
        <div *ngIf="detailsTask && showRightBar" class="h-100 d-flex flex-column">

          <!-- Lateral Tabs -->
          <div class="d-flex align-items-center border-bottom border-neutral-color-c">

            <!--Info Tab -->
            <div
              *ngIf="detailsTask.user_permissions.read"
              class="position-relative flex-grow-1 d-flex justify-content-center align-items-center text-neutral-color-e
                    pointer details-tab"
              [class.enter]="selectedTab===detailTabs.info"
              (click)="setDetailsTab(detailTabs.info)"
            >
              <span class="material-symbols-outlined text-title user-select-none">
              info
              </span>
              <div
                class="marker position-absolute bottom-0 start-0"
                [class.enter]="selectedTab===detailTabs.info"
              ></div>
            </div>

            <!-- Security Tab -->
            <div
              *ngIf="detailsTask.user_permissions.write"
              class="position-relative flex-grow-1 d-flex justify-content-center align-items-center text-neutral-color-e
                     pointer details-tab"
              [class.enter]="selectedTab===detailTabs.security"
              (click)="setDetailsTab(detailTabs.security)"
            >
              <span class="material-symbols-outlined text-title user-select-none">
              admin_panel_settings
              </span>
              <div
                class="marker position-absolute bottom-0 start-0 bg-primary-color"
                [class.enter]="selectedTab===detailTabs.security"
              ></div>
            </div>
          </div>


          <!-- Details -->
          <div class="flex-grow-1 d-flex flex-row overflow-y-hidden">
            <!-- Task Details -->
            <div
              *ngIf="detailsTask.user_permissions.read"
              class="pb-2 d-flex flex-column justify-content-start align-items-center overflow-y-auto details-body"
              [ngClass]="{
                'selected': selectedTab===detailTabs.info
              }"
            >

              <app-process-details
                *ngIf="selectedTab === detailTabs.info"
              ></app-process-details>

            </div>

            <!-- Task Security -->
            <div
              *ngIf="detailsTask.user_permissions.write"
              class="details-body overflow-hidden"
              [ngClass]="{
                'selected': selectedTab===detailTabs.security
              }"
            >
              <app-security
                [selectedObjects]="detailsTaskPermissions"
                [objects]="permissionObjects"
                [displayField]="'full_name'"
                [loading]="loadingTaskPermissions"
                (objectPermissionAdded)="addObjectPermission($event)"
                (objectPermissionRemoved)="removeObjectPermission($event)"
              ></app-security>
            </div>
          </div>

        </div>

        <!-- No selected Row Message-->
        <div *ngIf="!detailsTask && showRightBar" class="h-100 w-100 d-flex flex-column justify-content-center align-content-center">
          <div class="w-100 d-flex justify-content-center">
          <span class="material-symbols-sharp text-neutral-color-i" style="font-size: 48px">
            gesture_select
          </span>
          </div>
          <div class="w-100 d-flex justify-content-center">
          <span class="text-normal text-neutral-color-e text-center">
            There is no data selected<br>
            Please select row to show details
          </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- Template for column dynamic values -->
<ng-template #columnValue let-header=header let-task=task let-index=index>
  <ng-container [ngSwitch]="header.component">
    <!-- Values date type -->
    <div
      *ngSwitchCase="'FormattedDate'"
      class="d-flex justify-content-start align-items-center"
    >
      <span
        *ngIf="task.taskHeaderData[header.key]"
        class="text-normal"
      >{{ task.taskHeaderData[header.key] | date: "medium" }}</span>
      <span
        class="text-normal"
        *ngIf="!task.taskHeaderData[header.key]"
      >Not Defined</span>
    </div>

    <!-- Values TaskStatus type -->
    <div
      *ngSwitchCase="'TaskStatus'"
      class="d-flex justify-content-start align-items-center"
    >
      <span
        class="text-normal"
      >
        <app-tasks-status [processResult]="task"></app-tasks-status>
      </span>
    </div>

    <!-- Values text type and default -->
    <div
      *ngSwitchDefault
      class="d-flex justify-content-start align-items-center h-100"
    >
      <span
        class="text-normal"
      >{{ task.taskHeaderData?.[header.key] || 'Not Defined' }}</span>
    </div>
  </ng-container>
</ng-template>

<!-- Template for actions -->
<ng-template #actions let-task=task let-index=index let-first=first let-last=last>
  <div class="d-none justify-content-end align-items-center w-100 h-100 px-3">
    <!-- Edit Process Result Action -->
    <span
      *ngIf="task.user_permissions.write"
      class="material-symbols-outlined text-title pointer p-1 user-select-none"
      tooltip="Edit"
      containerClass="text-mini fw-bold"
      (click)="goToTask($event, task)"
    >
      edit_square
    </span>

    <!-- Process Result Security Action -->
    <span
      *ngIf="task.user_permissions.write"
      class="material-symbols-outlined text-title p-1 pointer user-select-none"
      tooltip="Security"
      containerClass="text-mini fw-bold"
      (click)="showTaskSecurity($event, task)"
    >
      admin_panel_settings
    </span>

    <!-- Show Info Action -->
    <span
      class="material-symbols-outlined text-title p-1 pointer user-select-none"
      tooltip="Show Details"
      containerClass="text-mini fw-bold"
      (click)="showTaskDetails($event, task)"
    >
      info
    </span>
  </div>
</ng-template>



