import { Component } from '@angular/core';

@Component({
  selector: 'app-dam-sync',
  templateUrl: './dam-sync.component.html',
  styleUrls: ['./dam-sync.component.scss']
})
export class DamSyncComponent {

}
