import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TaskStateI} from "../../../../infrastructure/interfaces/process.interface";
import {EmailProviderI} from "../../../../infrastructure/interfaces/settings.interface";

@Component({
  selector: 'email-provider-field',
  templateUrl: './email-provider.component.html',
  styleUrls: ['./email-provider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailProviderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailProviderComponent implements ControlValueAccessor {

  @Input() emailProviders: EmailProviderI[] = [];

  emailProvider: EmailProviderI | null = null;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(obj: EmailProviderI | null): void {
    this.emailProvider = obj;
  }

  selectEmailProvider(provider: EmailProviderI){
    this.emailProvider = provider;
    this.onChange(provider);
    this.onTouch(provider);
  }
}
