import { createFeatureSelector, createSelector } from '@ngrx/store';
import {permissionsFeatureKey, StatePermissions} from "./state";
import {ConfigI} from "../../interfaces/config.interface";
import {PermissionsObjectI} from "../../interfaces/permissions.interface";

export const permissionsState = createFeatureSelector<StatePermissions>(permissionsFeatureKey);

export const selectPermissionsObjects = createSelector(
  permissionsState,
  (state: StatePermissions): PermissionsObjectI[] => {
    return state.objects
  }
)
