import { createReducer, on } from '@ngrx/store';
import {initialState} from "./state";
import * as processActions from "./process.actions"

export const processReducer = createReducer(
	initialState,
  on(processActions.loadProcessListSuccess, (state, action) => {

    return {
      ...state,
      processList: action.response.results,
      selectedProcess: action.response.results.length > 0 ? action.response.results[0] : null,
      paginator: {
        count: action.response.count,
        page: action.response.page,
        next: action.response.next,
        previous: action.response.previous
      }
    }
  }),

  on(processActions.loadSelectedProcessSuccess, (state, action) => {
    return {
      ...state,
      selectedProcess: action.process
    }
  }),

  on(processActions.setLoadingProcess, (state, action) => {
    return {
      ...state,
      loadingProcess: action.status
    }
  }),

);
