import {Component, Input} from '@angular/core';
import {BreadcrumbsI} from "../../../infrastructure/interfaces/breadcrumbs.interface";
import {Router} from "@angular/router";

@Component({
  selector: 'app-custom-breadcrumbs',
  templateUrl: './custom-breadcrumbs.component.html',
  styleUrls: ['./custom-breadcrumbs.component.scss']
})
export class CustomBreadcrumbsComponent {
  @Input() objects: BreadcrumbsI[] = []

  constructor(
    private router: Router
  ) {
  }

  goTo(object: BreadcrumbsI, last: boolean) {
    if (!last) {
      this.router.navigate([object.url])
    }
  }
}
