<ng-container>
	<div class="p-3" *ngIf="content && detailContactResult">
		<h5 class="text-primary text-sub-title">{{ content.generalInformation }}</h5>
		<ul class="list-unstyled">
			<li *ngIf="detailContactResult.name">
				<strong class="text-sub-title">{{ content.name }}</strong>
				<p class="text-normal">{{ detailContactResult.name | titlecase }}</p>
			</li>
			<li  *ngIf="detailContactResult.type">
				<strong class="text-sub-title">{{ content.type }}</strong>
				<p class="text-normal">{{ detailContactResult.type | uppercase }}</p>
			</li>
			<li  *ngIf="detailContactResult.specialty">
				<strong class="text-sub-title">{{ content.specialty }}</strong>
				<p class="text-normal">{{ detailContactResult.specialty | titlecase }}</p>
			</li>
			<li  *ngIf="detailContactResult.company">
				<strong class="text-sub-title">{{ content.company }}</strong>
				<p class="text-normal">{{ detailContactResult.company | titlecase }}</p>
			</li>
			<li  *ngIf="detailContactResult.address">
				<strong class="text-sub-title">{{ content.address }}</strong>
				<p class="text-normal">Test Number ({{ detailContactResult.address | titlecase }})</p>
			</li>
		</ul>
		<hr class="border" />
		<h5 class="text-primary text-sub-title">{{ content.otherInformation }}</h5>
		<ul class="list-unstyled">
			<li>
				<strong class="text-sub-title">{{ content.createdDate }}</strong>
				<p class="text-normal">{{ detailContactResult.created_at | date : 'YYYY-MM-d' }}</p>
			</li>
			<li>
				<strong class="text-sub-title">{{ content.createdby }}</strong>
				<p class="text-normal">{{ detailContactResult.name | titlecase }}</p>
			</li>
			<li>
				<strong class="text-sub-title">{{ content.lastUpdated }}</strong>
				<p class="text-normal">{{ detailContactResult.updated_at | date : 'YYYY-MM-d' }}</p>
			</li>
			<li>
				<strong class="text-sub-title">{{ content.updatedBy }}</strong>
				<p class="text-normal">{{ detailContactResult.name | titlecase }}</p>
			</li>
		</ul>
	</div>
</ng-container>
