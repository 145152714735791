import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthInterceptorProvider, HttpLoaderFactory, LayoutModule} from "@mangosoft/mango-layout";
import {AuthModule} from "@mangosoft/mango-login";
import {JwtModule} from "@auth0/angular-jwt";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ToastrModule} from "ngx-toastr";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";
import {CookieService} from "ngx-cookie-service";
import {EffectsArray, metaReducers, reducerProvider, reducers} from "./infrastructure/store";
import {environment} from "../environments/environment";
import {AuthGuard} from "./infrastructure/guards/auth/auth.guard";
import {ComponentsModule} from "./ui/components/components.module";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import { DndModule } from 'ngx-drag-drop';
import {CollapseModule} from "ngx-bootstrap/collapse";
import {TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        LayoutModule,
        AuthModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return '';
                },
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            progressBar: true,
            closeButton: true,
            progressAnimation: 'increasing',
        }),
        EffectsModule.forRoot(EffectsArray),
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return "";
                },
            },
        }),
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
        NoopAnimationsModule,
        ComponentsModule,
        DndModule
    ],
    providers: [
      AuthGuard,
      CookieService,
      AuthInterceptorProvider,
      reducerProvider,
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
    bootstrap: [AppComponent],

})
export class AppModule { }
