import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {ProcessResultI} from "../../../infrastructure/interfaces/process-result.interface";
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ConfirmationModalComponent} from "../modals/confirmation-modal/confirmation-modal.component";
import {Subject, takeUntil} from "rxjs";
import {
  changeStatusProcessResult,
  changeStatusProcessResultSuccess
} from "../../../infrastructure/store/process-result/process-result.actions";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {TaskType} from "../../../infrastructure/interfaces/task.interface";

@Component({
  selector: 'app-kanban-status-card',
  templateUrl: './kanban-status-card.component.html',
  styleUrls: ['./kanban-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanStatusCardComponent implements OnDestroy{

  destroy$ = new Subject<void>()

  @Input() total: number = 0;
  @Input() selectedProcess!: ProcessI | null;
  private _processResult!: ProcessResultI;
  @Input() set processResult(value: ProcessResultI){
      this._processResult = value;
  };
  get processResult(){
    return this._processResult
  }
  @Input() last: boolean = false;
  @Input() height: number = 0;
  @Input() index!: number

  bsModalRef?: BsModalRef;

  constructor(
    private store: Store,
    private modalService: BsModalService,
    private router: Router
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  processStatusChange(status: TaskStateI){
    if (this.processResult && this.selectedProcess) {
      if (status.message){
        const initialState: ModalOptions = {
          initialState: {
            message: status.message,
            item: {...this.processResult, process_status: status}
          },
          backdrop: "static",
          keyboard: false,
          class: "modal-sm"
        };
        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
        this.bsModalRef.content.action.pipe(
          takeUntil(this.destroy$)
        ).subscribe((value: ProcessResultI) => {
          if (value && this.processResult && this.selectedProcess) {
            this.store.dispatch(changeStatusProcessResultSuccess({
              previousStatus: this.processResult.process_status,
              index: null,
              result: value,
            }))
            this.store.dispatch(changeStatusProcessResult({
              previousStatus: this.processResult.process_status,
              processId: this.selectedProcess.id,
              resultId: this.processResult.id,
              data: value,
            }))
          }
        })
      } else {
        this.store.dispatch(changeStatusProcessResultSuccess({
          previousStatus: this.processResult.process_status,
          index: null,
          result: {...this.processResult, process_status: status},
        }))
        this.store.dispatch(changeStatusProcessResult({
          previousStatus: this.processResult.process_status,
          processId: this.selectedProcess.id,
          resultId: this.processResult.id,
          data: {...this.processResult, process_status: status},
        }))
      }
    }
  }

  onDrop(event: any) {
    if (this.selectedProcess) {
      const index = event.index === 0 ? this.index : this.index + 1
      const insertResult = {...event.data}

      this.store.dispatch(changeStatusProcessResultSuccess({
        previousStatus: insertResult.process_status,
        index: index,
        result: {...insertResult, process_status: this.processResult.process_status},
      }))

      if (insertResult.process_status.key !== this.processResult.process_status.key){

        this.store.dispatch(changeStatusProcessResult({
          previousStatus: insertResult.process_status,
          processId: this.selectedProcess.id,
          resultId: insertResult.id,
          data: {...insertResult, process_status: this.processResult.process_status},
        }))
      }
    }


  }

  goto(){
    if (this.selectedProcess && this.processResult){
      const operative_tasks = this.selectedProcess.tasks.filter((v) => v.type !== TaskType.GROUP)
      if (operative_tasks.length > 0){
        const url = `/process/${this.selectedProcess?.id}/process-results/${this.processResult.id}/${operative_tasks[0].id}`
        this.router.navigate([url]);
      }
    }
  }

}
