<div class="d-flex justify-content-start align-content-center gap-4" style="width: 200px">
  <!-- Ball witget -->
  <div class="d-flex justify-content-start align-middle py-1">
    <div
      *ngFor="let task of processResult?.tasks || []"
      class="task-status"
      [ngStyle]="{
      'background-color': status_map?.[task.status.key] || status_map['empty']
      }"
    >
    </div>
  </div>

  <!-- Complete status widget -->
  <div class="d-flex align-content-center justify-content-center gap-1 h-100 py-1">
    <div class="d-flex justify-content-start align-content-center" style="width: 42px">
      {{math.round(processResult?.percentComplete || 0)}}%
    </div>
    <div style="width: 20px">
      <circle-progress
        [percent]="processResult?.percentComplete || 0"
        [radius]="100"
        [outerStrokeWidth]="35"
        [innerStrokeWidth]="35"
        [space]="-35"
        [outerStrokeColor]="'#6AA84F'"
        [innerStrokeColor]="'#E7E7E7'"
        [showSubtitle]="false"
        [showTitle]="false"
        [animation]="true"
        [animationDuration]="300"
        [startFromZero]="false"
        [responsive]="true"
      ></circle-progress>
    </div>
  </div>
</div>


