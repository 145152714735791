import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {itemsPerPageList} from "../../../infrastructure/store/process-result/state";
import {initialTaskPaginator} from "../../../infrastructure/store/task/state";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {LocalStorageService} from "../../../infrastructure/services/local-storage.service";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {
  selectDetailsTask,
  selectLoadingTaskPermissions,
  selectTaskDetailsTab,
  selectTaskList,
  selectTaskPaginator,
  selectTaskShowFilterBar,
  selectTaskShowRightBar
} from "../../../infrastructure/store/task/task.selector";
import {
  DetailTabs,
  TaskI,
  TaskPaginatorI,
  TaskPermissionsObjectI
} from "../../../infrastructure/interfaces/task.interface";
import {
  loadSelectedTaskSuccess,
  loadTaskList,
  removeTaskPermissions,
  setDetailTask,
  setLoadingTask,
  setTaskDetailsTab,
  setTaskShowFilterBar,
  setTaskShowRightBar,
  updateTaskPermissions,
  updateTaskPermissionsSuccess
} from "../../../infrastructure/store/task/task.actions";
import {Router} from "@angular/router";
import {PermissionsObjectI} from "../../../infrastructure/interfaces/permissions.interface";
import {selectPermissionsObjects} from "../../../infrastructure/store/permissions/permissions.selector";

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss']
})
export class TaskBoardComponent implements OnInit, OnDestroy{

  destroy$ = new Subject<void>();
  detailTabs = DetailTabs

  math = Math;

  taskPaginator: TaskPaginatorI = initialTaskPaginator;

  showFilterMenu: boolean = false;

  filterForm!: FormGroup;

  selectedProcess: ProcessI | null = null;

  taskList: TaskI[] = [];
  detailsTask: TaskI | null = null;
  detailsTaskPermissions: TaskPermissionsObjectI[] = [];
  loadingTaskPermissions: boolean = false;

  selectedTab: DetailTabs = DetailTabs.info;
  permissionObjects: Observable<PermissionsObjectI[]>;

  showFilterBar: boolean = false;
  showRightBar: boolean = false;

  // todo: remove after filters parametrization
  filterList = [
    { value: 'value1', label: 'Value 1' },
    { value: 'value2', label: 'Value 2' },
    { value: 'value3', label: 'Value 3' },
    { value: 'value4', label: 'Value 4' },
  ];
  filter_one = null;
  filter_two = null;
  filter_three = null;
  filter_four = null;
  taskShowRightBar = false;
  itemPerPageList = itemsPerPageList;
  taskId!: string;
  selectedTaskIds: Set<string> = new Set();

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.permissionObjects = this.store.select(selectPermissionsObjects);
  }

  ngOnInit() {
    // Listen for selected Process changes
    this.store.select(selectSelectedProcess).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.selectedProcess = value;

      if (value){
        this.buildFilterForm(value)
      }
    })

    // Listen for task list changes
    this.store.select(selectTaskList).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.taskList=value;
    })

    // Listen for Details Task changes
    this.store.select(selectDetailsTask).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.detailsTask = value;
      this.detailsTaskPermissions = [];
      if (this.detailsTask){
        this.detailsTaskPermissions = this.detailsTask.acl.objects.map((value) => {
          return {
            object: {
              ...value
            },
            permissions: {
              list: (this.detailsTask?.acl.list || []).filter((val) => value.username === val).length > 0,
              read: (this.detailsTask?.acl.read || []).filter((val) => value.username === val).length > 0,
              write: (this.detailsTask?.acl.write || []).filter((val) => value.username === val).length > 0,
              delete: (this.detailsTask?.acl.delete || []).filter((val) => value.username === val).length > 0,
            }
          }
        })
      }
    })

    // Listen for loading task permissions changes
    this.store.select(selectLoadingTaskPermissions).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value)=>{
      this.loadingTaskPermissions = value;
    })

    // Listen for task paginator changes
    this.store.select(selectTaskPaginator).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value)=>{
      this.taskPaginator = value;
    })

    // Listen for items per page changes
    this.itemPerPage?.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      // Sync between movil and desktop fields
      this.itemPerPage?.patchValue(value, {onlySelf: true, emitEvent: false});

      this.localStorageService.setItemPerPage(value);

      if (this.selectedProcess){
        // Close lateral task if open
        this.store.dispatch(setDetailTask({task: null}));

        this.store.dispatch(setLoadingTask({status: true}));
        this.store.dispatch(loadTaskList({
          processId: this.selectedProcess?.id,
          limit: value,
          page: 1
        }))
      }
    })

    // Listen for show filter bar changes
    this.store.select(selectTaskShowFilterBar).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.showFilterBar = value;
    })

    // Listen for show right bar changes
    this.store.select(selectTaskShowRightBar).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.showRightBar = value;
    })

    // Listen for details tab changes
    this.store.select(selectTaskDetailsTab).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.selectedTab = value;
    })
  }

  buildFilterForm(process: ProcessI) {
    this.filterForm = this.fb.group({
      itemPerPage: this.localStorageService.getItemPerPage()
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  paginate(action: "next"|"last"|"first"|"previous"){
    if (this.selectedProcess) {
      // Close lateral
      this.store.dispatch(setDetailTask({task: null}));

      // Pagination
      switch (action){

        case "first":
          if (this.taskPaginator.previous){
            this.store.dispatch(setLoadingTask({status: true}));
            this.store.dispatch(loadTaskList({
              processId: this.selectedProcess?.id,
              page: "1",
              limit: this.itemPerPage?.value
            }))
          }
          break;

        case "previous":
          if (this.taskPaginator.previous) {
            this.store.dispatch(setLoadingTask({status: true}));
            this.store.dispatch(loadTaskList({
              processId: this.selectedProcess.id,
              page: this.taskPaginator.previous,
              limit: this.itemPerPage?.value
            }))
          }
          break;

        case "next":
          if (this.taskPaginator.next) {
            this.store.dispatch(setLoadingTask({status: true}));
            this.store.dispatch(loadTaskList({
              processId: this.selectedProcess.id,
              page: this.taskPaginator.next,
              limit: this.itemPerPage?.value
            }))
          }
          break;

        case "last":
          if (this.taskPaginator.next){
            this.store.dispatch(setLoadingTask({status: true}));
            const lastPage = Math.floor(+this.taskPaginator.count/+this.itemPerPage?.value || +itemsPerPageList[0].value) + (this.taskPaginator.count % this.itemPerPage?.value > 0 ? 1 : 0)
            this.store.dispatch(loadTaskList({
              processId: this.selectedProcess.id,
              page: lastPage,
              limit: this.itemPerPage?.value
            }))
          }
          break;
      }
    }

  }

  // ***  Row Actions ** //
  setDetailsTask(event: any, task: TaskI){
    event.stopPropagation();
    task.id === this.detailsTask?.id && task.resultId === this.detailsTask.resultId ? this.taskShowRightBar = !this.taskShowRightBar: this.taskShowRightBar = true;
    this.store.dispatch(setDetailTask({task}));
    this.store.dispatch(setTaskDetailsTab({tab: DetailTabs.info}));

    this.store.dispatch(setTaskShowRightBar({ status: this.taskShowRightBar }));
  }

  goToTask(event:any, task: TaskI){


    event.stopPropagation();

    if (this.selectedProcess) {
      // Set Selected Task
      this.store.dispatch(loadSelectedTaskSuccess({task: task}))
      // Move to selected task url
      const url = `/process/${this.selectedProcess.id}/tasks/${task.resultId}/${task.id}`
      this.router.navigate([url])
    }
  }

  // Security Action. Open right bar and show security tab
  showTaskSecurity(event: any, task: TaskI){
    event.stopPropagation();
    this.store.dispatch(setTaskDetailsTab({tab: this.detailTabs.security}));
    this.store.dispatch(setDetailTask({task: task}));
    this.store.dispatch(setTaskShowRightBar({status: true}));
  }

  // Info Action. Open right bar and show info tab
  showTaskDetails(event: any, task: TaskI){
    event.stopPropagation();
    this.store.dispatch(setTaskDetailsTab({tab: this.detailTabs.info}));
    this.store.dispatch(setDetailTask({task: task}));
    this.store.dispatch(setTaskShowRightBar({status: true}));
  }

  // *** End Row Actions *** //


  toggleFilterMenu(){
    this.store.dispatch(setTaskShowFilterBar({status: !this.showFilterBar}));
  }

  toggleLeftBar() {
    this.store.dispatch(setTaskShowFilterBar({status: !this.showFilterBar}))
  }
  toggleRightBar() {
    this.store.dispatch(setTaskShowRightBar({status: !this.showRightBar}))
  }



  get itemPerPage() {
    return this.filterForm.get("itemPerPage")
  }

  setDetailsTab(tab: DetailTabs) {
    this.store.dispatch(setTaskDetailsTab({tab: tab}));
  }

  addObjectPermission(value: TaskPermissionsObjectI) {

    if (this.detailsTask){
      let acl = {...this.detailsTask.acl};

      acl.objects = (this.detailsTask?.acl?.objects || []).filter((val) => val.username !== value.object.username);
      acl.list = (this.detailsTask?.acl?.list || []).filter((val) => val !== value.object.username);
      acl.read = (this.detailsTask?.acl?.read || []).filter((val) => val !== value.object.username);
      acl.write = (this.detailsTask?.acl?.write || []).filter((val) => val !== value.object.username);
      acl.delete = (this.detailsTask?.acl?.delete || []).filter((val) => val !== value.object.username);

      acl.objects.push(value.object);
      if (value.permissions.list){ acl.list.push(value.object.username)}
      if (value.permissions.read){ acl.read.push(value.object.username)}
      if (value.permissions.write){ acl.write.push(value.object.username)}
      if (value.permissions.delete){ acl.delete.push(value.object.username)}

      this.store.dispatch(updateTaskPermissionsSuccess({
        task: {
          ...this.detailsTask,
          acl: acl
        }
      }))

      this.store.dispatch(updateTaskPermissions({
        resultId: this.detailsTask.resultId,
        taskId: this.detailsTask.id,
        data: value
      }))
    }
  }

  removeObjectPermission(value: TaskPermissionsObjectI) {
    if (this.detailsTask){
      let acl = {...this.detailsTask.acl};

      acl.objects = (this.detailsTask?.acl?.objects || []).filter((val) => val.username !== value.object.username);
      acl.list = (this.detailsTask?.acl?.list || []).filter((val) => val !== value.object.username);
      acl.read = (this.detailsTask?.acl?.read || []).filter((val) => val !== value.object.username);
      acl.write = (this.detailsTask?.acl?.write || []).filter((val) => val !== value.object.username);
      acl.delete = (this.detailsTask?.acl?.delete || []).filter((val) => val !== value.object.username);

      this.store.dispatch(updateTaskPermissionsSuccess({
        task: {
          ...this.detailsTask,
          acl: acl
        }
      }))

      this.store.dispatch(removeTaskPermissions({
        resultId: this.detailsTask.resultId,
        taskId: this.detailsTask.id,
        data: value
      }))
    }

  }

  toggleSelection(id: string, event: any) {
    if (event.target.checked) {
      this.selectedTaskIds.add(id);
    } else {
      this.selectedTaskIds.delete(id);
    }
  }

  toggleSelectAll(event: any): void {
    if (event.target.checked) {
      this.taskList.forEach(val => this.selectedTaskIds.add(val.resultId));
    } else {
      this.selectedTaskIds.clear();
    }
  }

  getSelectedContacts(): any[] {
    return this.taskList.filter(val => this.selectedTaskIds.has(val.resultId));
  }

  allSelected(): boolean {
    return this.taskList.length === this.selectedTaskIds.size;
  }

}
