import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@mangosoft/mango-layout';
import { Subject, catchError, of, takeUntil } from 'rxjs';

@Component({
	selector: 'app-right-sidebar',
	templateUrl: './right-sidebar.component.html',
	styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent implements OnInit {
	@Input() detailContactResult: any;
	private unsubscribe$: Subject<void> = new Subject<void>();
	content: any;

	constructor(private translateService: TranslateService) { }

	ngOnInit(): void {
		this.getTranslate();
    console.log(this.detailContactResult);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getTranslate() {
    this.translateService
      .init('process-manager')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          return of({ error: err });
        })
      )
      .subscribe((data) => {
        this.content = data?.pages?.contacts.rightSidebarInfo;
      });
	}
}
