import { createFeatureSelector, createSelector } from '@ngrx/store';
import {processFeatureKey, StateProcess} from "./state";
import {ProcessI} from "../../interfaces/process.interface";

export const processState = createFeatureSelector<StateProcess>(processFeatureKey);

export const selectProcessList = createSelector(
  processState,
  (state: StateProcess): ProcessI[] => {
    return state.processList
  }
)

export const selectSelectedProcess = createSelector(
  processState,
  (state: StateProcess): ProcessI | null => {
    return state.selectedProcess
  }
)

export const selectLoadingProcess = createSelector(
  processState,
  (state: StateProcess): boolean => {
    return state.loadingProcess
  }
)
