import { Injectable } from "@angular/core";
import { Subject, switchMap } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import {WebsocketAction, WebsocketMessageI} from "../interfaces/websocket.interface";
import {Store} from "@ngrx/store";
import {ProcessI} from "../interfaces/process.interface";
import {ProcessResultI} from "../interfaces/process-result.interface";
import {TaskI} from "../interfaces/task.interface";
import {selectSelectedProcess} from "../store/process/process.selector";
import {selectSelectedProcessResult} from "../store/process-result/process-result.selector";
import {selectSelectedTask} from "../store/task/task.selector";
import {loadSelectedProcessResult} from "../store/process-result/process-result.actions";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  selectedProcess!: ProcessI;
  selectedProcessResult!: ProcessResultI;
  selectedTask!: TaskI

  constructor(
    private store: Store
  ) {
    // Track selected process
    this.store.select(selectSelectedProcess).subscribe((val) => {
      if (val) this.selectedProcess = val
    })

    //Track selected result
    this.store.select(selectSelectedProcessResult).subscribe((val) => {
      if (val) this.selectedProcessResult = val
    })

    //Track selected task
    this.store.select(selectSelectedTask).subscribe((val) => {
      if (val) this.selectedTask = val
    })
  }

  websocketMessageAction = WebsocketAction;

  private socket$!: WebSocketSubject<WebsocketMessageI>;
  private socketInstance = false;
  private validClose = false;
  private messagesSubject$ = new Subject<WebSocketSubject<WebsocketMessageI>>()
  public messages$ = this.messagesSubject$.pipe(
    switchMap((value) => { return value})
  );

  public connect(WS_ENDPOINT: string): void {

    if (!this.socketInstance) {
      this.socket$ = this.getNewWebSocket(WS_ENDPOINT);
      this.messagesSubject$.next(this.socket$);
    }
  }

  private getNewWebSocket(WS_ENDPOINT: string): WebSocketSubject<WebsocketMessageI> {
    return webSocket({
      url: WS_ENDPOINT,
      closeObserver: {
        next: () => {
          if (!this.validClose){
            console.log('[ExportsWebsocketService]: reconnecting...');
            this.socketInstance = false;
            this.connect(WS_ENDPOINT);
          }

        }
      },
    });
  }

  close() {
    this.validClose = true;
    this.socketInstance = false;
    this.socket$.complete();
  }

  processWebsocketMessage(message: WebsocketMessageI){
    const action = message.action;
    const object = message.object;
    const properties = message.properties

    console.log("WEBSOCKET MESSAGE => ", message);
    console.log("Process Result => ", this.selectedProcessResult)

    // Update attachment
    if (action === this.websocketMessageAction.UPDATE && object.model === "process-result" ){
      if (this.selectedProcessResult?.id === message.object.id){
        this.store.dispatch(loadSelectedProcessResult({
          processId: this.selectedProcess.id,
          resultId: object.id
        }))
      }
    }
  }
}
