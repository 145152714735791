import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularProgressComponent {
  @Input() border: string = '2px';
  @Input() color: string = 'darkred';
  @Input() background: string = 'lightgrey';
  @Input() value: number = 0;
  @Input() width: string = '80px';
  @Input() fontSize: string = '1rem';
  @Input() animate: boolean = true;
}
