<div class="position-relative w-100 h-100 px-0 h-100 overflow-hidden">
  <!-- Content -->
  <div class="d-flex h-100 flex-column">
    <!-- Tools bar -->
    <div class="flex-shrink-0">
      <form *ngIf="selectedProcess else toolbarTemplate" [formGroup]="filterForm">
        <!-- Tools bar container -->
        <div class="w-100 d-flex flex-wrap align-items-center px-3 border-bottom border-neutral-color-c">

          <!-- Left Side -->
          <div class="col-12 col-sm-3 d-flex justify-content-center justify-content-sm-start align-content-center py-1">
            <!-- Filter Button -->
            <div class="col-4 col-sm-12 d-flex justify-content-sm-start align-items-center">
              <div
                (click)="toggleFilterMenu()"
                class="process-btn btn-sm"
                tooltip="Filter" container="body" containerClass="d-none d-md-block d-lg-block text-mini m-1 fw-bold"
                [ngClass]="{'active': dynamicFilterFormActive}"
              >
            <span class="material-symbols-outlined text-title text-primary-color">
              <span *ngIf="!dynamicFilterFormActive" class="material-symbols-outlined text-title">
                filter_alt
              </span>
              <span *ngIf="dynamicFilterFormActive" class="material-symbols-outlined text-title">
                filter_alt_off
              </span>
            </span>
                <span class="text-mini">Filter</span>
              </div>
            </div>

            <!-- Mobile view icons-->
            <div class="col-4 d-flex d-sm-none gap-2 justify-content-center align-items-center">
              <!-- grid view -->
              <div
                *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.grid)"
                class="process-btn active d-sm-none d-inline-flex"
                [class.active]="viewComponent === viewComponentType.grid"
                (click)="changeViewComponent(viewComponentType.grid)"
                tooltip="Grid View"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
              >
                <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
                  view_cozy
                </span>
              </div>

              <!-- table view -->
              <div
                *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.table)"
                class="process-btn active d-sm-none d-inline-flex"
                [class.active]="viewComponent === viewComponentType.table"
                (click)="changeViewComponent(viewComponentType.table)"
                tooltip="Table View"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
              >
                <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
                  toc
                </span>
              </div>

              <!-- kanban view -->
              <div
                *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.kanban)"
                class="process-btn active d-sm-none d-inline-flex"
                [class.active]="viewComponent === viewComponentType.kanban"
                (click)="changeViewComponent(viewComponentType.kanban)"
                tooltip="Kanban View"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
              >
                <span
                  class="material-symbols-outlined text-title text-complementary-color-a user-select-none"
                >
                  dashboard
                </span>
              </div>

              <!-- map view -->
              <div
                *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.map)"
                class="process-btn active d-sm-none d-inline-flex"
                [class.active]="viewComponent === viewComponentType.map"
                (click)="changeViewComponent(viewComponentType.map)"
                tooltip="Map View"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
              >
                <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
                  map
                </span>
              </div>
            </div>

            <!-- Mobile view left right bars-->
            <div class="col-4 d-flex d-sm-none gap-2 justify-content-end align-items-center">
              <!-- left bar -->
              <span
                class="bar-icon bar-icon-left  d-sm-none d-inline-flex"
                tooltip="Left Bar"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
                [class.active]="showFilterBar"
                (click)="toggleFilterMenu()"
              ></span>

              <!-- right bar -->
              <span
                class="bar-icon bar-icon-right d-sm-none d-inline-flex"
                tooltip="Right Bar"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold m-1"
                [class.active]="showRightBar"
                (click) = toggleRightBar()
              ></span>
            </div>


          </div>


          <!-- Right Side -->
          <div class="col-12 col-sm-9 py-1 h-100 d-flex justify-content-center justify-content-sm-end align-items-center gap-1">
            <!-- Map loader -->
            <div
              *ngIf="viewComponent === viewComponentType.map"
              class="d-flex align-items-center me-3"
            >
              <div
                *ngIf="loadingProcessResultMap"
                class="rounded-1 me-2 border border-primary-color"
                style="width: 150px; height: 10px"
              >
                <div
                  class="bg-primary-color rounded-1"
                  [ngStyle]="{
                  'transition': 'all 500ms',
                  'width': processResultMapTotal ? math.floor(processResultMapLoaded*100/processResultMapTotal) + '%' : 0  + '%',
                  'height': '100%'
                }"></div>
              </div>
              <div
                *ngIf="!loadingProcessResultMap"
                class="d-flex justify-content-center align-items-center"
              >
                <span class="text-normal">Address Found: {{processResultMapValid}}/{{ processResultMapTotal }}</span>
              </div>
            </div>

            <!-- Limits -->
            <div *ngIf="viewComponent === viewComponentType.table" class="d-flex align-items-center justify-content-start pe-3 py-1">
              <span class="d-none d-md-inline text-mini pe-2">Items per page:</span>
              <div class="">
                <ng-select
                  class="text-mini fw-bold ng-select-mini"
                  style="width: 55px; outline: none "
                  [items]="itemPerPageList"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="itemPerPage"
                >
                </ng-select>
              </div>
            </div>

            <!--Paginator -->
            <div *ngIf="viewComponent === viewComponentType.table" class="d-flex justify-content-end justify-content-sm-start align-items-center py-1 me-4">
              <!-- First-->
              <span
                class="material-symbols-outlined text-title  py-1"  style="width: 30px; margin-right: -15px"
                tooltip="{{resultPaginator.previous?'First':null}}"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold"
                [ngClass]="{
                      'text-complementary-color-a pointer hover:material-symbols-outlined': resultPaginator.previous,
                      'text-neutral-color-g': !resultPaginator.previous
                      }"
                (click)="paginate('first')"
              >keyboard_double_arrow_left</span>

              <!-- Previous -->
              <span
                class="material-symbols-outlined  text-title py-1"  style="width: 30px; margin-right: -15px"
                tooltip="{{resultPaginator.previous?'Previous':null}}"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold px-2"
                [ngClass]="{
                      'text-complementary-color-a pointer hover:material-symbols-outlined': resultPaginator.previous,
                      'text-neutral-color-g': !resultPaginator.previous
                      }"
                (click)="paginate('previous')"
              >chevron_left</span>

              <!-- Page -->
              <span class="text-normal px-2 user-select-none">
                      {{resultPaginator.count ? (resultPaginator.page-1)*(itemPerPage?.value || 10) + 1 : 0}}
                to
                {{resultPaginator.count ? math.min(resultPaginator.page*(itemPerPage?.value || 10), resultPaginator.count): 0}}
                of
                {{resultPaginator.count || 0}}
                    </span>

              <!-- Next -->
              <span
                class="material-symbols-outlined  text-title py-1" style="width: 30px"
                tooltip="{{resultPaginator.next?'Next':null}}"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold"
                [ngClass]="{
                      'text-complementary-color-a pointer hover:material-symbols-outlined': resultPaginator.next,
                      'text-neutral-color-g': !resultPaginator.next
                      }"
                (click)="paginate('next')"
              >chevron_right</span>

              <!-- Last -->
              <span
                class="material-symbols-outlined text-title py-1" style="width: 30px; margin-left: -15px"
                tooltip="{{resultPaginator.next?'Last':null}}"
                container="body"
                [delay]="300"
                containerClass="text-mini fw-bold"
                [ngClass]="{
                      'text-complementary-color-a pointer  hover:material-symbols-outlined': resultPaginator.next,
                      'text-neutral-color-g': !resultPaginator.next
                      }"
                (click)="paginate('last')"
              >keyboard_double_arrow_right</span>
            </div>

            <!-- grid view -->
            <div
              *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.grid)"
              class="process-btn active d-none d-sm-inline-flex"
              [class.active]="viewComponent === viewComponentType.grid"
              (click)="changeViewComponent(viewComponentType.grid)"
              tooltip="Grid View"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
            >
              <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
                view_cozy
              </span>
            </div>

            <!-- table view -->
            <div
              *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.table)"
              class="process-btn active d-none d-sm-inline-flex"
              [class.active]="viewComponent === viewComponentType.table"
              (click)="changeViewComponent(viewComponentType.table)"
              tooltip="Table View"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
            >
            <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
              toc
            </span>
            </div>

            <!-- kanban view -->
            <div
              *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.kanban)"
              class="process-btn active d-none d-sm-inline-flex"
              [class.active]="viewComponent === viewComponentType.kanban"
              (click)="changeViewComponent(viewComponentType.kanban)"
              tooltip="Kanban View"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
            >
            <span
              class="material-symbols-outlined text-title text-complementary-color-a user-select-none"
            >
              dashboard
            </span>
            </div>

            <!-- map view -->
            <div
              *ngIf="this.ui_active_views.length > 1 && ui_active_views.includes(viewComponentType.map)"
              class="process-btn active me-0 d-none d-sm-inline-flex"
              [class.active]="viewComponent === viewComponentType.map"
              (click)="changeViewComponent(viewComponentType.map)"
              tooltip="Map View"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
            >
            <span class="material-symbols-outlined text-title text-complementary-color-a user-select-none">
              map
            </span>
            </div>

            <!-- left bar -->
            <span
              class="bar-icon bar-icon-left ms-sm-4 d-none d-sm-inline-flex"
              tooltip="Left Bar"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
              [class.active]="showFilterBar"
              (click)="toggleFilterMenu()"
            ></span>

            <!-- right bar -->
            <span
              class="bar-icon bar-icon-right d-none d-sm-inline-flex"
              tooltip="Right Bar"
              container="body"
              [delay]="300"
              containerClass="text-mini fw-bold m-1"
              [class.active]="showRightBar"
              (click) = toggleRightBar()
            ></span>
          </div>

        </div>
      </form>
    </div>


    <!-- Main content -->
    <div class="flex-grow-1 d-flex overflow-hidden">
      <!-- Filter Bar -->
      <div
        class="filter-bar flex-shrink-0"
        [class.visible]="showFilterBar"
      >
        <!-- Header -->
        <div class="position-relative d-flex align-items-center px-3 py-2">
          <div class="flex-grow-1">
            <span class="text-neutral-color-h text-sub-title fw-bold">Filters</span>
          </div>
          <!-- Close button -->
          <div
            *ngIf="showFilterBar"
            class="rounded-circle pointer border d-sm-none bg-status-color-inactive position-absolute shadow-sm d-flex
                  justify-content-center align-items-center"
            style="width: 20px; height: 20px; top:10px; right: -30px"
            (click) = toggleFilterMenu()
          >
            <span class="material-symbols-outlined text-sub-title text-complementary-color-a fw-bolder">close</span>
          </div>
<!--          <div class="d-flex justify-content-end align-items-center">-->
<!--          <span-->
<!--            class="material-symbols-outlined text-sub-title pointer fw-bold hover:btn"-->
<!--            tooltip="Close" container="body"-->
<!--            containerClass="d-none d-md-block d-lg-block text-mini fw-sm-bold m-1"-->
<!--            [adaptivePosition]="false"-->
<!--            (click)="toggleFilterMenu()"-->
<!--          >-->
<!--            close-->
<!--          </span>-->
<!--          </div>-->
        </div>

        <!-- Body -->
        <div class="flex-grow-1 overflow-y-auto px-3 pt-2">


          <!-- Filters -->
          <ng-container
            *ngTemplateOutlet="filterValues, context:{processFilter: selectedProcess?.process_filters || [], processStates: selectedProcess?.process_states?.states_list || [], processTasks: selectedProcess?.tasks || [] }"
          ></ng-container>

        </div>

        <!-- Apply -->
        <div class="w-100 d-flex justify-content-end align-items-center px-3 py-2">
          <!-- Apply buttom -->
          <div class="process-btn btn-sm" (click)="applyFilter(this.dynamicFilterForm.value)">
              <span class="material-symbols-outlined text-sub-title text-primary-color">
                done
              </span>
            <span class="text-normal text-neutral-color-h user-select-none">Apply</span>
          </div>
        </div>
      </div>

      <!-- Content Widgets -->
      <div
        class="flex-grow-1 h-100 w-100 px-0 overflow-hidden"
      >
        <!-- Kanban -->
        <app-kanban-status *ngIf="viewComponent === viewComponentType.kanban"></app-kanban-status>

        <!-- Map -->
        <app-map *ngIf="viewComponent === viewComponentType.map"></app-map>

        <!-- GRID -->
<!--        <app-grid *ngIf="viewComponent === 'grid'"></app-grid>-->

        <app-grid-global *ngIf="viewComponent === viewComponentType.grid"></app-grid-global>

        <!-- Table -->
        <app-process-result-table *ngIf="viewComponent === viewComponentType.table"></app-process-result-table>

      </div>

      <!-- Lateral detail view -->
      <div
        class="right-bar flex-shrink-0 visible bg-white border-start border-neutral-color-c h-100"
        [class.visible]="showRightBar"
      >
        <!-- Close button -->
        <div
          *ngIf="showRightBar"
          class="rounded-circle pointer border d-sm-none bg-status-color-inactive position-absolute shadow-sm d-flex
                  justify-content-center align-items-center"
          style="width: 20px; height: 20px; top:10px; left: -30px;"
          (click) = toggleRightBar()
        >
          <span class="material-symbols-outlined text-complementary-color-a text-sub-title fw-bolder">close</span>
        </div>

        <!-- Lateral Tabs -->
        <div class="d-flex align-items-center border-bottom border-neutral-color-c">
          <!-- Info Tab -->
          <div
            class="position-relative flex-grow-1 d-flex justify-content-center align-items-center text-neutral-color-e
                   pointer details-tab"
            [class.enter]="selectedTab===detailTabs.info"
            (click)="setDetailsTab(detailTabs.info)"
          >
            <span class="material-symbols-outlined text-title user-select-none">
              info
            </span>
            <div
              class="marker position-absolute bottom-0 start-0"
              [class.enter]="selectedTab===detailTabs.info"
            ></div>
          </div>

          <!-- Security Tab -->
          <div
            class="position-relative flex-grow-1 d-flex justify-content-center align-items-center text-neutral-color-e
                   pointer details-tab"
            [class.enter]="selectedTab===detailTabs.security"
            (click)="setDetailsTab(detailTabs.security)"
          >
            <span class="material-symbols-outlined text-title user-select-none">
            admin_panel_settings
            </span>
            <div
              class="marker position-absolute bottom-0 start-0"
              [class.enter]="selectedTab===detailTabs.security"
            ></div>
          </div>
        </div>


          <!-- Process Details -->
        <div class="flex-grow-1 overflow-y-hidden">
          <ng-container *ngIf="selectedTab === detailTabs.info">
            <app-process-details
              class="flex-grow-1"
              [actions]="true"
            ></app-process-details>
          </ng-container>

            <!-- Process Security -->
          <ng-container  *ngIf="selectedTab === detailTabs.security">
            <app-security
              [selectedObjects]="detailsProcessResultPermissions"
              [objects]="permissionObjects"
              [displayField]="'full_name'"
              [loading]="loadingProcessResultPermissions"
              (objectPermissionAdded)="addObjectPermission($event)"
              (objectPermissionRemoved)="removeObjectPermission($event)"
            ></app-security>
          </ng-container>
        </div>



      </div>
    </div>
  </div>

</div>



<!-- Template for dynamic filters -->
<ng-template #filterValues let-processFilter=processFilter let-processStates=processStates let-processTasks=processTasks>
  <ng-container *ngIf="selectedProcess"   [formGroup]="dynamicFilterForm">
    <!-- Filter by bookmarked -->
    <div
      class="d-flex align-items-center justify-content-start w-100 mb-2"
    >
      <div class="d-flex align-items-center">
        <span class="text-normal fw-bold text-neutral-color-h">Filter by:</span>
      </div>
      <div
        class="d-flex px-1"
      >
        <bookmark-field formControlName="bookmarked"></bookmark-field>
      </div>
    </div>

    <!-- Process Status Filter -->
    <div class="d-flex flex-column align-items-start justify-content-center w-100 mb-3">
      <div
        class="w-100 border d-flex align-items-center py-1 px-1 rounded-top-2 pointer"
        [class.rounded-bottom-2]="processStatusFilterCollapse"
        (click)="processStatusFilterCollapse = !processStatusFilterCollapse"
        [attr.aria-expanded]="!processStatusFilterCollapse"
        aria-controls="collapseBasic1"
      >
        <div class="d-flex flex-grow-1 justify-content-start align-items-center">
          <span class="text-normal fw-bold text-neutral-color-h">Process Status:</span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <span class="material-symbols-outlined text-title text-complementary-color-a" *ngIf="processStatusFilterCollapse">arrow_drop_down</span>
          <span class="material-symbols-outlined text-title text-complementary-color-a" *ngIf="!processStatusFilterCollapse">arrow_drop_up</span>
        </div>
      </div>
      <div
        class="w-100 border-bottom border-start border-end py-1 px-2 rounded-bottom-2"
        id="collapseBasic1"
        [collapse]="processStatusFilterCollapse"
        [isAnimated]="true"
      >
        <div class="w-100 d-flex align-items-center justify-content-end">
          <span class="text-normal text-complementary-color-a pointer ms-2" (click)="change_all_process_status(true)"> Select All</span>
          <span class="text-normal px-2">|</span>
          <span class="text-normal text-complementary-color-a pointer" (click)="change_all_process_status(false)">Clear All</span>
        </div>
        <div class="w-100">
          <app-status-filter
            formControlName="process_status"
            [state_list]="processStates"
            [clearAll]="processResultStatusFilterClearAll"
            [selectAll]="processResultStatusFilterSelectAll"
          ></app-status-filter>
        </div>
      </div>
    </div>

    <!-- Process Steps Filter -->
    <div class="d-flex flex-column align-items-start justify-content-center w-100 mb-3">
      <div
        class="w-100 border d-flex align-items-center py-1 px-1 rounded-top-2 pointer"
        [class.rounded-bottom-2]="processStateFilterCollapse"
        (click)="processStateFilterCollapse = !processStateFilterCollapse"
        [attr.aria-expanded]="!processStateFilterCollapse"
        aria-controls="collapseBasic2"
      >
        <div class="d-flex flex-grow-1 justify-content-start align-items-center">
          <span class="text-normal fw-bold text-neutral-color-h">Process States:</span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <span class="material-symbols-outlined text-title text-complementary-color-a" *ngIf="processStateFilterCollapse">arrow_drop_down</span>
          <span class="material-symbols-outlined text-title text-complementary-color-a" *ngIf="!processStateFilterCollapse">arrow_drop_up</span>
        </div>
      </div>
      <div
        class="w-100 border-bottom border-start border-end py-1 px-2 rounded-bottom-2"
        id="collapseBasic2"
        [collapse]="processStateFilterCollapse"
        [isAnimated]="true"
      >
        <div class="w-100 d-flex align-items-center justify-content-end">
          <span class="text-normal text-complementary-color-a pointer ms-2" (click)="change_all_process_tasks(true)"> Select All</span>
          <span class="text-normal px-2">|</span>
          <span class="text-normal text-complementary-color-a pointer" (click)="change_all_process_tasks(false)">Clear All</span>
        </div>
        <div class="w-100">
          <app-task-filter
            formControlName="tasks"
            [taskList]="processTasks"
            [selectAll]="processResultTaskFilterSelectAll"
            [clearAll]="processResultTaskFilterClearAll"
          ></app-task-filter>
        </div>
      </div>
    </div>

    <!-- Dynamic filters -->
    <div
      class="d-flex flex-column align-items-start justify-content-center w-100 mb-3"
      *ngFor="let filter of processFilter; let i=index"
    >
      <div class="d-flex align-items-center">
        <span class="text-normal fw-bold text-neutral-color-h">{{filter.label}}: </span>
      </div>

      <!-- Widget Text -->
      <div
        class="w-100"
        *ngIf="filter.type === processFilterType.text"
      >
        <input
          class="form-input"
          type="text"
          [formControlName]="filter.field"
          placeholder="{{filter?.placeHolder + '...'}}"
        >
      </div>

      <!-- Widget Select -->
      <div
        class="w-100"
        *ngIf="filter.type === processFilterType.select"
      >
        <ng-select
          class="text-normal"
          [items]="filter.settings.source"
          placeholder="{{filter?.placeHolder + '...'}}"
          [clearable]="true"
          [bindLabel]="filter.settings.label_field"
          [bindValue]="filter.settings.value_field"
          [formControlName]="filter.field"
        >
        </ng-select>
      </div>

      <!-- Widget Select -->
      <div
        class="w-100"
        *ngIf="filter.type === processFilterType.date"
      >
        <input
          class="form-input"
          type="date"
          [formControlName]="filter.field"
        >
      </div>
    </div>
  </ng-container>

</ng-template>


<ng-template #toolbarTemplate>
  <!-- Tools bar container -->
  <div class="w-100 d-flex flex-wrap align-items-center px-3 border-bottom border-neutral-color-c">

    <!-- Left Side -->
    <div class="col-12 col-sm-3 d-flex justify-content-start align-content-center py-1">
      <div class="col-4 col-sm-12 d-flex justify-content-sm-start align-items-center">
        <!-- Filter Button -->
        <div
          class="skeleton-box rounded-1"
          style="width: 80px; height: 24px;"
        >
        </div>
      </div>
      <div class="col-4 d-flex gap-1 d-sm-none justify-content-center align-items-center">
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
      </div>
      <div class="col-4 gap-1 d-flex d-sm-none justify-content-end align-items-center">
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
        <div
          class="skeleton-box rounded-1 d-sm-none"
          style="width: 24px; height: 24px;"
        >
        </div>
      </div>

    </div>

    <!-- Right Side -->
    <div class="col-12 col-sm-9 py-1 d-flex justify-content-center justify-content-sm-end align-items-center gap-1">

      <!-- Limits -->
      <div
        class="skeleton-box rounded-1 me-sm-3"
        style="width: 145px; height: 24px;"
      >
      </div>

      <div
        class="skeleton-box rounded-1 me-sm-3"
        style="width: 145px; height: 24px;"
      >
      </div>

      <div
        class="skeleton-box rounded-1 d-none d-sm-block"
        style="width: 24px; height: 24px;"
      >
      </div>
      <div
        class="skeleton-box rounded-1 d-none d-sm-block"
        style="width: 24px; height: 24px;"
      >
      </div>
      <div
        class="skeleton-box rounded-1 d-none d-sm-block"
        style="width: 24px; height: 24px;"
      >
      </div>
      <div
        class="skeleton-box rounded-1 me-3 d-none d-sm-block"
        style="width: 24px; height: 24px;"
      >
      </div>


      <div
        class="skeleton-box rounded-1 d-none d-sm-block"
        style="width: 30px; height: 24px;"
      >
      </div>
      <div
        class="skeleton-box rounded-1 d-none d-sm-block"
        style="width: 30px; height: 24px;"
      >
      </div>

    </div>


  </div>
</ng-template>
