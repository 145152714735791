import {Component, Input} from '@angular/core';
import { FormControl } from '@angular/forms';
import {AuditI} from "../../../infrastructure/interfaces/audit.interface";

@Component({
  selector: 'app-status-logs',
  templateUrl: './status-logs.component.html',
  styleUrls: ['./status-logs.component.scss']
})
export class StatusLogsComponent {

  @Input() statusAuditLogs: AuditI[] = []
  @Input() loadingStatusAuditLogs: boolean = true;

  searchControl = new FormControl('');
  logs: any[] = [
    {
      name: 'Willie Jenning',
      email: 'willie.jennings@example.com',
      date: new Date(),
      status_0: 'On hold',
      status_1: 'Closed',
      image: 'path-to-image'
    },
    {
      name: 'Curtis Waver',
      email: 'curtis.weaver@example.com',
      date: new Date('2024-03-16T13:00:00'),
      status_0: 'In Progress',
      status_1: 'On hold',
      image: 'path-to-image'
    },
    {
      name: 'Tim Jenning',
      email: 'tim.jennings@example.com',
      date: new Date('2024-02-23T08:00:00'),
      status_0: 'Active',
      status_1: 'In Progress',
      image: 'path-to-image'
    }
  ];

  filteredLogs: any[] = [...this.logs];
  constructor() { }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe(value => this.filterLogs(value ?? ''));
  }

  filterLogs(searchTerm: string): void {
    this.filteredLogs = this.logs.filter(log =>
      log.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.status_0.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.status_1.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  getBadgeClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'badge active';
      case 'on hold':
        return 'badge on-hold';
      case 'closed':
        return 'badge closed';
      case 'in progress':
        return 'badge in-progress';
      default:
        return 'badge secondary';
    }
  }
}
