import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@mangosoft/mango-layout';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, catchError, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss']
})
export class ContactsTableComponent implements OnInit {
  @Input() viewComponent!: string;
  @Input() paginationValue: any;
  @Input() triggerDownloadCSV!: BehaviorSubject<string>;

  @Output() eventTriggerDownloadCSV: EventEmitter<string> = new EventEmitter();
  @Output() eventActionRow: EventEmitter<any> = new EventEmitter();
  @Output() actionModal: EventEmitter<any> = new EventEmitter();
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitValidateDownload: EventEmitter<boolean> = new EventEmitter();

  private unsubscribe$: Subject<void> = new Subject<void>();
  selectedContactIds: Set<string> = new Set();
  viewModal = false;
  content: any;
  contactsId!: string;
  hoverIndex: number = -1;
  page = {
    init: 1,
    start: 10,
  };
  contacts = [
    {
        "id": '1',
        "createdOn": new Date(),
        "name": "Courtney Henry",
        "type": "Lead",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "239.231",
        "address": "6391 Elgin St. Celina, Delaware 10299"
    },
    {
        "id": '2',
        "createdOn": new Date(),
        "name": "Annette Black",
        "type": "Patient",
        "specialty": "Pharmaceutical",
        "company": "Not Defined",
        "numberOfPatients": "300",
        "address": "2972 Westheimer Rd. Santa Ana, Illinois 85486"
    },
    {
        "id": '3',
        "createdOn": new Date(),
        "name": "Jane Cooper",
        "type": "Contact",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "1.239.230",
        "address": "2715 Ash Dr. San Jose, South Dakota 83475"
    },
    {
        "id": '4',
        "createdOn": new Date(),
        "name": "Darrell Steward",
        "type": "Lead",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "398.323",
        "address": "8502 Preston Rd. Inglewood, Maine 98380"
    },
    {
        "id": '5',
        "createdOn": new Date(),
        "name": "Jerome Bell",
        "type": "Lead",
        "specialty": "Pharmaceutical",
        "company": "Not Defined",
        "numberOfPatients": "450",
        "address": "2464 Royal Ln. Mesa, New Jersey 45463"
    },
    {
        "id": '6',
        "createdOn": new Date(),
        "name": "Courtney Henry",
        "type": "Lead",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "94.1234",
        "address": "4140 Parker Rd. Allentown, New Mexico 31134"
    },
    {
        "id": '7',
        "createdOn": new Date(),
        "name": "Annette Black",
        "type": "Patient",
        "specialty": "Pharmaceutical",
        "company": "Not Defined",
        "numberOfPatients": "9.231",
        "address": "3891 Ranchview Dr. Richardson, California 62639"
    },
    {
        "id": '8',
        "createdOn": new Date(),
        "name": "Jane Cooper",
        "type": "Contact",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "2.345",
        "address": "2118 Thornridge Cir. Syracuse, Connecticut 35624"
    },
    {
        "id": '9',
        "createdOn": new Date(),
        "name": "Darrell Steward",
        "type": "Lead",
        "specialty": "Oncology",
        "company": "Not Defined",
        "numberOfPatients": "8.310.123",
        "address": "1901 Thornridge Cir. Shiloh, Hawaii 81063"
    },
    {
        "id": '10',
        "createdOn": new Date(),
        "name": "Jerome Bell",
        "type": "Lead",
        "specialty": "Pharmaceutical",
        "company": "Not Defined",
        "numberOfPatients": "23.094",
        "address": "8502 Preston Rd. Inglewood, Maine 98380"
    }
  ]
  isLoading = false;
  pagination: any = {
    before: null,
    after: null,
  };
  senderId: any;
  entries: number = 10;
  pageCounter: number = 1;
  templateType = 'whatsapp';
  templateSelected: any;

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService) {}


  ngOnInit(): void {
    this.getTranslate();
    this.getTriggerDownload();
  }

  getTriggerDownload(){
    this.triggerDownloadCSV
    .pipe(
      takeUntil(this.unsubscribe$),
      catchError((err) => {
        return of({ error: err });
      })
    )
    .subscribe((res) => {
      if(res === 'action'){
        this.downloadCSV();
      }
    });
  }

  renderPrevPage(action: any): void {
    this.isLoading = true;
    this.pageCounter--;
  }

  renderNextPage(action: any): void {
    this.isLoading = true;
    this.pageCounter++;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTranslate() {
    this.translateService
      .init('process-manager')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          return of({ error: err });
        })
      )
      .subscribe((data) => {
        this.content = data?.pages?.contacts;
      });
  }

  getResultValue(key: string, result: any): string {
    return result[key] || '';
  }

  closeModalAlert(event: any) {
    this.viewModal = false;
  }

  deleteRow(row: any) {
    this.viewModal = true;
    this.templateSelected = row;
  }

  sendRow(row: any) {
    this.actionModal.emit('email');
  }

  editRow(row: any) {
    console.log(row);
  }

  actionRow(template: any) {
    if (this.contactsId === template.id) {
      this.contactsId = '';
    } else {
      this.contactsId = template.id;
    }
    this.eventActionRow.emit(template);
  }

  downloadCSV() {
    const csvData = this.convertToCSV(this.getSelectedContacts());
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'contacts.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.toastrService.success('Downloading...');
    this.eventTriggerDownloadCSV.emit('off');
  }

  convertToCSV(objArray: any[]): string {
    const header = ['Created On', 'Full Name', 'Type', 'Specialty', 'Company', 'Number of patients', 'Address'];
    const array = [header].concat(objArray.map(contact => [
      this.formatDate(contact.createdOn),
      contact.name,
      contact.type,
      contact.specialty,
      contact.company,
      contact.numberOfPatients,
      contact.address
    ]));
    return array.map(it => it.map(value => `"${value}"`).join(',')).join('\n');
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}, ${date.toLocaleTimeString()}`;
  }

  toggleSelection(contactId: string, event: any) {
    if (event.target.checked) {
      this.selectedContactIds.add(contactId);
    } else {
      this.selectedContactIds.delete(contactId);
    }
    this.getSelectedContacts().length > 0 ? this.emitValidateDownload.emit(true) : this.emitValidateDownload.emit(false);
  }

  toggleSelectAll(event: any): void {
    if (event.target.checked) {
      this.contacts.forEach(contact => this.selectedContactIds.add(contact.id));
    } else {
      this.selectedContactIds.clear();
    }
    this.getSelectedContacts().length > 0 ? this.emitValidateDownload.emit(true) : this.emitValidateDownload.emit(false);
  }
  getSelectedContacts(): any[] {
    return this.contacts.filter(contact => this.selectedContactIds.has(contact.id));
  }

  allSelected(): boolean {
    return this.contacts.length === this.selectedContactIds.size;
  }
}
